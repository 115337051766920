import { configureStore } from "@reduxjs/toolkit";
import { getSocket } from "slices/connection";
import { useDispatch } from "react-redux";
import { reducers } from "store/reducer";

export type RootState = ReturnType<typeof store.getState>;
const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: getSocket,
      },
      serializableCheck: false,
    }),
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
