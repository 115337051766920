import { useSelector } from "react-redux";
import { selectConnection } from "slices/connection";
import {
  DependencyList,
  EffectCallback,
  useEffect,
  useLayoutEffect,
} from "react";

export const useAuthConnectionEffect = (
  callback: EffectCallback,
  deps?: DependencyList
) => {
  const { loading, authenticated } = useSelector(selectConnection);
  useLayoutEffect(() => {
    if (!loading && authenticated) {
      return callback();
    }
  }, [loading, authenticated, ...(deps || [])]);
};

export const useConnection = () => {
  const { loading, authenticated } = useSelector(selectConnection);
  return { loading, authenticated };
};
