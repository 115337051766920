import { combineReducers } from "@reduxjs/toolkit";
import myAccount from "slices/myAccount";
import account from "slices/account";
import accounts from "slices/accounts";
import connection from "slices/connection";
import manualPatches from "slices/manualPatches";
import manualPatch from "slices/manualPatch";
import accountManualPatches from "slices/accountManualPatches";
import patchLogEvents from "slices/patchLogEvents";
import companies from "slices/companies";
import drivers from "slices/drivers";
import audits from "slices/audits";
import dots from "slices/dots";
import driverNote from "slices/driverNote";
import subscriptionLocks from "slices/subscriptionLocks";
import vehicles from "slices/vehicles";
import { StateType } from "typesafe-actions";

export const reducers = combineReducers({
  audits,
  dots,
  myAccount,
  account,
  drivers,
  accounts,
  companies,
  connection,
  manualPatches,
  patchLogEvents,
  manualPatch,
  accountManualPatches,
  subscriptionLocks,
  driverNote,
  vehicles,
});

export type RootState = StateType<typeof reducers>;
