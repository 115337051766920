import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  Spin,
} from "antd";
import { ALL_EVENT_CODES } from "../constants";
import React from "react";
import { FormInstance } from "antd/es";
import { useAuthConnectionEffect } from "hooks/useAuthConnectionEffect";
import * as VehiclesSlice from "slices/vehicles";
import { useVehicles } from "slices/vehicles";
import * as PatchlogEventsSlice from "slices/patchLogEvents";
import { useManualPatch } from "slices/manualPatch";
import { useAppDispatch } from "store/store";
import { useDispatch } from "react-redux";
import { LogEvent, LogEventEditForm } from "interfaces";
import { create as createPatcher } from "jsondiffpatch";
import assert from "assert";

const patcher = createPatcher();

export const EventForm: React.FC<{
  form: FormInstance<LogEventEditForm>;
  onSubmit: () => void;
  readOnly: boolean;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}> = ({ form, readOnly, onSubmit, loading, setLoading }) => {
  const { patch, patchSubscribed, patchLoading } = useManualPatch();
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  useAuthConnectionEffect((): (() => any) | undefined => {
    if (patch?.companyId && patch?.origin) {
      const companyId = patch?.companyId;
      dispatch(VehiclesSlice.subscribe(companyId, { origin: patch?.origin }));
      return () =>
        dispatch(
          VehiclesSlice.unsubscribe(companyId, { origin: patch?.origin })
        );
    }
  }, [patch?.companyId, patch?.origin]);
  const { vehicles, vehiclesById, vehiclesSubscribed, vehiclesLoading } =
    useVehicles(patch?.companyId || null) || {};

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values) => {
          const originLogEvent = patcher.clone(values.originData) as LogEvent;
          originLogEvent.eventCode.id = values.code;
          originLogEvent.eventComment = values.notes;
          originLogEvent.recordOrigin.id = values.origin;
          originLogEvent.eventTime.timestamp = values.time.unix() * 1000;
          originLogEvent.seqId =
            originLogEvent.seqId != null ? originLogEvent.seqId : "1";
          originLogEvent.eventTime.logDate = {
            ...originLogEvent.eventTime.logDate,
            date: values.time.format("yyyy/MM/DD"),
          };
          if (values.locationOrigin === "automatic") {
            delete originLogEvent.driverLocationDescription;
            originLogEvent.location = {
              lon: values.lon,
              lat: values.lat,
              calculatedLocation: values.calculatedLocation,
            };
          } else {
            delete originLogEvent.location;
            originLogEvent.driverLocationDescription = values.locationNotes;
          }

          const code = ALL_EVENT_CODES.find((c) => c.id === values.code);
          assert(code);
          assert(vehiclesById);

          if (code.status === "ACTIVE") {
            originLogEvent.recordStatus = {
              id: "ACTIVE",
            };
          } else if (code.status === "NONE") {
            delete originLogEvent.recordStatus;
          }
          const vehicle = vehiclesById[values.vehicle];
          originLogEvent.vehicle =
            vehicle != null
              ? {
                  id: vehicle._id,
                  vin: vehicle.vin,
                  name: vehicle.name,
                }
              : originLogEvent.vehicle;
          originLogEvent.totalVehicleMiles = values.odometer;
          originLogEvent.totalEngineHours = values.totalEngineHours;
          const delta = patcher.diff(values.originData, originLogEvent);
          if (delta && patch) {
            try {
              setLoading(true);
              const result = await appDispatch(
                PatchlogEventsSlice.updateLogEvent({
                  data: delta,
                  manualPatchId: patch.id,
                  logId: values.logId,
                })
              );
              if (result.status !== "ok") {
                message.error(result?.msg || "Error");
              }
            } finally {
              if (form.getFieldValue("logId") === values.logId) {
                setLoading(false);
              }
            }
          } else {
            message.warn("No changes");
          }
          if (form.getFieldValue("logId") === values.logId) {
            onSubmit();
          }
        }}
      >
        <Form.Item name="logId" hidden />
        <Form.Item name="originData" hidden />
        <Form.Item
          requiredMark="optional"
          name="time"
          label="TIME"
          rules={[
            {
              required: true,
              message: "Choose time",
            },
          ]}
        >
          <DatePicker
            allowClear={readOnly ? false : undefined}
            open={readOnly ? false : undefined}
            inputReadOnly={readOnly}
            showTime
            style={{ minWidth: 300 }}
          />
        </Form.Item>
        <Form.Item
          name="code"
          label="CODE"
          className="collection-create-form_last-form-item"
          rules={[
            {
              required: true,
              message: "Please choose code",
            },
          ]}
        >
          <Select
            disabled={readOnly}
            placeholder={"SELECT CODE"}
            onChange={(code: string) => {
              const codeObj = ALL_EVENT_CODES.find((c) => c.id === code);
              form.setFields([
                { name: "origin", value: codeObj?.origin || null },
              ]);
            }}
            showSearch
            getPopupContainer={(node: HTMLElement) =>
              node.parentNode as HTMLElement
            }
            style={{ minWidth: 300 }}
          >
            {ALL_EVENT_CODES.filter(({ hide }) => !hide).map(
              ({ id, disabled, detailedTitle }) => {
                return (
                  <Select.Option key={id} value={id} disabled={disabled}>
                    {detailedTitle}
                  </Select.Option>
                );
              }
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="origin"
          label="ORIGIN"
          className="collection-create-form_last-form-item"
          rules={[
            {
              required: true,
              message: "Please choose origin",
            },
          ]}
        >
          <Select
            disabled={readOnly}
            placeholder={"SELECT ORIGIN"}
            showSearch
            getPopupContainer={(node: HTMLElement) =>
              node.parentNode as HTMLElement
            }
            style={{ minWidth: 300, pointerEvents: "none", opacity: 0.5 }}
          >
            {Object.keys(originLabels).map((id) => {
              return (
                <Select.Option key={id} value={id}>
                  {originLabels[id]}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="vehicle"
          label="VEHICLE"
          className="collection-create-form_last-form-item"
          rules={[
            {
              required: true,
              message: "Please choose vehicle",
            },
          ]}
        >
          <Select
            disabled={readOnly}
            placeholder={"SELECT VEHICLE"}
            showSearch
            getPopupContainer={(node: HTMLElement) =>
              node.parentNode as HTMLElement
            }
            style={{ minWidth: 300 }}
          >
            {vehicles?.map(({ _id, name }) => {
              return (
                <Select.Option key={_id} value={_id}>
                  {name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="odometer"
          label="ODOMETER"
          rules={[
            {
              required: true,
              message: "Odometer required",
            },
          ]}
        >
          <InputNumber readOnly={readOnly} />
        </Form.Item>
        <Form.Item
          name="totalEngineHours"
          label="ENGINE HOURS"
          rules={[
            {
              required: true,
              message: "Engine hours required",
            },
          ]}
        >
          <InputNumber readOnly={readOnly} />
        </Form.Item>
        <Form.Item
          name="locationOrigin"
          label="LOCATION ORIGIN"
          rules={[
            {
              required: true,
              message: "Please choose location origin",
            },
          ]}
        >
          <Select
            disabled={readOnly}
            aria-autocomplete="none"
            placeholder={"LOCATION ORIGIN"}
            showSearch
            getPopupContainer={(node: HTMLElement) =>
              node.parentNode as HTMLElement
            }
            style={{ minWidth: 300 }}
          >
            <Select.Option value="automatic">AUTOMATIC</Select.Option>
            <Select.Option value="manual">MANUAL</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.locationOrigin !== curValues.locationOrigin
          }
        >
          {({ getFieldValue, getFieldsValue, setFieldsValue }) => {
            const origin = getFieldValue("locationOrigin");
            return (
              <div
                onClick={() => {
                  if (readOnly) return;
                  setFieldsValue({
                    locationOrigin: "automatic",
                  });
                }}
                style={{
                  opacity: origin === "automatic" ? 1 : 0.5,
                }}
              >
                <Space style={{ width: "100%" }}>
                  <Form.Item
                    name="lat"
                    label="Latitude"
                    rules={[
                      {
                        required: origin === "automatic",
                        message: "Latitude is required",
                      },
                    ]}
                  >
                    <InputNumber
                      readOnly={readOnly}
                      placeholder="Latitude"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="lon"
                    label="Longitude"
                    rules={[
                      {
                        required: origin === "automatic",
                        message: "Longitude is required",
                      },
                    ]}
                  >
                    <InputNumber
                      readOnly={readOnly}
                      placeholder="Longitude"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Space>
                <Form.Item
                  label="Calc. Location"
                  name="calculatedLocation"
                  rules={[
                    {
                      required: origin === "automatic",
                      message: "Calc. Location is required",
                    },
                  ]}
                >
                  <Input placeholder="Calc. Location" readOnly={readOnly} />
                </Form.Item>
              </div>
            );
          }}
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.locationOrigin !== curValues.locationOrigin
          }
        >
          {({ getFieldValue, getFieldsValue, setFieldsValue }) => {
            const origin = getFieldValue("locationOrigin");
            return (
              <div
                onClick={() => {
                  if (readOnly) return;
                  setFieldsValue({
                    locationOrigin: "manual",
                  });
                }}
                style={{
                  opacity: origin === "manual" ? 1 : 0.5,
                }}
              >
                <Form.Item label="Location Note" name="locationNotes">
                  <Input placeholder="Location" readOnly={readOnly} />
                </Form.Item>
              </div>
            );
          }}
        </Form.Item>
        <Form.Item label="Note" name="notes">
          <Input placeholder="Note" readOnly={readOnly} />
        </Form.Item>
      </Form>
    </Spin>
  );
};

export const originLabels: Record<string, string> = {
  ELD: "Auto",
  DRIVER: "Driver",
  OTHER_USER: "Other User",
};
