import { AccountPayload } from "interfaces";
import { createSingleSubscriptiveSlice } from "slices/subscriptive/single";
import { Dispatch } from "redux";
import { RootState } from "store/reducer";
import { Socket } from "socket.io-client";
import { ResponsePayload } from "slices/subscriptive";
import { emitAsync } from "utils/socket";
import { useSelector } from "react-redux";

export class UpdateAccountPayload {
  username: string;
  name: string;
  password?: string;
  version: number;
}

const update =
  (account: UpdateAccountPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload> => {
    const myAccount = getState().myAccount.resource;
    const socket = getSocket();
    if (!!myAccount) {
      return await emitAsync<ResponsePayload>(
        socket,
        `myAccount:update`,
        account
      );
    } else {
      return {
        status: "error",
        msg: "Synchronization error",
      };
    }
  };

const logout =
  () =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload> => {
    const myAccount = getState().myAccount.resource;
    const socket = getSocket();
    if (!!myAccount) {
      return await emitAsync<ResponsePayload>(socket, `myAccount:logout`, {});
    } else {
      return {
        status: "error",
        msg: "Synchronization error",
      };
    }
  };

const {
  select,
  unsubscribe,
  reducer,
  onPublish,
  subscribe,
  selectResource,
  slice,
} = createSingleSubscriptiveSlice({
  name: "myAccount",
  reducers: {},
  volatile: false,
});
export { unsubscribe, reducer, onPublish, subscribe, slice, logout, update };
const { setLoading } = slice.actions;

export default slice.reducer;

export const useMyAccount = () => {
  const { resource, loading, subscribed } = useSelector(select);
  return {
    myAccount: resource as AccountPayload | undefined,
    myAccountLoading: loading,
    myAccountSubscribed: subscribed,
  };
};
