import { AccountPayload, AccountRole } from "interfaces";
import { createSingleSubscriptiveSlice } from "slices/subscriptive/single";
import { Dispatch } from "redux";
import { RootState } from "store/reducer";
import { Socket } from "socket.io-client";
import { ResponsePayload } from "slices/subscriptive";
import { emitAsync } from "utils/socket";
import { v4 } from "uuid";
import { onPublish as accountsOnPublish } from "slices/accounts";
import { useSelector } from "react-redux";

const {
  select,
  unsubscribe,
  reducer,
  onPublish,
  subscribe,
  selectResource,
  slice,
} = createSingleSubscriptiveSlice({
  name: "account",
  volatile: true,
  reducers: {},
});

const { setLoading } = slice.actions;

export default slice.reducer;

export class CreateAccountPayload {
  username: string;
  name: string;
  role: AccountRole;
  clientResourceId: string;
}

export class UpdateAccountPayload extends CreateAccountPayload {
  id: string;
}

const create = (account: CreateAccountPayload) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload<null | undefined>> => {
  const myAccount = getState().myAccount.resource;
  const socket = getSocket();
  if (!!myAccount) {
    const clientResourceId = v4();

    const now = new Date();
    const optimisticAccount: AccountPayload = {
      ...account,
      clientResourceId: account.clientResourceId || clientResourceId,
      disabledAt: null,
      version: 0,
      status: "offline",
      id: v4(),
      createdAt: now.toISOString(),
      updatedAt: now.toISOString(),
      loading: true,
      deletedAt: null,
    };
    // await dispatch(onPublish(optimisticAccount));
    // await dispatch(accountsOnPublish([optimisticAccount]));
    const response = await emitAsync<ResponsePayload<null | undefined>>(
      socket,
      `account:create`,
      optimisticAccount
    );
    if (response.status !== "ok") {
      const now = new Date();
      const deletedAccount = {
        ...optimisticAccount,
        deletedAt: now.toISOString(),
      };
      // dispatch(onPublish(deletedAccount));
      // dispatch(accountsOnPublish([deletedAccount]));
    }
    return response;
  }
  return {
    status: "error",
    msg: "Synchronization error",
  };
};

const update = (
  account: UpdateAccountPayload,
  existingAccount: AccountPayload
) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload> => {
  const socket = getSocket();
  if (!!existingAccount) {
    const optimisticAccount: AccountPayload = {
      ...existingAccount,
      ...account,
      version: existingAccount.version,
      loading: true,
    };
    dispatch(onPublish(optimisticAccount));
    dispatch(accountsOnPublish([optimisticAccount]));

    const response = await emitAsync<ResponsePayload>(
      socket,
      `account:update`,
      account
    );
    if (response.status !== "ok") {
      dispatch(onPublish(existingAccount));
      dispatch(accountsOnPublish([existingAccount]));
    }
    return response;
  } else {
    return {
      status: "error",
      msg: "Synchronization error",
    };
  }
};

const disable = (existingAccount: AccountPayload) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload> => {
  const myAccount = getState().myAccount.resource;
  const socket = getSocket();
  if (!!myAccount && !!existingAccount) {
    const optimisticAccount: AccountPayload = {
      ...existingAccount,
      disabledAt: new Date().toISOString(),
      version: existingAccount.version,
      loading: true,
      status: "disabled",
    };
    dispatch(onPublish(optimisticAccount));
    dispatch(accountsOnPublish([optimisticAccount]));

    const response = await emitAsync<ResponsePayload>(
      socket,
      `account:disable`,
      { id: existingAccount.id }
    );
    if (response.status !== "ok") {
      dispatch(onPublish(existingAccount));
      dispatch(accountsOnPublish([existingAccount]));
    }
    return response;
  } else {
    return {
      status: "error",
      msg: "Synchronization error",
    };
  }
};

const enable = (existingAccount: AccountPayload) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload> => {
  const myAccount = getState().myAccount.resource;

  const socket = getSocket();
  if (!!myAccount && !!existingAccount) {
    const optimisticAccount: AccountPayload = {
      ...existingAccount,
      disabledAt: null,
      version: existingAccount.version,
      loading: true,
      status: "offline",
    };
    dispatch(onPublish(optimisticAccount));
    dispatch(accountsOnPublish([optimisticAccount]));
    const response = await emitAsync<ResponsePayload>(
      socket,
      `account:enable`,
      { id: existingAccount.id }
    );
    if (response.status !== "ok") {
      dispatch(onPublish(existingAccount));
      dispatch(accountsOnPublish([existingAccount]));
    }
    return response;
  } else {
    return {
      status: "error",
      msg: "Synchronization error",
    };
  }
};

const logout = (existingAccount: AccountPayload) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload> => {
  const myAccount = getState().myAccount.resource;
  const socket = getSocket();
  if (!!myAccount && !!existingAccount) {
    const optimisticAccount: AccountPayload = {
      ...existingAccount,
      disabledAt: null,
      version: existingAccount.version,
      loading: true,
      status: "offline",
    };
    dispatch(onPublish(optimisticAccount));
    dispatch(accountsOnPublish([optimisticAccount]));

    const response = await emitAsync<ResponsePayload>(
      socket,
      `account:logout`,
      { id: existingAccount.id }
    );
    if (response.status !== "ok") {
      dispatch(onPublish(existingAccount));
      dispatch(accountsOnPublish([existingAccount]));
    }
    return response;
  } else {
    return {
      status: "error",
      msg: "Synchronization error",
    };
  }
};

export {
  unsubscribe,
  reducer,
  onPublish,
  subscribe,
  slice,
  logout,
  enable,
  disable,
  update,
  create,
};

export const useAccount = () => {
  const { resource, loading, subscribed } = useSelector(select);
  return {
    account: resource as AccountPayload | undefined,
    accountLoading: loading,
    accountSubscribed: subscribed,
  };
};
