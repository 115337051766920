import {
  Button,
  Col,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React, { useMemo, useState } from "react";
import {
  InfoCircleOutlined,
  LogoutOutlined,
  PoweroffOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { isAfter } from "date-fns/fp";
import { useHistory } from "react-router-dom";
import * as AccountsSlice from "slices/accounts";
import { useAccounts } from "slices/accounts";
import { useDispatch } from "react-redux";
import { AccountPayload, AccountRole, accountRoleLabels } from "interfaces";
import { useAppDispatch } from "store/store";
import AccountForm from "components/AccountForm";
import { useForm } from "antd/lib/form/Form";
import {
  create,
  disable,
  enable,
  logout,
  UpdateAccountPayload,
} from "slices/account";
import { useAuthConnectionEffect } from "hooks/useAuthConnectionEffect";
import { useMyAccount } from "slices/myAccount";

const { Column } = Table;

type Account = {
  name: string;
  username: string;
  disabledAt: Date;
  status: "online" | "offline" | "processing";
};

const AccountListPage = () => {
  const { accounts, accountsLoading, accountsSubscribed } = useAccounts();
  const { myAccount, myAccountSubscribed, myAccountLoading } = useMyAccount();
  const [searchValue, setSearchValue] = useState("");
  const filteredAccounts = useMemo(() => {
    return accounts.filter(
      (entry) =>
        entry.name
          .toLowerCase()
          .search(new RegExp(searchValue.toLowerCase())) !== -1 ||
        entry.username
          .toLowerCase()
          .search(new RegExp(searchValue.toLowerCase())) !== -1
    );
  }, [searchValue, accounts]);

  const dispatch = useDispatch();
  useAuthConnectionEffect(() => {
    dispatch(AccountsSlice.subscribe());
    return () => {
      dispatch(AccountsSlice.unsubscribe());
    };
  });

  const appDispatch = useAppDispatch();
  const history = useHistory();
  const [createForm] = useForm<UpdateAccountPayload>();
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <Layout className={"accounts-list-container"}>
      <Layout.Content>
        <Row justify={"space-between"}>
          <Col span={6}>
            <Typography.Title style={{ margin: "16px 0" }} level={3}>
              USERS
            </Typography.Title>
          </Col>
          {myAccount?.role === "admin" ? (
            <Col
              span={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Input
                placeholder="Search User"
                value={searchValue}
                size={"middle"}
                style={{
                  minWidth: "100px",
                  marginRight: "8px",
                }}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <Button
                icon={<UserAddOutlined />}
                key="createButton"
                type="primary"
                onClick={() => setShowModal(true)}
              >
                CREATE
              </Button>
              <Modal
                visible={showModal}
                title="CREATE ACCOUNT"
                okText="CREATE"
                cancelText="CANCEL"
                onCancel={() => {
                  setShowModal(false);
                }}
                onOk={() => {
                  createForm.submit();
                }}
              >
                <AccountForm
                  showRole
                  account={null}
                  form={createForm}
                  onSubmit={async (account) => {
                    const { status, msg } = await appDispatch(create(account));
                    if (status === "ok") {
                      createForm.resetFields();
                      setShowModal(false);
                      message.success("Account has been created");
                    } else {
                      message.error(msg || "Error");
                    }
                  }}
                />
              </Modal>
            </Col>
          ) : null}
        </Row>

        <div className={"content"}>
          <Table
            loading={
              !accountsSubscribed ||
              accountsLoading ||
              !myAccountSubscribed ||
              myAccountLoading
            }
            dataSource={filteredAccounts as AccountPayload[]}
            rowKey={(account: AccountPayload) => account.clientResourceId}
          >
            <Column title="ID" dataIndex="username" key="username" />
            <Column title="FULL NAME" dataIndex="name" key="name" />
            <Column
              title="USER FUNCTION"
              dataIndex="role"
              key="role"
              filters={[
                {
                  text: "Log Support Personnel",
                  value: "none",
                },
                {
                  text: "Support Personnel (hrs)",
                  value: "shifter_hr",
                },
                // {
                //   text: "Support Personnel (days)",
                //   value: "shifter_day",
                // },
                {
                  text: "Support Personnel (days/hrs)",
                  value: "shifter_hr_day",
                },
                {
                  text: "Admin",
                  value: "admin",
                },
                {
                  text: "Operator",
                  value: "operator",
                },
                {
                  text: "Log Check",
                  value: "viewer",
                },
                {
                  text: "Manager",
                  value: "manager",
                },
                {
                  text: "Tech support",
                  value: "tech_support",
                },
                {
                  text: "Call Center",
                  value: "call_center",
                },
              ]}
              onFilter={(value: any, record: any) =>
                record.role.indexOf(value) === 0
              }
              render={(role: AccountRole) => accountRoleLabels[role]}
            />
            <Column
              title="LAST UPDATED (DATE)"
              dataIndex="updatedAt"
              key="updatedAt"
              render={(updatedAt: string) => {
                return moment(updatedAt).format("DD.MM.YYYY");
              }}
            />
            <Column
              title="STATUS"
              dataIndex="status"
              key="status"
              filters={[
                {
                  text: "ONLINE",
                  value: "online",
                },
                {
                  text: "OFFLINE",
                  value: "offline",
                },
                {
                  text: "PROCESSING",
                  value: "processing",
                },
                {
                  text: "DISABLED",
                  value: "disabled",
                },
              ]}
              defaultFilteredValue={["online"]}
              onFilter={(value: any, record: any) =>
                record.status.indexOf(value) === 0
              }
              render={(status: Account["status"], record: Account) => {
                const disabled = isAfter(record.disabledAt, new Date());
                const colorByStatus = {
                  online: "success",
                  processing: "processing",
                  offline: "default",
                  disabled: "default",
                };
                const titleByStatus = {
                  online: "ONLINE",
                  processing: "PROCESSING",
                  offline: "OFFLINE",
                  disabled: "DISABLED",
                };

                return (
                  <Tag color={disabled ? "error" : colorByStatus[status]}>
                    {disabled ? "blocked" : titleByStatus[status]}
                  </Tag>
                );
              }}
            />
            <Column
              align="right"
              title="ACTION"
              key="action"
              render={(text, account: AccountPayload) => {
                const disableAvailable = [
                  "disabled",
                  "offline",
                  "online",
                ].includes(account.status);
                const logoutAvailable = account.status === "online";
                return (
                  <Space size="small">
                    <Tooltip title="Info" placement={"bottom"}>
                      <Button
                        shape="circle"
                        onClick={() => history.push(`/accounts/${account.id}`)}
                        icon={
                          <InfoCircleOutlined
                            className={"icon-info icon-medium"}
                          />
                        }
                      />
                    </Tooltip>
                    {myAccount?.role === "admin" ? (
                      <>
                        <Tooltip title="Close session" placement={"bottom"}>
                          <Popconfirm
                            disabled={!logoutAvailable}
                            key="logoutAccountButton"
                            title={"Close session?"}
                            onConfirm={async () => {
                              const response = await appDispatch(
                                logout(account)
                              );
                              if (response.status == "ok") {
                                message.success(`Session closed`);
                              } else {
                                message.error("Cannot kill session");
                              }
                            }}
                            okText="YES"
                            cancelText="NO"
                          >
                            <Button
                              disabled={!logoutAvailable}
                              shape="circle"
                              icon={
                                <LogoutOutlined
                                  className={"icon-warning icon-medium"}
                                />
                              }
                            />
                          </Popconfirm>
                        </Tooltip>
                        <Tooltip
                          placement={"bottom"}
                          title={account.disabledAt ? "ENABLE" : "DISABLE"}
                        >
                          <Popconfirm
                            disabled={!disableAvailable}
                            key="disableButton"
                            title={`${
                              account.disabledAt ? "ENABLE" : "DISABLE"
                            } account?`}
                            onConfirm={async () => {
                              const response = await appDispatch(
                                account.disabledAt
                                  ? enable(account)
                                  : disable(account)
                              );
                              if (response.status == "ok") {
                                message.success(
                                  `Account is ${
                                    account.disabledAt ? "enabled" : "disabled"
                                  }`
                                );
                              } else {
                                message.error("Cannot disable account");
                              }
                            }}
                            okText="YES"
                            cancelText="NO"
                          >
                            <Button
                              shape="circle"
                              icon={
                                <PoweroffOutlined className={"icon-medium"} />
                              }
                              disabled={!disableAvailable}
                              danger={!account.disabledAt}
                              type={account.disabledAt ? "default" : "primary"}
                            />
                          </Popconfirm>
                        </Tooltip>
                      </>
                    ) : null}
                  </Space>
                );
              }}
            />
          </Table>
        </div>
      </Layout.Content>
    </Layout>
  );
};
export default AccountListPage;
