import { Form, Checkbox, Input, Radio, Space } from "antd";
import { AccountPayload, AccountRole, accountRoleLabels } from "interfaces";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import { useState } from "react";
import React from "react";
import { UpdateAccountPayload } from "slices/account";

const AccountForm = ({
  showRole,
  account,
  form,
  onSubmit,
}: {
  showRole?: boolean;
  account: AccountPayload | null;
  form: FormInstance<UpdateAccountPayload>;
  onSubmit: (acc: UpdateAccountPayload) => any;
}) => {
  const [withPassword, setWithPassword] = useState<boolean>(false);
  return (
    <Form
      onReset={() => setWithPassword(false)}
      form={form}
      layout="vertical"
      name="form_in_modal"
      initialValues={account || ({} as UpdateAccountPayload)}
      onFinish={onSubmit}
    >
      <Form.Item
        name="name"
        label="FULL NAME"
        rules={[
          {
            required: true,
            message: "Enter full name",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="username"
        label="USERNAME"
        rules={[
          {
            required: true,
            message: "Enter username",
          },
          {
            min: 3,
            message: "Not less than 3 characters",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Checkbox
        onChange={(e) => setWithPassword(e.target.checked)}
        style={{ marginBottom: 20 }}
      >
        CHANGE PASSWORD
      </Checkbox>
      {withPassword && (
        <Form.Item
          name="password"
          label="PASSWORD"
          rules={[
            {
              required: true,
              message: "Enter password",
            },
            {
              min: 6,
              message: "Not less than 6 characters",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      )}
      {showRole ? (
        <Form.Item
          name="role"
          label="USER FUNCTION"
          className="collection-create-form_last-form-item"
          rules={[
            {
              required: true,
              message: "Please choose role",
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              {(
                [
                  "admin",
                  "viewer",
                  "shifter_hr",
                  "shifter_day",
                  "shifter_hr_day",
                  "manager",
                  "tech_support",
                  "call_center",
                ] as AccountRole[]
              ).map((role) => (
                <Radio value={role}>{accountRoleLabels[role]}</Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      ) : null}
    </Form>
  );
};

export default AccountForm;
