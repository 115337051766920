import { PatchLogEventPayload } from "interfaces";
import { Dispatch } from "redux";
import { RootState } from "store/reducer";
import { Socket } from "socket.io-client";
import { ResponsePayload } from "slices/subscriptive";
import { emitAsyncOrdered } from "utils/socket";
import { useSelector } from "react-redux";
import * as r from "ramda";
import { createRelativeSubscriptiveSlice } from "slices/subscriptive/relative";

const {
  select,
  selectChildResourceList,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  setLoadingResources,
  subscribe,
  slice,
  resourceId,
} = createRelativeSubscriptiveSlice({
  name: "patchLogEvents",
  parentName: "manualPatches",
  parentSingleName: "manualPatch",
  payloadType: PatchLogEventPayload,
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
  idProp: "id",
});

export class SelectPatchLogEventsPayload {
  manualPatchId: string;

  logIds: string[];

  state: boolean;
}

export class UpdatePatchLogEventsPayload {
  manualPatchId: string;

  logId: string;

  data?: Record<string, unknown>;
}

const selectLogEvents = ({
  manualPatchId,
  state,
  logIds,
}: SelectPatchLogEventsPayload) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload<null | undefined>> => {
  const socket = getSocket();
  await dispatch(
    setLoadingResources({
      parentId: manualPatchId,
      ids: logIds,
      state: true,
    })
  );

  const response = await emitAsyncOrdered<ResponsePayload<null | undefined>>(
    socket,
    resourceId,
    `manualPatch/patchLogEvents:select`,
    { manualPatchId, state, logIds }
  );

  if (response.status !== "ok") {
    await dispatch(
      setLoadingResources({
        parentId: manualPatchId,
        ids: logIds,
        state: false,
      })
    );
  }

  return response;
};

const updateLogEvent = ({
  manualPatchId,
  logId,
  data,
}: UpdatePatchLogEventsPayload) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload<null | undefined>> => {
  const socket = getSocket();
  await dispatch(
    setLoadingResources({
      parentId: manualPatchId,
      ids: [logId],
      state: true,
    })
  );

  const response = await emitAsyncOrdered<ResponsePayload<null | undefined>>(
    socket,
    resourceId,
    `manualPatch/patchLogEvent:update`,
    { manualPatchId, data, logId }
  );

  if (response.status !== "ok") {
    await dispatch(
      setLoadingResources({
        parentId: manualPatchId,
        ids: [logId],
        state: false,
      })
    );
  }

  return response;
};

export class SelectAllPayload {
  manualPatchId: string;

  state: boolean;
}

const selectAllLogEvents = ({
  manualPatchId,
  state,
}: SelectAllPayload) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload<null | undefined>> => {
  const account = getState().myAccount.resource;
  const socket = getSocket();
  if (!!account) {
    return await emitAsyncOrdered<ResponsePayload<null | undefined>>(
      socket,
      resourceId,
      `manualPatch/patchLogEvents:selectAll`,
      { manualPatchId, state }
    );
  } else {
    return {
      status: "unauthenticated",
    };
  }
};

export default slice.reducer;

export {
  updateLogEvent,
  selectLogEvents,
  selectAllLogEvents,
  select,
  selectChildResourceList,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  slice,
};

export const usePatchLogEvents = (manualPatchId: string | null) => {
  const {
    resourceDictionary: patchLogEventsById,
    loading: patchLogEventsLoading,
    subscribed: patchLogEventsSubscribed,
  } = useSelector(r.partial(select, [manualPatchId])) || {};

  const patchLogEvents = useSelector(
    r.partial(selectChildResourceList, [manualPatchId])
  ) as PatchLogEventPayload[] | undefined;

  return {
    patchLogEvents: patchLogEvents as PatchLogEventPayload[] | undefined,
    patchLogEventsById: patchLogEventsById as
      | Record<string, PatchLogEventPayload>
      | undefined,
    patchLogEventsLoading,
    patchLogEventsSubscribed,
  };
};
