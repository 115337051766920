import { createSubscriptiveSlice, ResponsePayload } from "slices/subscriptive";
import { AuditPayload } from "interfaces";
import { Dispatch } from "redux";
import { Socket } from "socket.io-client";
import { v4 } from "uuid";
import { emitAsync } from "utils/socket";
import { RootState } from "store/reducer";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";

export class CreateAuditPayload {
  clientResourceId: string;
  message?: string | null;
  origin: string;
  to: string;
  from: string;
  companyId: string;
  driverId: string;
}

export class UpdateAuditPayload extends CreateAuditPayload {
  id: string;
}

const {
  select,
  selectDictionary,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  selectResourceList,
  slice,
  initialState,
} = createSubscriptiveSlice({
  name: "audits",
  payloadType: AuditPayload,
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
  idProp: "clientResourceId",
});

const create =
  (audit: CreateAuditPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    const clientResourceId = v4();
    const drivers = getState().drivers;
    const now = new Date();
    const optimisticAudit: AuditPayload = {
      ...audit,
      clientResourceId: audit.clientResourceId || clientResourceId,
      version: 0,
      number: 0,
      id: v4(),
      createdAt: now.toISOString(),
      updatedAt: now.toISOString(),
      from: (audit.from as unknown as Moment)?.toISOString(),
      to: (audit.to as unknown as Moment)?.toISOString(),
      loading: true,
      deletedAt: null,
      driverName: "N/A",
    };
    await dispatch(onPublish([optimisticAudit]));
    const response = await emitAsync<ResponsePayload<null | undefined>>(
      socket,
      `audit:create`,
      optimisticAudit
    );
    if (response.status !== "ok") {
      const now = new Date();
      dispatch(
        onPublish([{ ...optimisticAudit, deletedAt: now.toISOString() }])
      );
    }
    return response;
  };

const update =
  (audit: UpdateAuditPayload, existingAudit: AuditPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload> => {
    const socket = getSocket();
    if (!!existingAudit) {
      const optimisticAudit: AuditPayload = {
        ...existingAudit,
        ...audit,
        version: existingAudit.version,
        loading: true,
      };
      await dispatch(onPublish([optimisticAudit]));

      const response = await emitAsync<ResponsePayload>(
        socket,
        `audit:update`,
        audit
      );
      if (response.status !== "ok") {
        dispatch(onPublish([existingAudit]));
      }
      return response;
    } else {
      return {
        status: "error",
        msg: "Synchronization error",
      };
    }
  };

const deleteAudit =
  (existingAudit: AuditPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload> => {
    const socket = getSocket();
    if (!!existingAudit) {
      const optimisticAudit: AuditPayload = {
        ...existingAudit,
        deletedAt: moment().toISOString(),
        loading: true,
      };
      await dispatch(onPublish([optimisticAudit]));

      const response = await emitAsync<ResponsePayload>(
        socket,
        `audit:delete`,
        {
          id: existingAudit.id,
        }
      );
      if (response.status !== "ok") {
        dispatch(onPublish([existingAudit]));
      }
      return response;
    } else {
      return {
        status: "error",
        msg: "Synchronization error",
      };
    }
  };

const { setLoading } = slice.actions;

export default slice.reducer;

export {
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  slice,
  initialState,
  update,
  create,
  deleteAudit,
};

export const useAudits = () => {
  const { loading: auditsLoading, subscribed: auditsSubscribed } =
    useSelector(select);
  const audits = useSelector(selectResourceList) as AuditPayload[];
  const auditsById = useSelector(selectDictionary) as Record<
    string,
    AuditPayload
  >;

  return {
    audits,
    auditsById,
    auditsLoading,
    auditsSubscribed,
  };
};
