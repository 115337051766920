import * as R from "ramda";
import {
  classToPlain,
  Expose,
  plainToClass,
  Transform,
} from "class-transformer";
import { ClassConstructor } from "class-transformer/types/interfaces";
import { timezones } from "utils/timezone";
import { Moment } from "moment";

export const transformBeforeEmit = R.curry(
  <T, V>(cls: ClassConstructor<T>, objs: V | V[]) => {
    return classToPlain(plainToClass(cls, objs), {
      excludeExtraneousValues: true,
    });
  }
);

export type LogEventEditForm = {
  originData: LogEvent;
  logId: string;
  time: Moment;
  code: string;
  origin: string;
  vehicle: string;
  odometer: number;
  totalEngineHours: number;
  lat: number;
  lon: number;
  locationOrigin: "automatic" | "manual";
  calculatedLocation: string;
  locationNotes: string;
  notes: string;
};

export class LogEvent {
  _id: string;
  _rev: string;
  i: string;
  companyId: string;
  userId: string;
  createdBy: string;
  createdAt: number;
  seqId: string;
  totalVehicleMiles: number;
  totalEngineHours: number;
  accumulatedVehicleMiles: number;
  elapsedEngineHours: number;
  malfuncIndicatorStatus: boolean;
  diagnosticIndicatorStatus: boolean;
  eventComment: string;
  location?: {
    calculatedLocation?: string;
    lat?: number;
    lon?: number;
  };
  driverLocationDescription: string;
  isLive: boolean;
  recordStatus: {
    id: string;
  };
  recordOrigin: {
    id: string;
  };
  eventCode: {
    id: string;
  };
  eventTime: {
    timestamp: number;
    logDate: {
      date: string;
      timeZone: {
        id: keyof typeof timezones;
      };
      logStartOffset: number;
    };
  };
  vehicle: {
    id: string;
    name: string;
    vin: string;
  };
  suggestedEditEventIds: string[];
  type: {
    id: string;
  };
  dSTime: -1;
  stime: number;
}

export class DriverPayload {
  updatedAt: string;
  createdAt: string;
  deletedAt?: string | null;
  id: string;
  clientResourceId: string;
  _id: string;
  _rev: string;
  companyId: string;
  createdBy: string;
  updatedBy: string;
  username: string;
  firstName: string;
  lastName: string;
  active: true;
  phoneNum: string;
  appVersion: string;
  deviceInfo: string;
  role: {
    id: string;
  };
}

export class VehiclePayload {
  _id: string;
  _rev: string;
  id: string;
  clientResourceId: string;
  updatedAt: string;
  createdAt: string;
  deletedAt?: string | null;
  companyId: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  make: string;
  model: string;
  year: number;
  vin: string;
  plateNumber: string;
  active: boolean;
  trackerId: string;
  fuel: {
    id: string;
  };
  licenseState: {
    id: string;
  };
  eld: {
    id: string;
    serialNumber: string;
    sn: string;
  };
  edci: {
    id: string;
  };
  et: {
    id: string;
  };
  ct: string;
  etg: string;
  type: {
    id: string;
  };
  stime: number;
  dSTime: number;
  dId: string;
}

export class CompanyPayload {
  updatedAt: string;
  createdAt: string;
  deletedAt?: string | null;
  id: string;
  clientResourceId: string;
  _id: string;
  _rev: string;
  companyId: string;
  name: string;
  street: string;
  city: string;
  zipCode: string;
  dotNumber: string;
  vehicleMotionThreshold: number;
  timeZone: {
    id: string;
  };
  state: {
    id: string;
  };
  terminals: [
    {
      id: string;
      logStartOffset: number;
      street: string;
      city: string;
      zipCode: string;
      timeZone: {
        id: string;
      };
      state: {
        id: string;
      };
    }
  ];
  defaultHosRules: {
    pcAllowed: boolean;
    ymAllowed: boolean;
    is16hShortHaulExceptionAllowed: boolean;
    exempt: boolean;
    suggestedEventOriginIsDriver: boolean;
    cycle: {
      id: string;
    };
    cargoType: {
      id: string;
    };
    restartHours: {
      id: string;
    };
    restBreak: {
      id: string;
    };
  };
  complianceMode: {
    id: string;
  };
  det: {
    id: string;
  };
  planFeatures: {
    allowTracking: boolean;
    allowIFTA: boolean;
    allowGpsTracking: boolean;
  };
  r: {
    id: string;
  };
  ehem: boolean;
  type: {
    id: string;
  };
  stime: number;
  dSTime: number;
  updatedBy: string;
  a: boolean;
}

export interface BaseModel {
  id: string;
  clientResourceId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  loading?: boolean;
}

export type AccountStatus = "disabled" | "offline" | "processing" | "online";
export type AccountRole =
  | "admin"
  | "operator"
  | "viewer"
  | "shifter_hr"
  | "shifter_day"
  | "shifter_hr_day"
  | "manager"
  | "tech_support"
  | "call_center";

export class AccountPayload {
  loading?: boolean;
  @Expose()
  clientResourceId: string;
  @Expose()
  createdAt: string;
  @Expose()
  updatedAt: string;
  @Expose()
  deletedAt: string | null;
  @Expose()
  id: string;
  @Expose()
  username: string;
  @Expose()
  name: string;
  @Expose()
  disabledAt: string | null;
  @Expose()
  role: AccountRole;

  @Expose()
  status: AccountStatus;
  @Expose()
  version: number;
}

export class TokenPayload {
  @Expose()
  accessToken: string;
  @Expose()
  refreshToken: string;

  @Expose()
  expiredAt: string;

  @Expose()
  revokedAt: string | null;
  @Expose()
  ownerId: string | null;
  @Expose()
  predecessorTokenId: string | null;
  @Expose()
  clientResourceId: string;
  @Expose()
  createdAt: string;
  @Expose()
  deletedAt: string | null;
  @Expose()
  id: string;
  @Expose()
  loading: boolean;
  @Expose()
  updatedAt: string;
}

export type ManualPatchType = "days" | "hours";

export type ManualPatchStatus =
  | "deleted"
  | "shift_rollback_started"
  | "shift_rollback_stopped"
  | "shift_finished"
  | "shift_stopped"
  | "shift_started"
  | "shift_requested"
  | "fetch_finished"
  | "fetch_started"
  | "fetch_requested"
  | "created";

export class ManualPatchPayload {
  @Expose()
  fixCertifications?: boolean;
  @Expose()
  loading?: boolean;
  @Expose()
  createdAt: string;
  @Expose()
  updatedAt: string;
  @Expose()
  deletedAt?: string | null;
  @Expose()
  id: string;
  @Expose()
  clientResourceId: string;
  @Expose()
  from?: string | null;
  @Expose()
  to?: string | null;
  @Expose()
  fetchRequestedAt?: string | null;
  @Expose()
  fetchStartedAt?: string | null;
  @Expose()
  fetchFinishedAt?: string | null;
  @Expose()
  shiftRequestedAt?: string | null;
  @Expose()
  shiftStartedAt?: string | null;
  @Expose()
  shiftStoppedAt?: string | null;
  @Expose()
  shiftFinishedAt?: string | null;
  @Expose()
  rollbackRequestedAt?: string | null;
  @Expose()
  rollbackStartedAt?: string | null;
  @Expose()
  rollbackStoppedAt?: string | null;
  @Expose()
  companyId?: string | null;
  @Expose()
  driverId?: string | null;
  @Expose()
  driverName?: string | null;
  @Expose()
  timezone?: string | null;
  @Expose()
  ownerId?: string | null;
  @Expose()
  companyName?: string | null;
  @Expose()
  origin?: string | null;
  @Expose()
  type: ManualPatchType;
  @Expose()
  @Transform(
    ({ obj: patch }) => {
      if (patch.deletedAt) {
        return "deleted";
      } else if (patch.shiftFinishedAt) {
        return "shift_finished";
      } else if (patch.shiftStoppedAt) {
        return "shift_stopped";
      } else if (patch.shiftStartedAt) {
        return "shift_started";
      } else if (patch.shiftRequestedAt) {
        return "shift_requested";
      } else if (patch.fetchFinishedAt) {
        return "fetch_finished";
      } else if (patch.fetchStartedAt) {
        return "fetch_started";
      } else if (patch.fetchRequestedAt) {
        return "fetch_requested";
      } else if (patch.createdAt) {
        return "created";
      }
    },
    { toClassOnly: true }
  )
  status: ManualPatchStatus;
  @Expose()
  shiftAmount: number;
  @Expose()
  version: number;
  @Expose()
  incNumber: number;
}

export class SubscriptionLockPayload {
  @Expose()
  loading?: boolean;
  @Expose()
  disabled?: boolean;
  @Expose()
  id: string;
  @Expose()
  message?: string | null;
  @Expose()
  clientResourceId: string;
  @Expose()
  version: number;
  @Expose()
  origin: string;
  @Expose()
  number: number;
  @Expose()
  createdAt: string;
  @Expose()
  updatedAt: string;
  @Expose()
  deletedAt?: string | null;
  @Expose()
  companyId: string;
  @Expose()
  companyName: string;
  @Expose()
  driverId?: string;
  @Expose()
  driverName?: string;
}

export class DotPayload {
  @Expose()
  disabled?: boolean;
  @Expose()
  loading?: boolean;
  @Expose()
  id: string;
  @Expose()
  message?: string | null;
  @Expose()
  clientResourceId: string;
  @Expose()
  version: number;
  @Expose()
  origin: string;
  @Expose()
  number: number;
  @Expose()
  createdAt: string;
  @Expose()
  updatedAt: string;
  @Expose()
  deletedAt?: string | null;
  @Expose()
  from: string;
  @Expose()
  companyId: string;
  @Expose()
  driverId: string;
  @Expose()
  driverName: string;
}

export class AuditPayload {
  @Expose()
  loading?: boolean;
  @Expose()
  id: string;
  @Expose()
  message?: string | null;
  @Expose()
  clientResourceId: string;
  @Expose()
  version: number;
  @Expose()
  origin: string;
  @Expose()
  number: number;
  @Expose()
  createdAt: string;
  @Expose()
  updatedAt: string;
  @Expose()
  deletedAt?: string | null;
  @Expose()
  from: string;
  @Expose()
  to: string;
  @Expose()
  companyId: string;
  @Expose()
  driverId: string;
  @Expose()
  driverName: string;
}

export const accountColorByStatus = {
  online: "success",
  processing: "processing",
  offline: "default",
  disabled: "default",
};
export const manualPatchColorByStatus: Record<ManualPatchStatus, string> = {
  deleted: "error",
  shift_rollback_started: "processing",
  shift_rollback_stopped: "warning",
  shift_finished: "success",
  shift_stopped: "processing",
  shift_started: "processing",
  shift_requested: "processing",
  fetch_finished: "default",
  fetch_started: "default",
  fetch_requested: "default",
  created: "default",
};
export const manualPatchTitleByStatus: Record<ManualPatchStatus, string> = {
  deleted: "DELETED",
  shift_rollback_started: "ROLLING BACK",
  shift_rollback_stopped: "ROLLED BACK",
  shift_finished: "SHIFT FINISHED",
  shift_stopped: "PROCESSING",
  shift_started: "PROCESSING",
  shift_requested: "PROCESSING",
  fetch_finished: "PENDING",
  fetch_started: "PENDING",
  fetch_requested: "PENDING",
  created: "CREATED",
};
export const accountRoleLabels: Record<AccountRole, string> = {
  shifter_hr: "Support Personnel (hrs)",
  shifter_day: "Support Personnel (days)",
  shifter_hr_day: "Support Personnel (days/hrs)",
  admin: "Admin",
  operator: "Operator",
  viewer: "Log Check",
  manager: "Manager",
  tech_support: "Tech Support",
  call_center: " Call Center",
};

export class PatchLogEventPayload {
  @Transform(({ obj: event }) => {
    return event.logId;
  })
  id: string;
  @Transform(({ obj: event }) => {
    return event.logId;
  })
  loading?: boolean;
  clientResourceId: string;
  @Expose()
  version: number;
  @Expose()
  createdAt: string;
  @Expose()
  updatedAt: string;
  @Expose()
  deletedAt?: string;
  @Expose()
  logId: string;
  @Expose()
  originData: LogEvent;
  @Expose()
  committedData: LogEvent | null;
  @Expose()
  committedAt: string;
  @Expose()
  rollbackAt: string;
  @Expose()
  failedAt: string;
  @Expose()
  selected: boolean;
  @Expose()
  patchId: string;
  @Expose()
  type: string;
  @Expose()
  updateData: object;
}

export class DriverNotePayload {
  @Expose()
  driverId: string;
  @Expose()
  driverName: string;
  @Expose()
  freeMonth: string;
  @Expose()
  ownerId: string;
  @Expose()
  origin: string;
  @Expose()
  companyId: string;
  @Expose()
  companyName: string;
  @Expose()
  deletedAt: string | null;
  @Expose()
  ownerUsername: string;
}
