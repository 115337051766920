import * as R from "ramda";
import React, { useMemo } from "react";
import { Button, Col, DatePicker, Row, Select, Tooltip } from "antd";
import { compareTwoStrings } from "string-similarity";
import { CompanyPayload, DriverPayload, ManualPatchPayload } from "interfaces";
import { getContainer } from "utils/html";
import { ResponsePayload } from "slices/subscriptive";
import moment from "moment";
import { useDrivers } from "slices/drivers";
import { UpdateManualPatchPayload } from "slices/manualPatch";
import * as CompaniesSlice from "slices/companies";
import { useCompanies } from "slices/companies";
import { useAuthConnectionEffect } from "hooks/useAuthConnectionEffect";
import { useDispatch } from "react-redux";

export default function SelectStep(props: {
  onlyLast8Days: boolean;
  patch: ManualPatchPayload;
  updatePatch: (
    patch: UpdateManualPatchPayload,
    reset?: boolean
  ) => Promise<ResponsePayload>;
  disabled: boolean | undefined;
  fetchEvents: () => void;
}) {
  const { updatePatch, patch, disabled, fetchEvents } = props;
  const { drivers, driversSubscribed, driversLoading } = useDrivers(
    patch.companyId || null
  );
  const dispatch = useDispatch();
  const { companies, companiesSubscribed, companiesLoading } = useCompanies();
  useAuthConnectionEffect(() => {
    if (patch?.origin) {
      dispatch(CompaniesSlice.subscribe({ origin: patch?.origin }));
      return () => {
        dispatch(CompaniesSlice.unsubscribe({ origin: patch?.origin }));
      };
    }
  }, [patch?.origin]);
  const { startDate, endDate } = useMemo(
    () => ({
      startDate: patch.from ? moment(patch.from) : null,
      endDate: patch.to ? moment(patch.to) : null,
    }),
    [patch.from, patch.to]
  );
  const eightDaysAgo = moment().subtract(8, "days");
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col>
          <Tooltip
            mouseEnterDelay={1}
            getPopupContainer={getContainer}
            getTooltipContainer={getContainer}
            title="Select company"
          >
            <Select
              placeholder={"Select origin"}
              disabled={disabled}
              filterOption={(str, option) => {
                const name = option?.children.toLowerCase().replaceAll(" ", "");
                const searchStr = str.toLowerCase().replaceAll(" ", "");
                return (
                  !str ||
                  name.includes(searchStr) ||
                  compareTwoStrings(name, searchStr) > 0.8
                );
              }}
              onChange={(origin: string) =>
                origin !== patch.origin &&
                updatePatch(
                  R.pipe<
                    ManualPatchPayload,
                    ManualPatchPayload,
                    ManualPatchPayload,
                    ManualPatchPayload
                  >(
                    R.assoc("origin", origin),
                    R.assoc("companyId", null),
                    R.assoc("driverId", null)
                  )(patch)
                )
              }
              value={patch.origin || undefined}
              showSearch
              getPopupContainer={getContainer}
              style={{ minWidth: 300 }}
            >
              {process.env.REACT_APP_ORIGINS?.split(",").map((origin) => (
                <Select.Option key={origin} value={origin}>
                  {origin}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip
            mouseEnterDelay={1}
            getPopupContainer={getContainer}
            getTooltipContainer={getContainer}
            title="Select company"
          >
            <Select
              placeholder={"Select company"}
              loading={companiesLoading}
              disabled={disabled || !companiesSubscribed}
              filterOption={(str, option) => {
                const name = option?.children.toLowerCase().replaceAll(" ", "");
                const searchStr = str.toLowerCase().replaceAll(" ", "");
                return (
                  !str ||
                  name.includes(searchStr) ||
                  compareTwoStrings(name, searchStr) > 0.8
                );
              }}
              onChange={(companyId: string) =>
                companyId !== patch.companyId &&
                updatePatch(
                  R.pipe<
                    ManualPatchPayload,
                    ManualPatchPayload,
                    ManualPatchPayload
                  >(
                    R.assoc("companyId", companyId),
                    R.assoc("driverId", null)
                  )(patch)
                )
              }
              value={patch.companyId || undefined}
              showSearch
              getPopupContainer={getContainer}
              style={{ minWidth: 300 }}
            >
              {companies.map((company: CompanyPayload) => (
                <Select.Option key={company._id} value={company._id}>
                  {company.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip
            mouseEnterDelay={1}
            getPopupContainer={getContainer}
            getTooltipContainer={getContainer}
            title="Select driver"
          >
            <Select
              placeholder={"Select driver"}
              loading={driversLoading}
              disabled={disabled || !driversSubscribed}
              filterOption={(str, option) => {
                const name = option?.children.join("").toLowerCase();
                const searchStr = str.toLowerCase();
                return (
                  !str ||
                  name.includes(str.toLowerCase()) ||
                  compareTwoStrings(name, searchStr) > 0.8
                );
              }}
              onChange={(driverId: string) =>
                patch.driverId !== driverId &&
                updatePatch(R.assoc("driverId", driverId, patch))
              }
              value={patch.driverId || undefined}
              showSearch
              getPopupContainer={getContainer}
              style={{ minWidth: 300 }}
            >
              {drivers?.map((driver: DriverPayload) => (
                <Select.Option key={driver._id} value={driver._id}>
                  {driver.firstName} {driver.lastName}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col>
          <DatePicker.RangePicker
            disabledDate={(date) =>
              props.onlyLast8Days ? eightDaysAgo.isAfter(date) : false
            }
            disabled={disabled}
            allowClear={false}
            // getPopupContainer={getContainer}
            value={[startDate, endDate]}
            onChange={(range) => {
              if (range) {
                updatePatch({
                  ...patch,
                  from: range[0]
                    ? moment.utc(range[0].format("YYYY-MM-DD"))?.toISOString()
                    : null,
                  to: range[1]
                    ? moment.utc(range[1].format("YYYY-MM-DD"))?.toISOString()
                    : null,
                });
              }
            }}
          />
        </Col>
        <Col>
          <Button
            disabled={disabled}
            type="primary"
            htmlType="submit"
            onClick={fetchEvents}
          >
            LOAD
          </Button>
        </Col>
      </Row>
    </>
  );
}
