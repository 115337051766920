import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Image, Input, Row, Spin } from "antd";
import { useSelector } from "react-redux";
import {
  CredentialsPayload,
  obtainTokenAsync,
  selectConnection,
  tryAuthenticateAsync,
} from "slices/connection";

import { useHistory } from "react-router-dom";
import { useAppDispatch } from "store/store";

export default function Login() {
  const dispatch = useAppDispatch();
  const [msg, setMsg] = useState<undefined | string>("");
  const history = useHistory();
  const { loading, authenticated } = useSelector(selectConnection);
  useEffect(() => {
    if (authenticated) {
      history.replace("/accounts");
    }
  });
  const onFinish = async (values: CredentialsPayload) => {
    setMsg("");
    const { msg, status } = await dispatch(obtainTokenAsync(values));
    if (status !== "ok") {
      setMsg(msg);
    } else {
      const { status, msg, data } = await dispatch(tryAuthenticateAsync());
      if (status === "ok") {
        history.replace("/activity");
      } else {
        setMsg(msg);
      }
    }
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Col span={4} style={{ textAlign: "center", marginBottom: "2rem" }}>
        <Spin spinning={loading}>
          <div style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Image preview={false} src="/logo.png" />
          </div>
          {msg && (
            <Alert style={{ marginBottom: 16 }} message={msg} type="error" />
          )}
          <Form
            layout={"vertical"}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="USERNAME"
              name="username"
              rules={[
                { required: true, message: "Please input your username" },
              ]}
            >
              <Input type="username" />
            </Form.Item>

            <Form.Item
              label="PASSWORD"
              name="password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                style={{ width: "100%" }}
                loading={loading}
                disabled={loading}
                type="primary"
                htmlType="submit"
              >
                LOGIN
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Col>
    </Row>
  );
}
