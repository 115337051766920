import {
  createRelativeSubscriptiveSlice,
  RelativeSubscriptiveResourceList,
} from "slices/subscriptive/relative";
import { VehiclePayload } from "interfaces";
import { useSelector } from "react-redux";
import * as r from "ramda";

export type VehiclesRootState = {
  [k: string]: RelativeSubscriptiveResourceList<VehiclePayload>;
};

const {
  select,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  selectChildResourceList,
  slice,
} = createRelativeSubscriptiveSlice({
  name: "vehicles",
  // @ts-expect-error _id does not exist
  idProp: "_id",
  payloadType: VehiclePayload,
  parentName: "companies",
  parentSingleName: "company",
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
});

export { unsubscribe, reducer, reconnect, onPublish, subscribe };

export default slice.reducer;

export const useVehicles = (companyId: string | null) => {
  const {
    resourceDictionary: vehiclesById,
    loading: vehiclesLoading,
    subscribed: vehiclesSubscribed,
  } = useSelector(r.partial(select, [companyId])) || {};

  const vehicles = useSelector(r.partial(selectChildResourceList, [companyId]));

  return {
    vehicles: vehicles as VehiclePayload[] | undefined,
    vehiclesById: vehiclesById as Record<string, VehiclePayload> | undefined,
    vehiclesLoading,
    vehiclesSubscribed,
  };
};
