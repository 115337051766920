import * as R from "ramda";
import { compareTwoStrings } from "string-similarity";
import {
  Button,
  Col,
  InputNumber,
  Layout,
  message,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useAuthConnectionEffect } from "hooks/useAuthConnectionEffect";
import * as AccountsSlice from "slices/accounts";
import { useAccounts } from "slices/accounts";
import { useDispatch } from "react-redux";
import * as DriversSlices from "slices/drivers";
import { useDrivers } from "slices/drivers";
import { CompanyPayload, DriverPayload } from "interfaces";
import { timezones } from "utils/timezone";
import * as CompaniesSlice from "slices/companies";
import { useCompanies } from "slices/companies";
import * as ManualPatchesSlice from "slices/manualPatches";
import { useManualPatches } from "slices/manualPatches";
import ManualPatchesTable from "components/ManualPatchesTable";
import { FileAddOutlined } from "@ant-design/icons";
import { v4 } from "uuid";
import { useAppDispatch } from "store/store";
import { useMyAccount } from "slices/myAccount";
import { create } from "slices/manualPatch";
import { useDebounce, useLocalStorageState } from "ahooks";

const ManualPatchesPage = () => {
  const [timezone, setTimezone] = useState<keyof typeof timezones>("UZ");
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [driverId, setDriverId] = useState<string | null>(null);
  const [origin, setOrigin] = useState<string | null>(null);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  const {
    companiesById,
    companiesSubscribed,
    companiesLoading,
  } = useCompanies();
  const [limit, setLimit] = useLocalStorageState(
    "activity-limit",
    // @ts-expect-error ahooks options type is not exported
    200
  );
  const debouncedLimit = useDebounce(limit, {
    wait: 3000,
  });
  const { accounts, accountsSubscribed, accountsLoading } = useAccounts();
  const { patches, patchesLoading, patchesSubscribed } = useManualPatches();
  const accountsById = R.indexBy(R.prop("id"), accounts);
  const { myAccount, myAccountSubscribed, myAccountLoading } = useMyAccount();
  const { driversById, driversLoading, driversSubscribed } = useDrivers(
    companyId
  );
  const filteredManualPatches = patches.filter((patch) => {
    return (
      (!origin || patch.origin === origin) &&
      (!companyId || patch.companyId === companyId) &&
      (!driverId || patch.driverId === driverId)
    );
  });
  useAuthConnectionEffect(() => {
    if (myAccount?.role) {
      dispatch(
        ManualPatchesSlice.subscribe({
          includeFinished: true,
          limit: debouncedLimit,
          lastUpdatedAt: null,
        })
      );
      if (myAccount.role === "admin") dispatch(AccountsSlice.subscribe());
      return () => {
        dispatch(
          ManualPatchesSlice.unsubscribe({
            includeFinished: true,
            limit: debouncedLimit,
          })
        );
        if (myAccount.role === "admin") dispatch(AccountsSlice.unsubscribe());
      };
    }
  }, [myAccount?.role, debouncedLimit]);

  useAuthConnectionEffect(() => {
    if (companyId && origin) {
      dispatch(DriversSlices.subscribe(companyId, { origin }));
      return () => {
        dispatch(DriversSlices.unsubscribe(companyId, { origin }));
      };
    }
  }, [companyId, origin]);

  useAuthConnectionEffect(() => {
    if (origin) {
      dispatch(CompaniesSlice.subscribe({ origin }));
      return () => {
        dispatch(CompaniesSlice.unsubscribe({ origin }));
      };
    }
  }, [origin]);
  return (
    <Layout className={"accounts-list-container"}>
      <Row justify={"space-between"}>
        <Col span={6}>
          <Typography.Title style={{ margin: "16px 0" }} level={3}>
            ACTIVITY
          </Typography.Title>
        </Col>

        <Col
          span={3}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Space size={20}>
            {!["shifter_hr", "shifter_day", "shifter_hr_day"].includes(
              myAccount?.role || ""
            ) ? (
              <Space>
                <div>LIMIT</div>
                <InputNumber value={limit} min={10} onChange={setLimit} />
              </Space>
            ) : null}

            <Button
              icon={<FileAddOutlined />}
              key="createButton"
              type="primary"
              disabled={
                ![
                  "admin",
                  "shifter_hr",
                  "shifter_day",
                  "shifter_hr_day",
                ].includes(myAccount?.role || "")
              }
              style={{ marginLeft: "auto" }}
              onClick={async () => {
                const clientResourceId = v4();
                const { status, msg, data } = await appDispatch(
                  create({
                    clientResourceId,
                    companyId,
                    driverId,
                    origin,
                    type: ["admin", "shifter_hr", "shifter_hr_day"].includes(
                      myAccount?.role || ""
                    )
                      ? "days"
                      : "hours",
                  })
                );
                if (status === "ok") {
                  console.log("data", data);
                  if (data?.id) {
                    window.open(`/activity/${data?.id}`, "_blank")?.focus();
                  }
                  message.success("Patch has been created");
                } else {
                  message.error(msg || "Error");
                }
              }}
            >
              CREATE
            </Button>
          </Space>
        </Col>
      </Row>
      <Layout.Content>
        <div className={"content"}>
          <div style={{ display: "flex", padding: 16 }}>
            <Row gutter={[8, 8]}>
              <Col>
                <Select
                  placeholder={"Select origin"}
                  onChange={(origin: string) => {
                    setOrigin(origin);
                    setDriverId(null);
                    setCompanyId(null);
                  }}
                  showSearch
                  getPopupContainer={(node: HTMLElement) =>
                    node.parentNode as HTMLElement
                  }
                  style={{ minWidth: 150 }}
                >
                  {process.env.REACT_APP_ORIGINS?.split(",").map((origin) => (
                    <Select.Option key={origin} value={origin}>
                      {origin}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col>
                <Tooltip
                  mouseEnterDelay={1}
                  getPopupContainer={(node: HTMLElement) =>
                    node.parentNode as HTMLElement
                  }
                  getTooltipContainer={(node: HTMLElement) =>
                    node.parentNode as HTMLElement
                  }
                  title="Select company"
                >
                  <Select
                    loading={companiesLoading}
                    disabled={!companiesSubscribed}
                    placeholder={"Select company"}
                    filterOption={(str, option) => {
                      const name = option?.children
                        .toLowerCase()
                        .replaceAll(" ", "");
                      const searchStr = str.toLowerCase().replaceAll(" ", "");
                      return (
                        !str ||
                        name.includes(searchStr) ||
                        compareTwoStrings(name, searchStr) > 0.8
                      );
                    }}
                    onChange={(companyId: string) => {
                      setCompanyId(companyId);
                      setDriverId(null);
                    }}
                    value={companyId || undefined}
                    showSearch
                    getPopupContainer={(node: HTMLElement) =>
                      node.parentNode as HTMLElement
                    }
                    style={{ minWidth: 150 }}
                  >
                    {Object.values(companiesById).map(
                      (company: CompanyPayload) => (
                        <Select.Option key={company._id} value={company._id}>
                          {company.name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip
                  mouseEnterDelay={1}
                  getPopupContainer={(node: HTMLElement) =>
                    node.parentNode as HTMLElement
                  }
                  getTooltipContainer={(node: HTMLElement) =>
                    node.parentNode as HTMLElement
                  }
                  title="Select driver"
                >
                  <Select
                    loading={driversLoading}
                    disabled={!driversSubscribed}
                    placeholder={"Select driver"}
                    filterOption={(str, option) => {
                      const name = option?.children.join("").toLowerCase();
                      const searchStr = str.toLowerCase();
                      return (
                        !str ||
                        name.includes(str.toLowerCase()) ||
                        compareTwoStrings(name, searchStr) > 0.8
                      );
                    }}
                    onChange={(driverId: string) => setDriverId(driverId)}
                    value={driverId || undefined}
                    showSearch
                    getPopupContainer={(node: HTMLElement) =>
                      node.parentNode as HTMLElement
                    }
                    style={{ minWidth: 150 }}
                  >
                    {Object.values(driversById || {}).map(
                      (driver: DriverPayload) => (
                        <Select.Option key={driver._id} value={driver._id}>
                          {driver.firstName} {driver.lastName}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Tooltip>
              </Col>
              {/*<DatePicker.RangePicker*/}
              {/*  allowClear={false}*/}
              {/*  getPopupContainer={(node: HTMLElement) =>*/}
              {/*    node.parentNode as HTMLElement*/}
              {/*  }*/}
              {/*  value={[startDate, endDate]}*/}
              {/*  onChange={(range) => {*/}
              {/*    if (range) {*/}
              {/*      updatePatch({*/}
              {/*        ...patch,*/}
              {/*        from: range[0]?.toISOString(),*/}
              {/*        to: range[1]?.toISOString(),*/}
              {/*      });*/}
              {/*    }*/}
              {/*  }}*/}
              {/*/>*/}
            </Row>
            <Radio.Group
              value={timezone}
              style={{ marginLeft: "auto" }}
              onChange={(e) => setTimezone(e.target.value)}
            >
              <Radio.Button value="UZ">UZ</Radio.Button>
              <Radio.Button value="ET">ET</Radio.Button>
              <Radio.Button value="CT">CT</Radio.Button>
              <Radio.Button value="MT">MT</Radio.Button>
              <Radio.Button value="PT">PT</Radio.Button>
            </Radio.Group>
          </div>
          <ManualPatchesTable
            loading={
              patchesLoading ||
              !patchesSubscribed ||
              myAccountLoading ||
              !myAccountSubscribed
            }
            account={myAccount || null}
            accountsById={accountsById}
            manualPatches={filteredManualPatches}
            timezone={timezone}
          />{" "}
        </div>
      </Layout.Content>
    </Layout>
  );
};
export default ManualPatchesPage;
