import * as R from "ramda";

export const DRIVING_EVENTS = {
  DS_D: true,
  DR_IND_PC: true,
  DR_IND_CLEARED: true,
  DR_IND_YM: true,
};
export const ON_DUTY_EVENTS = { DS_ON: true };
export const OFF_DUTY_EVENTS = {
  DS_OFF: true,
  DS_WT: true,
  DS_SB: true,
};

export const DUTY_EVENTS = {
  ...DRIVING_EVENTS,
  ...ON_DUTY_EVENTS,
  ...OFF_DUTY_EVENTS,
};

export const ENGINE_DOWN_EVENTS = {
  ENG_DOWN_NORMAL: true,
  ENG_DOWN_REDUCED: true,
};
export const ENGINE_UP_EVENTS = {
  ENG_UP_NORMAL: true,
  ENG_UP_REDUCED: true,
};
export const ENGINE_EVENTS = {
  ...ENGINE_UP_EVENTS,
  ...ENGINE_DOWN_EVENTS,
};

export const ERROR_EVENTS = {
  DIAG_ERR_LOGGED: true,
  DIAG_ERR_CLEARED: true,
  DIAG_EVT_LOGGED: true,
  DIAG_EVT_CLEARED: true,
};

export const INTERMEDIATE_EVENTS = {
  LOG_NORMAL_PRECISION: true,
  LOG_REDUCED_PRECISION: true,
};

export const LOGIN_LOGOUT_EVENTS = {
  DR_CERT_1: true,
  DR_CERT_2: true,
  DR_CERT_3: true,
  DR_CERT_4: true,
  DR_CERT_5: true,
  DR_CERT_6: true,
  DR_CERT_7: true,
  DR_CERT_8: true,
  DR_CERT_9: true,
};

export const ALLOWED_MINUTES_ERROR = 15;

export const ALL_EVENT_CODES = R.sortBy(R.prop("displayOrder"), [
  {
    id: "DS_OFF",
    type: "DUTY_STATUS",
    value: "1",
    origin: "DRIVER",
    status: "ACTIVE",
    label: "OFF",
    title: "Off Duty",
    detailedTitle: "Off Duty",
    displayOrder: 1,
    description: 'Driver duty status is "Off-duty"',
  },
  {
    id: "DS_WT",
    type: "DUTY_STATUS",
    value: "1",
    origin: "DRIVER",
    status: "ACTIVE",
    hide: true,
    label: "WT",
    title: "Off Duty (WT)",
    detailedTitle: "Off Duty (Waiting Time)",
    displayOrder: 2,
    description: 'Driver duty status is "Off-duty" (Waiting Time)',
  },
  {
    id: "DS_SB",
    type: "DUTY_STATUS",
    value: "2",
    origin: "DRIVER",
    status: "ACTIVE",
    label: "SB",
    title: "Sleeper",
    detailedTitle: "Sleeper",
    displayOrder: 3,
    description: 'Driver duty status is "Sleeper Berth"',
  },
  {
    id: "DS_D",
    type: "DUTY_STATUS",
    value: "3",
    origin: "ELD",
    status: "ACTIVE",
    label: "D",
    title: "Driving",
    detailedTitle: "Driving",
    displayOrder: 4,
    description: 'Driver duty status is "Driving"',
  },
  {
    id: "DS_ON",
    type: "DUTY_STATUS",
    value: "4",
    origin: "DRIVER",
    status: "ACTIVE",
    label: "ON",
    title: "On Duty",
    detailedTitle: "On Duty",
    displayOrder: 5,
    description: 'Driver duty status is "On-duty not driving"',
  },
  {
    id: "LOG_NORMAL_PRECISION",
    type: "LOG",
    title: "Intermediate w/ CLP",
    detailedTitle: "Intermediate w/ CLP",
    origin: "ELD",
    status: "ACTIVE",
    value: "1",
    displayOrder: 6,
    description: "Intermediate log with conventional location precision",
  },
  {
    id: "LOG_REDUCED_PRECISION",
    type: "LOG",
    title: "Intermediate w/ RLP",
    detailedTitle: "Intermediate w/ RLP",
    hide: true,
    value: "2",
    origin: "ELD",
    status: "ACTIVE",
    displayOrder: 7,
    description: "Intermediate log with reduced location precision",
  },
  {
    id: "DR_IND_PC",
    type: "DR_IND",
    value: "1",
    origin: "DRIVER",
    status: "ACTIVE",
    label: "PC",
    title: "Personal Use",
    detailedTitle: "Personal Use",
    displayOrder: 8,
    description: 'Driver indicates "Authorized Personal Use of CMV"',
  },
  {
    id: "DR_IND_YM",
    type: "DR_IND",
    value: "2",
    origin: "DRIVER",
    status: "ACTIVE",
    label: "YM",
    title: "Yard Moves",
    detailedTitle: "Yard Moves",
    displayOrder: 9,
    description: 'Driver indicates "Yard Moves"',
  },
  {
    id: "DR_IND_CLEARED",
    type: "DR_IND",
    value: "0",
    origin: "DRIVER",
    status: "ACTIVE",
    hide: true,
    title: "PC/YM Cleared",
    detailedTitle: "PC/YM Cleared",
    displayOrder: 10,
    description: "Driver indication for PC, YM cleared",
  },
  {
    id: "DR_CERT_1",
    value: "1",
    origin: "DRIVER",
    status: "NONE",
    disabled: false,
    type: "DR_CERT",
    title: "Certification",
    detailedTitle: "Certification (1)",
    displayOrder: 11,
    description: "Driver certification of a daily record 1",
  },
  {
    id: "DR_CERT_2",
    value: "2",
    origin: "DRIVER",
    status: "NONE",
    disabled: false,
    type: "DR_CERT",
    title: "Certification",
    detailedTitle: "Certification (2)",
    displayOrder: 12,
    description: "Driver certification of a daily record 2",
  },
  {
    id: "DR_CERT_3",
    value: "3",
    origin: "DRIVER",
    status: "NONE",
    disabled: false,
    type: "DR_CERT",
    title: "Certification",
    detailedTitle: "Certification (3)",
    displayOrder: 13,
    description: "Driver certification of a daily record 3",
  },
  {
    id: "DR_CERT_4",
    value: "4",
    origin: "DRIVER",
    status: "NONE",
    disabled: false,
    type: "DR_CERT",
    title: "Certification",
    detailedTitle: "Certification (4)",
    displayOrder: 14,
    description: "Driver certification of a daily record 4",
  },
  {
    id: "DR_CERT_5",
    value: "5",
    origin: "DRIVER",
    status: "NONE",
    disabled: false,
    type: "DR_CERT",
    title: "Certification",
    detailedTitle: "Certification (5)",
    displayOrder: 15,
    description: "Driver certification of a daily record 5",
  },
  {
    id: "DR_CERT_6",
    value: "6",
    origin: "DRIVER",
    status: "NONE",
    disabled: false,
    type: "DR_CERT",
    title: "Certification",
    detailedTitle: "Certification (6)",
    displayOrder: 16,
    description: "Driver certification of a daily record 6",
  },
  {
    id: "DR_CERT_7",
    value: "7",
    origin: "DRIVER",
    status: "NONE",
    disabled: false,
    type: "DR_CERT",
    title: "Certification",
    detailedTitle: "Certification (7)",
    displayOrder: 17,
    description: "Driver certification of a daily record 7",
  },
  {
    id: "DR_CERT_8",
    value: "8",
    origin: "DRIVER",
    status: "NONE",
    disabled: false,
    type: "DR_CERT",
    title: "Certification",
    detailedTitle: "Certification (8)",
    displayOrder: 18,
    description: "Driver certification of a daily record 8",
  },
  {
    id: "DR_CERT_9",
    value: "9",
    origin: "DRIVER",
    status: "NONE",
    disabled: false,
    type: "DR_CERT",
    title: "Certification",
    detailedTitle: "Certification (>=9)",
    displayOrder: 19,
    description: "Driver certification of a daily record >= 9",
  },
  {
    id: "DR_LOGIN",
    type: "DR_LOGIN",
    title: "Login",
    detailedTitle: "Login",
    value: "1",
    origin: "ELD",
    status: "NONE",
    displayOrder: 20,
    description: "Authenticated driver ELD login activity",
  },
  {
    id: "DR_LOGOUT",
    type: "DR_LOGIN",
    title: "Logout",
    detailedTitle: "Logout",
    value: "2",
    origin: "ELD",
    status: "NONE",
    displayOrder: 21,
    description: "Authenticated driver ELD logout activity",
  },
  {
    id: "ENG_UP_NORMAL",
    type: "ENGINE",
    title: "Engine Power-up w/ CLP",
    detailedTitle: "Engine Power-up w/ CLP",
    value: "1",
    origin: "ELD",
    status: "NONE",
    displayOrder: 22,
    description: "Engine power-up with conventional location precision",
  },
  {
    id: "ENG_UP_REDUCED",
    type: "ENGINE",
    value: "2",
    origin: "ELD",
    status: "NONE",
    hide: true,
    title: "Engine Power-up w/ RLP",
    detailedTitle: "Engine Power-up w/ RLP",
    displayOrder: 23,
    description: "Engine power-up with reduced location precision",
  },
  {
    id: "ENG_DOWN_NORMAL",
    type: "ENGINE",
    title: "Engine Shut-down w/ CLP",
    detailedTitle: "Engine Shut-down w/ CLP",
    value: "3",
    origin: "ELD",
    status: "NONE",
    displayOrder: 24,
    description: "Engine shut-down with conventional location precision",
  },
  {
    id: "ENG_DOWN_REDUCED",
    type: "ENGINE",
    title: "Engine Shut-down w/ RLP",
    detailedTitle: "Engine Shut-down w/ RLP",
    hide: true,
    value: "4",
    origin: "ELD",
    status: "NONE",
    displayOrder: 25,
    description: "Engine shut-down with reduced location precision",
  },
  {
    id: "DIAG_ERR_LOGGED",
    type: "DIAGNOSTIC",
    value: "1",
    origin: "ELD",
    status: "NONE",
    hide: true,
    title: "ELD malfunction",
    detailedTitle: "ELD malfunction",
    displayOrder: 26,
    description: "An ELD malfunction logged",
  },
  {
    id: "DIAG_ERR_CLEARED",
    type: "DIAGNOSTIC",
    value: "2",
    origin: "ELD",
    status: "NONE",
    hide: true,
    title: "ELD malfunction cleared",
    detailedTitle: "ELD malfunction cleared",
    displayOrder: 27,
    description: "An ELD malfunction cleared",
  },
  {
    id: "DIAG_EVT_LOGGED",
    type: "DIAGNOSTIC",
    value: "3",
    origin: "ELD",
    status: "NONE",
    hide: true,
    title: "Data diagnostic",
    detailedTitle: "Data diagnostic",
    displayOrder: 28,
    description: "A data diagnostic event logged",
  },
  {
    id: "DIAG_EVT_CLEARED",
    type: "DIAGNOSTIC",
    value: "4",
    origin: "ELD",
    status: "NONE",
    hide: true,
    title: "Data diagnostic cleared",
    detailedTitle: "Data diagnostic cleared",
    displayOrder: 29,
    description: "A data diagnostic event cleared",
  },
]);

export const ALL_EVENT_ORIGINS = ["auto", "driver"];
