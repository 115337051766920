import { ManualPatchPayload, ManualPatchType } from "interfaces";
import { createSingleSubscriptiveSlice } from "slices/subscriptive/single";
import { Dispatch } from "redux";
import { RootState } from "store/reducer";
import { Socket } from "socket.io-client";
import { ResponsePayload } from "slices/subscriptive";
import { emitAsync } from "utils/socket";
import { v4 } from "uuid";
import { onPublish as manualPatchesOnPublish } from "slices/manualPatches";
import { useSelector } from "react-redux";

const {
  select,
  unsubscribe,
  reducer,
  onPublish,
  subscribe,
  selectResource,
  slice,
} = createSingleSubscriptiveSlice({
  name: "manualPatch",
  reducers: {},
  volatile: false,
});

const { setLoading } = slice.actions;

export default slice.reducer;

export class CreateManualPatchPayload {
  clientResourceId: string;
  origin?: string | null;
  from?: string | null;
  to?: string | null;
  companyId?: string | null;
  driverId?: string | null;
  timezone?: string | null;
  shiftAmount?: number | null;
  type: ManualPatchType;
  driverName?: string | null;
}

export class UpdateManualPatchPayload extends CreateManualPatchPayload {
  id: string;
}

export class ManualPatchActionPayload {
  id: string;
  version: number;
}

const remove = (id: string, version: number) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload<null | undefined>> => {
  const socket = getSocket();
  return await emitAsync<ResponsePayload<null | undefined>>(
    socket,
    `manualPatch:finish-shift`,
    { id, version }
  );
};

export const startRollback = (
  id: string,
  version: number,
  restart: boolean
) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload<null | undefined>> => {
  const socket = getSocket();
  return await emitAsync<ResponsePayload<null | undefined>>(
    socket,
    `manualPatch:start-rollback`,
    { id, version, restart }
  );
};

export const stopRollback = (id: string, version: number) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload<null | undefined>> => {
  const socket = getSocket();
  return await emitAsync<ResponsePayload<null | undefined>>(
    socket,
    `manualPatch:stop-rollback`,
    { id, version }
  );
};

const start = (
  id: string,
  version: number,
  shiftAmount: number,
  type: ManualPatchType,
  fixCertifications: boolean
) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload<null | undefined>> => {
  const socket = getSocket();
  return await emitAsync<ResponsePayload<null | undefined>>(
    socket,
    `manualPatch:start-shift`,
    { id, version, shiftAmount, type, fixCertifications }
  );
};

const stop = (id: string, version: number) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload<null | undefined>> => {
  const socket = getSocket();
  return await emitAsync<ResponsePayload<null | undefined>>(
    socket,
    `manualPatch:stop-shift`,
    { id, version }
  );
};

const create = (patch: CreateManualPatchPayload) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload<{ id: string }>> => {
  const account = getState().myAccount.resource;
  const socket = getSocket();
  if (!!account) {
    const patchesCount = Object.keys(
      getState().manualPatches.resourceDictionary
    ).length;

    const now = new Date();
    if (patchesCount > 0) {
      const optimisticPatch: ManualPatchPayload = {
        incNumber: patchesCount + 1, // to avoid collisions
        ownerId: account.id,
        ...patch,
        version: 0,
        status: "created",
        id: v4(),
        createdAt: now.toISOString(),
        updatedAt: now.toISOString(),
        loading: true,
        deletedAt: null,
        shiftAmount: 1,
      };
      // await dispatch(onPublish(optimisticPatch));
      // await dispatch(manualPatchesOnPublish([optimisticPatch]));
      const response = await emitAsync<ResponsePayload<{ id: string }>>(
        socket,
        `manualPatch:create`,
        {
          ...patch,
          origin: patch.origin || process.env.REACT_APP_ORIGINS?.split(",")[0],
        }
      );
      if (response.status !== "ok") {
        const now = new Date();
        const deletedPatch = {
          ...optimisticPatch,
          deletedAt: now.toISOString(),
        };
        // dispatch(onPublish(deletedPatch));
        // dispatch(manualPatchesOnPublish([deletedPatch]));
      }
      return response;
    } else {
      return await emitAsync<ResponsePayload<{ id: string }>>(
        socket,
        `manualPatch:create`,
        patch
      );
    }
  }
  return {
    status: "error",
    msg: "Synchronization error",
  };
};

const update = (
  patch: UpdateManualPatchPayload,
  existingPatch: ManualPatchPayload
) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload> => {
  const socket = getSocket();
  if (!!existingPatch) {
    const optimisticPatch: ManualPatchPayload = {
      ...existingPatch,
      ...patch,
      version: existingPatch.version,
      loading: true,
      shiftAmount: 1,
    };
    await dispatch(onPublish(optimisticPatch));
    await dispatch(manualPatchesOnPublish([optimisticPatch]));

    const response = await emitAsync<ResponsePayload>(
      socket,
      `manualPatch:update`,
      patch
    );
    if (response.status !== "ok") {
      dispatch(onPublish(existingPatch));
      dispatch(manualPatchesOnPublish([existingPatch]));
    }
    return response;
  }
  return {
    status: "error",
    msg: "Synchronization error",
  };
};

const fetch = (id: string, version: number) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload> => {
  const socket = getSocket();
  return await emitAsync<ResponsePayload>(socket, `manualPatch:fetch`, {
    id,
    version,
  });
};

export {
  unsubscribe,
  reducer,
  onPublish,
  subscribe,
  slice,
  fetch,
  update,
  create,
  stop,
  start,
  remove,
};

export const useManualPatch = () => {
  const { loading, subscribed, resource } = useSelector(select);

  return {
    patch: resource as ManualPatchPayload | undefined,
    patchLoading: loading,
    patchSubscribed: subscribed,
  };
};
