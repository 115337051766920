import { createSubscriptiveSlice } from "slices/subscriptive";
import { ManualPatchPayload } from "interfaces";
import { useSelector } from "react-redux";

const {
  select,
  selectDictionary,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  selectResourceList,
  slice,
  initialState,
} = createSubscriptiveSlice({
  name: "manualPatches",
  payloadType: ManualPatchPayload,
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
  idProp: "clientResourceId",
});

const { setLoading } = slice.actions;

export default slice.reducer;

export {
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  slice,
  initialState,
};

export const useManualPatches = () => {
  const patches = useSelector(selectResourceList) as ManualPatchPayload[];
  const patchesById = useSelector(selectDictionary) as Record<
    string,
    ManualPatchPayload
  >;
  const { loading: patchesLoading, subscribed: patchesSubscribed } =
    useSelector(select);
  return {
    patches,
    patchesById,
    patchesLoading,
    patchesSubscribed,
  };
};
