import { createSubscriptiveSlice } from "slices/subscriptive";
import { AccountPayload } from "interfaces";
import { useSelector } from "react-redux";

const {
  select,
  selectDictionary,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  selectResourceList,
  slice,
  initialState,
} = createSubscriptiveSlice({
  name: "accounts",
  payloadType: AccountPayload,
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
  idProp: "clientResourceId",
});

const { setLoading } = slice.actions;

export default slice.reducer;

export {
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  slice,
  initialState,
  // patch,
};

export const useAccounts = () => {
  const { loading: accountsLoading, subscribed: accountsSubscribed } =
    useSelector(select);
  const accounts = useSelector(selectResourceList) as AccountPayload[];
  const accountsById = useSelector(selectDictionary) as Record<
    string,
    AccountPayload
  >;

  return {
    accounts,
    accountsById,
    accountsLoading,
    accountsSubscribed,
  };
};
