import { createSubscriptiveSlice, ResponsePayload } from "slices/subscriptive";
import { Dispatch } from "redux";
import { Socket } from "socket.io-client";
import { v4 } from "uuid";
import { emitAsync } from "utils/socket";
import { RootState } from "store/reducer";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import { DotPayload } from "interfaces";

export class CreateDotPayload {
  clientResourceId: string;
  message?: string | null;
  origin: string;
  from: string;
  companyId: string;
  driverId: string;
}

export class UpdateDotPayload extends CreateDotPayload {
  id: string;
}

const {
  select,
  selectDictionary,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  selectResourceList,
  slice,
  initialState,
} = createSubscriptiveSlice({
  name: "dots",
  payloadType: DotPayload,
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
  idProp: "clientResourceId",
});

const create =
  (dot: CreateDotPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    const clientResourceId = v4();
    const drivers = getState().drivers;
    const now = new Date();
    const optimisticDot: DotPayload = {
      ...dot,
      clientResourceId: dot.clientResourceId || clientResourceId,
      version: 0,
      number: 0,
      id: v4(),
      createdAt: now.toISOString(),
      updatedAt: now.toISOString(),
      from: (dot.from as unknown as Moment)?.toISOString(),
      loading: true,
      deletedAt: null,
      driverName: "N/A",
    };
    await dispatch(onPublish([optimisticDot]));
    const response = await emitAsync<ResponsePayload<null | undefined>>(
      socket,
      `dot:create`,
      optimisticDot
    );
    if (response.status !== "ok") {
      const now = new Date();
      dispatch(onPublish([{ ...optimisticDot, deletedAt: now.toISOString() }]));
    }
    return response;
  };

const update =
  (dot: UpdateDotPayload, existingDot: DotPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload> => {
    const socket = getSocket();
    if (!!existingDot) {
      const optimisticDot: DotPayload = {
        ...existingDot,
        ...dot,
        version: existingDot.version,
        loading: true,
      };
      await dispatch(onPublish([optimisticDot]));

      const response = await emitAsync<ResponsePayload>(
        socket,
        `dot:update`,
        dot
      );
      if (response.status !== "ok") {
        dispatch(onPublish([existingDot]));
      }
      return response;
    } else {
      return {
        status: "error",
        msg: "Synchronization error",
      };
    }
  };

const deleteDot =
  (existingDot: DotPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload> => {
    const socket = getSocket();
    if (!!existingDot) {
      const optimisticDot: DotPayload = {
        ...existingDot,
        deletedAt: moment().toISOString(),
        loading: true,
      };
      await dispatch(onPublish([optimisticDot]));

      const response = await emitAsync<ResponsePayload>(socket, `dot:delete`, {
        id: existingDot.id,
      });
      if (response.status !== "ok") {
        dispatch(onPublish([existingDot]));
      }
      return response;
    } else {
      return {
        status: "error",
        msg: "Synchronization error",
      };
    }
  };

const disableDot =
  (existingDot: DotPayload, state: boolean) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload> => {
    const socket = getSocket();
    if (!!existingDot) {
      const optimisticDot: DotPayload = {
        ...existingDot,
        loading: true,
        disabled: state,
      };
      await dispatch(onPublish([optimisticDot]));

      const response = await emitAsync<ResponsePayload>(socket, `dot:disable`, {
        id: existingDot.id,
        state,
      });
      if (response.status !== "ok") {
        dispatch(onPublish([existingDot]));
      }
      return response;
    } else {
      return {
        status: "error",
        msg: "Synchronization error",
      };
    }
  };

const { setLoading } = slice.actions;

export default slice.reducer;

export {
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  slice,
  initialState,
  update,
  create,
  deleteDot,
  disableDot,
};

export const useDots = () => {
  const { loading: dotsLoading, subscribed: dotsSubscribed } =
    useSelector(select);
  const dots = useSelector(selectResourceList) as DotPayload[];
  const dotsById = useSelector(selectDictionary) as Record<string, DotPayload>;

  return {
    dots,
    dotsById,
    dotsLoading,
    dotsSubscribed,
  };
};
