import { DriverNotePayload } from "interfaces";
import { createSingleSubscriptiveSlice } from "slices/subscriptive/single";
import { Dispatch } from "redux";
import { RootState } from "store/reducer";
import { Socket } from "socket.io-client";
import { ResponsePayload } from "slices/subscriptive";
import { emitAsync } from "utils/socket";
import { v4 } from "uuid";
import { useSelector } from "react-redux";

const {
  select,
  unsubscribe,
  reducer,
  onPublish,
  subscribe,
  selectResource,
  slice,
} = createSingleSubscriptiveSlice({
  name: "driverNote",
  volatile: true,
  reducers: {},
});

const { setLoading } = slice.actions;

export default slice.reducer;

export class CreateDriverNotePayload {
  driverId: string;
  freeMonth: string;
  origin: string;
  companyId: string;
}

const upsert = (driverNote: CreateDriverNotePayload) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload<null | undefined>> => {
  const socket = getSocket();
  const clientResourceId = v4();

  return await emitAsync<ResponsePayload<null | undefined>>(
    socket,
    `driverNote:upsert`,
    driverNote
  );
};

const deleteNote = (existingDriverNote: { driverId: string }) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  getSocket: () => Socket
): Promise<ResponsePayload> => {
  const socket = getSocket();

  return await emitAsync<ResponsePayload>(socket, `driverNote:delete`, {
    driverId: existingDriverNote.driverId,
  });
};

export {
  unsubscribe,
  reducer,
  onPublish,
  subscribe,
  slice,
  upsert,
  deleteNote,
};

export const useDriverNote = () => {
  const { resource, loading, subscribed } = useSelector(select);
  return {
    driverNote: (resource as unknown) as DriverNotePayload | undefined,
    driverNoteLoading: loading,
    driverNoteSubscribed: subscribed,
  };
};
