import {
  createSlice,
  Draft,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";
import { Dispatch } from "redux";
import { emitAsyncOrdered } from "utils/socket";
import { BaseModel } from "interfaces";

export interface SingleSubscriptiveResource<T> {
  loading: boolean;
  subscribed: boolean;
  lastUpdatedAt: string | undefined | null;
  resource: T | null;
}

export const createSingleSubscriptiveSlice = ({
  name,
  reducers,
  volatile,
}: {
  name: string;
  volatile: boolean;
  reducers: ValidateSliceCaseReducers<
    SingleSubscriptiveResource<BaseModel>,
    SliceCaseReducers<SingleSubscriptiveResource<BaseModel>>
  >;
}) => {
  type RootState = {
    [k: string]: SingleSubscriptiveResource<BaseModel>;
  };

  const selectResource = (state: RootState) => state[name].resource;

  const initialState: SingleSubscriptiveResource<BaseModel> = {
    resource: null,
    lastUpdatedAt: null,
    subscribed: false,
    loading: false,
  };

  const slice = createSlice({
    name: name,
    initialState,
    reducers: {
      setResource: (
        state: Draft<SingleSubscriptiveResource<BaseModel>>,
        { payload: resource }: PayloadAction<BaseModel>
      ) => {
        Object.assign(state, resource);
      },
      resetResource: (state: Draft<SingleSubscriptiveResource<BaseModel>>) => {
        state.resource = null;
      },
      onPublish: (
        state: Draft<SingleSubscriptiveResource<BaseModel>>,
        { payload }: PayloadAction<BaseModel>
      ) => {
        console.log(`on ${name} publish`, payload);
        const resource: BaseModel = payload;
        if (resource.deletedAt == null) {
          state.lastUpdatedAt = resource.updatedAt;
          state.resource = resource;
        } else {
          state.lastUpdatedAt = null;
          state.resource = null;
        }
      },

      setLoading: (state, { payload: loading }) => {
        state.loading = loading;
      },
      setSubscribed: (
        state,
        { payload: subscribed }: PayloadAction<boolean>
      ) => {
        state.subscribed = subscribed;
      },
      ...reducers,
    },
  });

  const { onPublish, setLoading, setSubscribed, resetResource } = slice.actions;
  const select = (state: RootState) => state[name];

  const unsubscribe = (data: Record<string, unknown>) => async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ) => {
    const socket = getSocket();
    dispatch(setLoading(true));
    socket.off(`${name}${data.id ? `/${data.id}` : ""}:publish`);
    if (getSocket().connected) {
      await emitAsyncOrdered(socket, name, `${name}:unsubscribe`, data);
    }
    if (volatile) {
      dispatch(resetResource());
    }
    dispatch(setLoading(false));
    dispatch(setSubscribed(false));
  };
  const subscribe = (data: Record<string, unknown>) => async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ) => {
    const socket = getSocket();
    const { lastUpdatedAt } = select(getState());

    dispatch(setLoading(true));
    socket.on(
      `${name}${data.id ? `/${data.id}` : ""}:publish`,
      (resource: BaseModel) => {
        dispatch(onPublish(resource));
      }
    );
    const { status } = await emitAsyncOrdered(
      socket,
      name,
      `${name}:subscribe`,
      {
        ...(lastUpdatedAt && { lastUpdatedAt }),
        ...data,
      }
    );
    dispatch(setSubscribed(status === "ok"));
    dispatch(setLoading(false));
  };

  const reducer = slice.reducer;
  return {
    select,
    selectResource,
    unsubscribe,
    reducer,
    onPublish,
    subscribe,
    slice,
    initialState,
  };
};
