import * as R from "ramda";
import { useMyAccount } from "slices/myAccount";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useAuthConnectionEffect } from "hooks/useAuthConnectionEffect";
import { useHistory, useParams } from "react-router-dom";
import * as ManualPatchSlice from "slices/manualPatch";
import {
  create,
  UpdateManualPatchPayload,
  useManualPatch,
} from "slices/manualPatch";
import * as AuditSlice from "slices/audits";
import { useAudits } from "slices/audits";
import * as DriverNoteSlice from "slices/driverNote";
import { useDriverNote } from "slices/driverNote";
import * as DotSlice from "slices/dots";
import { useDots } from "slices/dots";
import * as SubscriptionLockSlice from "slices/subscriptionLocks";
import { useSubscriptionLocks } from "slices/subscriptionLocks";
import * as PatchLogEventsSlice from "slices/patchLogEvents";
import { usePatchLogEvents } from "slices/patchLogEvents";
import * as DriverSlice from "slices/drivers";
import { useDrivers } from "slices/drivers";
import { useDispatch } from "react-redux";
import {
  AuditPayload,
  DotPayload,
  LogEvent,
  LogEventEditForm,
  ManualPatchType,
  PatchLogEventPayload,
  SubscriptionLockPayload,
} from "interfaces";
import { useAppDispatch } from "store/store";
import moment from "moment-timezone";
import { calculateLogCheck } from "hooks/calculateLogCheck";
import { timezones } from "utils/timezone";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  InputNumber,
  Layout,
  message,
  Modal,
  Popconfirm,
  Progress,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import SelectStep from "components/SelectStep";
import { getContainer } from "utils/html";
import { v4 } from "uuid";
import { getLogEvent, isCertification, isRestricted } from "utils/patch-log";
import { AutoSizer, List } from "react-virtualized";
import { useForm } from "antd/es/form/Form";
import { EventForm, originLabels } from "components/EventForm";
import { create as createPatcher, Delta } from "jsondiffpatch";
import * as PatchlogEventsSlice from "slices/patchLogEvents";
import { ALL_EVENT_CODES } from "../../constants";
import { EditOutlined } from "@ant-design/icons";

const timeLimiter = (events: any[], index: number) => {
  return (
    +new Date().getTime() - events[index].originData.eventTime.timestamp <=
    777600000
  );
};

const patcher = createPatcher();

export const getOriginData = (
  patchLogEvent: PatchLogEventPayload
): LogEvent => {
  if (patchLogEvent.updateData) {
    const clonedOriginData = patcher.clone(patchLogEvent.originData);
    patcher.patch(clonedOriginData, patchLogEvent.updateData as Delta);
    return clonedOriginData as LogEvent;
  }
  return (patchLogEvent.originData as unknown) as LogEvent;
};

export type ExtStatus = "init" | "uploading" | "finished";
export const eventLabels: Record<string, string> = {
  DS_OFF: "Off duty",
  LOG_NORMAL_PRECISION: "Intermediate w/ CLP",
  DR_LOGIN: "Login",
  DR_LOGOUT: "Logout",
  DS_ON: "On duty",
  DS_D: "Driving",
  DS_SB: "Sleeper",
  ENG_DOWN_NORMAL: "Engine Shut-down w/ CLP",
  ENG_UP_NORMAL: "Engine Power-up w/ CLP",
};

export const getEventStatus = (patchLogEvent: PatchLogEventPayload) => {
  if (patchLogEvent.failedAt) {
    return "error";
  } else if (patchLogEvent.committedAt) {
    return "done";
  } else if (!patchLogEvent.selected) {
    return "ignored";
  } else {
    return "pending";
  }
};

const ManualPatchDetails = () => {
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();

  let { manualPatchId } = useParams<{ manualPatchId: string }>();
  const [forceScrollIndex, setForceScrollIndex] = useState<number>();
  const { patch, patchSubscribed, patchLoading } = useManualPatch();

  const { auditsLoading, auditsSubscribed, auditsById } = useAudits();
  const { dots, dotsById, dotsLoading, dotsSubscribed } = useDots();
  const {
    subscriptionLocks,
    subscriptionLocksById,
    subscriptionLocksLoading,
    subscriptionLocksSubscribed,
  } = useSubscriptionLocks();
  const { driverNote } = useDriverNote();

  useAuthConnectionEffect(() => {
    if (patch?.origin && patch?.driverId && patch?.companyId) {
      dispatch(DriverNoteSlice.subscribe({ driverId: patch.driverId }));
      return () => {
        dispatch(DriverNoteSlice.unsubscribe({ driverId: patch.driverId }));
      };
    }
  }, [patch?.origin, patch?.driverId, patch?.companyId]);
  const {
    patchLogEvents,
    patchLogEventsLoading,
    patchLogEventsSubscribed,
  } = usePatchLogEvents(manualPatchId);

  const { driversById, driversSubscribed, driversLoading } =
    useDrivers(patch?.companyId || null) || {};

  const { myAccount, myAccountLoading, myAccountSubscribed } = useMyAccount();

  const [patchType, setPatchType] = useState<ManualPatchType>(
    ["admin", "shifter_hr", "shifter_hr_day"].includes(myAccount?.role || "")
      ? "hours"
      : "days"
  );

  const [fixCertifications, setFixCertifications] = useState(true);
  const history = useHistory();
  const [visible, setVisible] = useState<boolean>(false);

  const numberOfErrors = useMemo(() => {
    return patchLogEvents?.filter(({ failedAt }) => failedAt).length || 0;
  }, [patchLogEvents]);
  const numberOfSuccessful = useMemo(() => {
    return patchLogEvents?.filter(({ committedAt }) => committedAt).length || 0;
  }, [patchLogEvents]);
  const numberOfRolledBack = useMemo(() => {
    return patchLogEvents?.filter(({ rollbackAt }) => rollbackAt).length || 0;
  }, [patchLogEvents]);

  const totalNumber = patchLogEvents?.length || 0;
  const [shiftAmount, setShiftAmount] = useState(1);
  const totalSelected = useMemo(
    () =>
      !patchLogEvents
        ? 0
        : patchLogEvents.filter(
            (patchLogEvent) =>
              patchLogEvent.selected ||
              (fixCertifications && isCertification(getLogEvent(patchLogEvent)))
          ).length,
    [fixCertifications, patchLogEvents]
  );

  const sortedEvents = useMemo(
    () =>
      R.sortWith(
        [
          shiftAmount < 0
            ? R.descend(
                R.path<number>(["originData", "eventTime", "timestamp"])
              )
            : R.ascend(
                R.path<number>(["originData", "eventTime", "timestamp"])
              ),
        ],
        patchLogEvents || []
      ),
    [patchLogEvents, shiftAmount]
  );
  const lastFinishedIndex = useMemo(
    () =>
      Math.min(
        R.findLastIndex(
          (patchLogEvent) => !!patchLogEvent.committedAt,
          sortedEvents || []
        ) + 5,
        sortedEvents.length - 1
      ),
    [sortedEvents]
  );

  const downloadedAlert = useMemo((): undefined | null | [string, string] => {
    return (
      patchLogEvents &&
      R.pipe<
        PatchLogEventPayload[],
        LogEvent[],
        LogEvent[],
        null | [string, string]
      >(
        R.map((patchLog) => {
          return patchLog.originData;
        }),
        R.filter((logEvent: LogEvent) => {
          return (
            moment()
              .subtract(9, "days")
              .isBefore(moment(logEvent.eventTime.timestamp)) && !!logEvent.i
          );
        }),
        (logs) => {
          const count = logs.length;
          if (count) {
            const timestamps = logs.map((log) => log.eventTime.timestamp);
            const from = moment
              .tz(
                Math.min(...timestamps),
                timezones[logs[0].eventTime.logDate.timeZone.id] ||
                  "America/Los_Angeles"
              )
              .format("MMM DD, hh:mm:ss a");
            const to = moment
              .tz(
                Math.max(...timestamps),
                timezones[logs[0].eventTime.logDate.timeZone.id] ||
                  "America/Los_Angeles"
              )
              .format("MMM DD, hh:mm:ss a");
            return [
              `⚠️ Warning ⚠️`,
              `The driver has downloaded DOT inspection ${from} - ${to}`,
            ];
          } else {
            return null;
          }
        }
      )(patchLogEvents)
    );
  }, [patchLogEvents]);
  const patchInputsDisabled =
    !patch ||
    !myAccount ||
    patch.status === "shift_requested" ||
    patch.status === "shift_started" ||
    patch.status === "shift_stopped" ||
    !!patch.shiftFinishedAt ||
    !["admin", "shifter_hr", "shifter_day", "shifter_hr_day"].includes(
      myAccount.role || ""
    ) ||
    patchLoading ||
    !patchSubscribed;

  const conflictingDot: DotPayload | undefined = Object.values(dotsById).find(
    (dot) => {
      const dotFrom = moment(dot.from).subtract(9, "days").endOf("day");
      const dotTo = moment(dot.from).endOf("day");
      const patchMoments = [
        moment(patch?.from).startOf("day"),
        moment(patch?.to).endOf("day"),
        moment(patch?.from).subtract(shiftAmount, patchType).startOf("day"),
        moment(patch?.to).subtract(shiftAmount, patchType).endOf("day"),
      ];
      const patchMax = moment.max(patchMoments);
      const patchMin = moment.min(patchMoments);
      return (
        dot &&
        dot.origin === patch?.origin &&
        dot.driverId === patch?.driverId &&
        dot.companyId === patch?.companyId &&
        !dot.disabled &&
        (patchMin.isBetween(dotFrom, dotTo) ||
          patchMax.isBetween(dotFrom, dotTo) ||
          dotFrom.isBetween(patchMin, patchMax) ||
          dotTo.isBetween(patchMin, patchMax))
      );
    }
  );

  const conflictingSubscriptionLock:
    | SubscriptionLockPayload
    | undefined = Object.values(subscriptionLocksById).find(
    (subscriptionLock) => {
      return (
        subscriptionLock &&
        subscriptionLock.origin === patch?.origin &&
        (subscriptionLock.driverId === patch?.driverId ||
          subscriptionLock.driverId == null) &&
        subscriptionLock.companyId === patch?.companyId &&
        !subscriptionLock.disabled
      );
    }
  );

  const conflictingAudit: AuditPayload | undefined = Object.values(
    auditsById
  ).find((audit) => {
    const auditFrom = moment(audit.from).subtract(1, "day").endOf("day");
    const auditTo = moment(audit.to).add(1, "days").startOf("day");
    const patchMoments = [
      moment(patch?.from).startOf("day"),
      moment(patch?.to).endOf("day"),
      moment(patch?.from).subtract(shiftAmount, patchType).startOf("day"),
      moment(patch?.to).subtract(shiftAmount, patchType).endOf("day"),
    ];
    const patchMax = moment.max(patchMoments);
    const patchMin = moment.min(patchMoments);
    return (
      audit &&
      audit.origin === patch?.origin &&
      audit.driverId === patch?.driverId &&
      audit.companyId === patch?.companyId &&
      (patchMin.isBetween(auditFrom, auditTo) ||
        patchMax.isBetween(auditFrom, auditTo) ||
        auditFrom.isBetween(patchMin, patchMax) ||
        auditTo.isBetween(patchMin, patchMax))
    );
  });

  const alerts = [
    conflictingAudit
      ? [
          "CURRENT DRIVER IS PASSING AUDIT INSPECTION",
          conflictingAudit.message
            ? conflictingAudit.message
            : "Contact supervisor for more details",
        ]
      : null,
    conflictingDot
      ? [
          `CURRENT DRIVER HAD DOT INSPECTION ON ${moment(
            conflictingDot.from
          ).format("DD.MM.YYYY")}`,
          conflictingDot.message
            ? conflictingDot.message
            : "Contact supervisor for more details",
        ]
      : null,
    conflictingSubscriptionLock
      ? [
          "THIS DRIVER / COMPANY IS SUBSCRIPTION BLOCKED",
          conflictingSubscriptionLock.message
            ? conflictingSubscriptionLock.message
            : "Contact supervisor for more details",
        ]
      : null,
  ].filter((alert): alert is [string, string] => !!alert);

  const moveButtonDisabled =
    !patchLogEvents ||
    !patchLogEvents.length ||
    !patch ||
    !(patch.status === "fetch_finished" || patch.status === "shift_stopped") ||
    !shiftAmount ||
    !patchSubscribed ||
    !patchLogEventsSubscribed ||
    patchLoading ||
    patchLogEventsLoading;

  const finishButtonDisabled =
    !patch ||
    !(patch.status === "shift_stopped" || patch.status === "fetch_finished") ||
    !patchSubscribed ||
    !patchLogEventsSubscribed ||
    patchLoading ||
    patchLogEventsLoading;

  const fetchEvents = async () => {
    if (patch?.driverId && patch?.to && patch?.from) {
      dispatch(ManualPatchSlice.fetch(patch?.id, patch?.version));
    }
  };

  const updatePatch = async (patchUpdate: UpdateManualPatchPayload) => {
    if (patch) {
      const result = await appDispatch(
        ManualPatchSlice.update(
          {
            ...patchUpdate,
          },
          patch
        )
      );
      if (result.status !== "ok") {
        message.error(result?.msg || "Error");
      }
      if (result.status === "sync_error") {
        await dispatch(ManualPatchSlice.unsubscribe({ id: manualPatchId }));
        await dispatch(ManualPatchSlice.subscribe({ id: manualPatchId }));
      }
      return result;
    } else {
      return {
        status: "error",
      };
    }
  };

  const handleVisibleChange = async (visible: boolean) => {
    if (!visible) {
      setVisible(visible);
      return;
    }
    // Determining condition before show the popconfirm.
    if (!downloadedAlert && alerts.length === 0) {
      // await shiftData();
      setVisible(false);
    } else {
      setVisible(visible);
    }
  };

  const shiftData = () => {
    if (patch?.id) {
      dispatch(
        ManualPatchSlice.start(
          patch?.id,
          patch?.version,
          shiftAmount,
          patchType,
          fixCertifications
        )
      );
    }
  };

  useAuthConnectionEffect(() => {
    dispatch(AuditSlice.subscribe());
    dispatch(DotSlice.subscribe());
    dispatch(SubscriptionLockSlice.subscribe());
    return () => {
      dispatch(AuditSlice.unsubscribe());
      dispatch(DotSlice.unsubscribe());
      dispatch(SubscriptionLockSlice.unsubscribe());
    };
  }, []);

  useAuthConnectionEffect(() => {
    if (manualPatchId) {
      dispatch(ManualPatchSlice.subscribe({ id: manualPatchId }));
      return () => {
        dispatch(ManualPatchSlice.unsubscribe({ id: manualPatchId }));
      };
    }
  }, [manualPatchId]);

  useAuthConnectionEffect((): (() => any) | undefined => {
    if (patch?.companyId && patch?.origin) {
      const companyId = patch?.companyId;
      dispatch(DriverSlice.subscribe(companyId, { origin: patch?.origin }));
      return () =>
        dispatch(DriverSlice.unsubscribe(companyId, { origin: patch?.origin }));
    }
  }, [patch?.companyId, patch?.origin]);

  useAuthConnectionEffect(() => {
    dispatch(PatchLogEventsSlice.subscribe(manualPatchId));
    return () => {
      manualPatchId && dispatch(PatchLogEventsSlice.unsubscribe(manualPatchId));
    };
  }, [manualPatchId]);

  //   useEffect(() => {
  //     if (patch?.type) setPatchType(patch?.type);
  //   }, [patch?.type]);

  useEffect(() => {
    if (patch?.fixCertifications)
      setFixCertifications(!!patch?.fixCertifications);
  }, [patch?.fixCertifications]);

  useEffect(() => {
    if (patch?.shiftAmount != null) {
      setShiftAmount(patch?.shiftAmount);
    }
  }, [patch?.shiftAmount]);

  useEffect(() => {
    if (myAccount?.role) {
      if (myAccount?.role === "shifter_day") {
        setPatchType("days");
      }
      if (myAccount?.role === "shifter_hr") {
        setPatchType("hours");
      }
    }
  }, [myAccount?.role, patchType]);
  const duplicateButton = (
    <Col className="mr-auto">
      <Button
        color="primary"
        onClick={async () => {
          const clientResourceId = v4();
          const { status, msg, data } = await appDispatch(
            create({
              clientResourceId,
              companyId: patch?.companyId,
              driverId: patch?.driverId,
              origin: patch?.origin,
              type: patchType,
              shiftAmount,
              from: patch?.from,
              to: patch?.to,
            })
          );
          if (status === "ok") {
            if (data?.id) {
              history.push(`/activity/${data?.id}`);
            }
            message.success("Patch has been created");
          } else {
            message.error(msg || "Error");
          }
        }}
      >
        NEXT LOG
      </Button>
    </Col>
  );
  const indeterminateSelected =
    totalSelected > 0 && totalSelected < sortedEvents.length;
  const allSelected =
    totalSelected === sortedEvents.length && sortedEvents.length > 0;
  const selectAllDisabled =
    !patch ||
    ["shift_started", "shift_requested"].includes(patch.status) ||
    sortedEvents.length === 0 ||
    sortedEvents.length === numberOfSuccessful;
  const disabled =
    !myAccount ||
    !!patch?.shiftFinishedAt ||
    !["admin", "shifter_hr", "shifter_day", "shifter_hr_day"].includes(
      myAccount?.role || ""
    ) ||
    patchLogEventsLoading;

  const onSelectAllChange = (state: boolean) => {
    if (patch) {
      appDispatch(
        PatchLogEventsSlice.selectAllLogEvents({
          state,
          manualPatchId: patch.id,
        })
      );
    }
  };
  const onSelectChange = (ids: string[], state: boolean) => {
    if (patch) {
      appDispatch(
        PatchLogEventsSlice.selectLogEvents({
          logIds: ids,
          state,
          manualPatchId: patch.id,
        })
      );
    }
  };
  const [lastSelectedAction, setLastSelectedAction] = useState<
    undefined | { id: string; state: boolean }
  >();
  const [form] = useForm<LogEventEditForm>();
  const [editing, setEditing] = useState<{
    patchLogEvent: PatchLogEventPayload;
    loading: boolean;
    resetLoading: boolean;
  }>();
  console.timeEnd("RENDER");

  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [logCheckEnabled, setLogCheckEnabled] = useState(true);
  const LogCheck = useMemo(() => {
    try {
      return logCheckEnabled ? calculateLogCheck(sortedEvents) : [];
    } catch (e) {
      return [];
    }
  }, [sortedEvents, logCheckEnabled]);
  console.log("LogCheck ==>");
  console.log(LogCheck);
  console.log("SortedEvents ==> ");
  console.log(sortedEvents);

  const joinLog = (arr: any[]) => {
    let result: string = "";
    for (let i = 0; i < arr.length; i++) {
      const tempTimeLimiter = logCheckEnabled
        ? timeLimiter(sortedEvents, i)
        : false;
      if (arr[i].violation.status && tempTimeLimiter) {
        result += arr[i].violation.log.join(", ");
      }
    }
    return result;
  };

  const handleOk = () => {
    setIsErrorModalVisible(false);
  };

  const handleCancel = () => {
    setIsErrorModalVisible(false);
  };

  const joinedLog = useMemo(() => joinLog(LogCheck), [LogCheck]);
  const hasViolations = joinedLog.length > 0;
  const hasCasualErrors = useMemo(
    () =>
      LogCheck.some(
        (log, index) => log.casual.status && timeLimiter(sortedEvents, index)
      ),
    [LogCheck]
  );

  return patch && myAccount ? (
    <Layout className={"accounts-list-container"}>
      <Layout.Content style={{ display: "flex", flexDirection: "column" }}>
        <>
          <Modal
            visible={editing != null}
            title="EDIT EVENT"
            okText="EDIT"
            cancelText="CANCEL"
            onCancel={() => {
              setEditing(undefined);
              form.resetFields();
            }}
            confirmLoading={editing != null && editing.loading}
            onOk={() => {
              form.submit();
            }}
            footer={
              editing != null &&
              ["admin", "shifter_hr", "shifter_day", "shifter_hr_day"].includes(
                myAccount.role || ""
              ) &&
              editing.patchLogEvent?.committedAt == null ? (
                <Space>
                  {editing.patchLogEvent?.updateData != null ? (
                    <Button
                      loading={editing.resetLoading}
                      disabled={editing.loading}
                      key="reset"
                      onClick={async () => {
                        const logId = form.getFieldValue("logId");
                        try {
                          setEditing((editing) =>
                            editing
                              ? { ...editing, resetLoading: true }
                              : editing
                          );
                          const result = await appDispatch(
                            PatchlogEventsSlice.updateLogEvent({
                              data: undefined,
                              manualPatchId: patch.id,
                              logId,
                            })
                          );
                          if (result.status !== "ok") {
                            message.error(result?.msg || "Error");
                          }
                          if (form.getFieldValue("logId") === logId) {
                            setEditing(undefined);
                            form.resetFields();
                          }
                        } finally {
                          if (form.getFieldValue("logId") === logId) {
                            setEditing((editing) =>
                              editing != null
                                ? {
                                    ...editing,
                                    resetLoading: false,
                                  }
                                : editing
                            );
                          }
                        }
                      }}
                    >
                      Reset
                    </Button>
                  ) : null}
                  <Button
                    key="back"
                    onClick={() => {
                      setEditing(undefined);
                      form.resetFields();
                    }}
                    disabled={editing.loading || editing.resetLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    disabled={editing.resetLoading}
                    loading={editing.loading}
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Submit
                  </Button>
                </Space>
              ) : null
            }
          >
            <EventForm
              readOnly={
                editing != null && editing.patchLogEvent?.committedAt != null
              }
              loading={
                editing != null && (editing.loading || editing.resetLoading)
              }
              setLoading={(loading) => {
                setEditing((editing) =>
                  editing != null
                    ? {
                        ...editing,
                        loading,
                      }
                    : editing
                );
              }}
              form={form}
              onSubmit={() => {
                setEditing(undefined);
                form.resetFields();
              }}
            />
          </Modal>
          <Row style={{ width: "100%" }} gutter={8}>
            <Col>
              <SelectStep
                onlyLast8Days={myAccount.role === "shifter_hr"}
                disabled={patchInputsDisabled}
                patch={patch}
                updatePatch={updatePatch}
                fetchEvents={fetchEvents}
              />
            </Col>
            <Col>
              <Button
                // danger={joinedLog.length ? true : false}
                disabled={!hasViolations}
                type="primary"
                onClick={() => {
                  setIsErrorModalVisible(true);
                }}
                style={
                  hasViolations
                    ? {
                        backgroundColor: "#C62828",
                        color: "white",
                        border: "none",
                      }
                    : undefined
                }
              >
                VIOLATION
              </Button>
            </Col>
            <Col>
              <Button
                disabled={!hasCasualErrors}
                type="default"
                onClick={() => {
                  const nextViolationIndex = LogCheck.findIndex(
                    (logCheck, index) => {
                      return (
                        logCheck.casual.status &&
                        timeLimiter(sortedEvents, index)
                      );
                    }
                  );
                  setForceScrollIndex(nextViolationIndex);
                  requestAnimationFrame(() => {
                    setForceScrollIndex(undefined);
                  });
                }}
              >
                SCROLL TO ERROR
              </Button>
            </Col>
            <Col>
              <Modal
                title="VIOLATION ERROR"
                visible={isErrorModalVisible}
                centered={true}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
              >
                <p>{joinedLog}</p>
              </Modal>
            </Col>
            {patch.driverId && patch.origin && patch.companyId ? (
              <Col style={{ marginLeft: "auto", float: "right" }}>
                <Tooltip
                  placement={"topRight"}
                  title={
                    driverNote
                      ? `Checked by ${driverNote?.ownerUsername}`
                      : "No record available"
                  }
                >
                  <DatePicker
                    value={
                      driverNote?.freeMonth
                        ? moment(driverNote?.freeMonth, "MMMM")
                        : null
                    }
                    onChange={(value) => {
                      if (patch.driverId && patch.origin && patch.companyId)
                        if (value) {
                          dispatch(
                            DriverNoteSlice.upsert({
                              freeMonth: value.format("MMMM"),
                              driverId: patch.driverId,
                              companyId: patch.companyId,
                              origin: patch.origin,
                            })
                          );
                        } else {
                          dispatch(
                            DriverNoteSlice.deleteNote({
                              driverId: patch.driverId,
                            })
                          );
                        }
                    }}
                    picker="month"
                    format={"MMMM"}
                  />
                </Tooltip>
              </Col>
            ) : null}
          </Row>
          {!!downloadedAlert && (
            <Alert
              style={{ marginTop: 16 }}
              message={
                <div>
                  <h3>{downloadedAlert[0]}</h3>
                  <b>{downloadedAlert[1]}</b>
                </div>
              }
              type="error"
            />
          )}

          {alerts.map(([title, description]) => (
            <Alert
              style={{ marginTop: 16 }}
              message={
                <div>
                  <h3>{title}</h3>
                  <b>{description}</b>
                </div>
              }
              type="error"
            />
          ))}
          {patchLogEvents && patchLogEvents?.length > 0 ? (
            <Tooltip
              placement={"bottom"}
              overlayStyle={{ maxWidth: "100vh" }}
              getPopupContainer={getContainer}
              getTooltipContainer={getContainer}
              title={`${numberOfErrors} errors | ${
                numberOfSuccessful - numberOfRolledBack
              } succeed | ${numberOfRolledBack} rolled back | ${
                totalSelected - (numberOfSuccessful + numberOfErrors)
              } left | ignored ${totalNumber - totalSelected}`}
            >
              <Progress
                format={(percent, successPercent) =>
                  `${numberOfSuccessful - numberOfRolledBack}/${totalSelected}`
                }
                style={{ marginTop: 8, paddingRight: 30 }}
                status={numberOfErrors ? "exception" : "normal"}
                success={{
                  percent: Math.round(
                    ((numberOfSuccessful - numberOfRolledBack) / totalNumber) *
                      100
                  ),
                }}
                percent={Math.round((totalSelected / totalNumber) * 100)}
              />
            </Tooltip>
          ) : null}
          <>
            <div className="rtl-table-row rtl-table-header">
              <div className="select">
                <Checkbox
                  indeterminate={indeterminateSelected}
                  checked={allSelected}
                  disabled={selectAllDisabled || disabled}
                  onChange={() => {
                    onSelectAllChange(indeterminateSelected || !allSelected);
                  }}
                />
              </div>
              <div className="big">TIME</div>
              <div>EVENT</div>
              <div className="small">STATUS</div>
              <div className="big">LOCATION</div>
              <div className="small">SYSTEM</div>
              <div>ODOMETER</div>
              <div>ENGINE HOURS</div>
              {/*<div>_ID</div>*/}
              <div>NOTES</div>
              <div className="small">ID</div>
              <div>DRIVER</div>
              <div className="big">STATUS</div>
              <div style={{ width: 40 }} />
            </div>
            <div className="rtl-table">
              <AutoSizer>
                {({ width, height }) => (
                  <Spin
                    style={{
                      width,
                      height,
                    }}
                    spinning={
                      ["fetch_started", "fetch_requested"].includes(
                        patch.status
                      ) || !patchLogEventsSubscribed
                    }
                  >
                    <List
                      scrollToIndex={
                        ["shift_requested", "shift_started"].includes(
                          patch?.status || ""
                        ) && lastFinishedIndex >= 0
                          ? lastFinishedIndex
                          : forceScrollIndex != null
                          ? forceScrollIndex
                          : undefined
                      }
                      className="rtl-list"
                      ref="List"
                      height={height}
                      overscanRowCount={20}
                      //   noRowsRenderer={this._noRowsRenderer}
                      rowCount={(sortedEvents || []).length}
                      rowHeight={40}
                      width={width}
                      rowRenderer={({ index, style }) => {
                        const patchLogEvent = (sortedEvents || [])[index];
                        const logEvent = getOriginData(patchLogEvent);

                        const timeLimiter = (events: any[], index: number) => {
                          if (
                            +new Date().getTime() -
                              events[index].originData.eventTime.timestamp >
                            777600000
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        };

                        let isMainStatus: boolean = false;
                        if (
                          logEvent.eventCode.id !== "DR_LOGOUT" &&
                          logEvent.eventCode.id !== "DR_LOGIN" &&
                          !logEvent.eventCode.id.includes("DR_CERT")
                        ) {
                          isMainStatus = true;
                        }

                        const tempTimeLimiter = logCheckEnabled
                          ? timeLimiter(sortedEvents, index)
                          : false;
                        const casualErrorStatus = tempTimeLimiter
                          ? LogCheck[index].casual.status
                          : false;
                        const warningErrorStatus = tempTimeLimiter
                          ? LogCheck[index].warning.status
                          : false;
                        const casualErrorLog = tempTimeLimiter
                          ? LogCheck[index].casual.log.join(", ")
                          : "";
                        const warningErrorLog = tempTimeLimiter
                          ? LogCheck[index].warning.log.join(", ")
                          : "";

                        // console.log("EVENTS =>>> ");
                        // console.log(sortedEvents);

                        // console.log(
                        //   "TIMEEEEEEEEEEEE " +
                        //     index +
                        //     moment
                        //       .tz(
                        //         sortedEvents[index].originData.eventTime
                        //           .timestamp,
                        //         timezones[
                        //           sortedEvents[index].originData.eventTime
                        //             .logDate.timeZone.id
                        //         ] || "America/Los_Angeles"
                        //       )
                        //       .format("MMM DD, hh:mm:ss a")
                        // );
                        const user = (driversById || {})[logEvent.userId];
                        let status;
                        const additionalStatus = patchLogEvent.loading
                          ? "(LOADING)"
                          : patchLogEvent.updateData != null
                          ? "(WITH OVERRIDE)"
                          : null;

                        if (patchLogEvent.failedAt) {
                          status = (
                            <Tag color={"error"}>ERROR {additionalStatus}</Tag>
                          );
                        } else if (patchLogEvent.rollbackAt) {
                          status = (
                            <Tag color={"warning"}>
                              ROLLED BACK {additionalStatus}
                            </Tag>
                          );
                        } else if (patchLogEvent.committedAt) {
                          status = (
                            <Tag color={"success"}>DONE {additionalStatus}</Tag>
                          );
                        } else if (!patchLogEvent.selected) {
                          status = (
                            <Tag color={"default"}>
                              IGNORED {additionalStatus}
                            </Tag>
                          );
                        } else {
                          status = (
                            <Tag color={"processing"}>
                              PENDING {additionalStatus}
                            </Tag>
                          );
                        }
                        const selected =
                          patchLogEvent.selected ||
                          isRestricted(fixCertifications, patchLogEvent);

                        return (
                          <>
                            <Tooltip
                              title={casualErrorLog + warningErrorLog}
                              placement="topLeft"
                            >
                              <div
                                key={patchLogEvent.id}
                                className={`rtl-table-row ${
                                  selected ? "rtl-table-row-selected" : ""
                                }`}
                                style={
                                  casualErrorStatus
                                    ? {
                                        ...style,
                                        borderBottom: "1px solid #ededed",
                                        backgroundColor: "rgba(239,83,80,0.51)",
                                        color: "black",
                                      }
                                    : warningErrorStatus
                                    ? {
                                        ...style,
                                        borderBottom: "1px solid #ededed",
                                        backgroundColor: "rgba(255,167,38,0.6)",
                                        color: "black",
                                      }
                                    : {
                                        ...style,
                                        borderBottom: "1px solid #ededed",
                                      }
                                }
                              >
                                <div className="select">
                                  <Checkbox
                                    checked={selected}
                                    disabled={
                                      selectAllDisabled ||
                                      ["done"].includes(
                                        getEventStatus(patchLogEvent)
                                      ) ||
                                      disabled ||
                                      isRestricted(
                                        fixCertifications,
                                        patchLogEvent
                                      )
                                    }
                                    // disabled={this.state.disabled}
                                    onChange={(event) => {
                                      if (!patchLogEvent.loading) {
                                        if (
                                          event.nativeEvent.shiftKey &&
                                          lastSelectedAction
                                        ) {
                                          const notFinishedEvents = sortedEvents.filter(
                                            (event) => {
                                              return !event.committedAt;
                                            }
                                          );
                                          const index = notFinishedEvents.findIndex(
                                            ({ id }) =>
                                              id === lastSelectedAction.id
                                          );
                                          const newIndex = notFinishedEvents.findIndex(
                                            ({ id }) => id === patchLogEvent.id
                                          );
                                          const ids = R.pluck(
                                            "id",
                                            newIndex > index
                                              ? R.slice(
                                                  index,
                                                  newIndex + 1,
                                                  sortedEvents
                                                )
                                              : R.slice(
                                                  newIndex,
                                                  index + 1,
                                                  sortedEvents
                                                )
                                          );
                                          onSelectChange(
                                            ids,
                                            !patchLogEvent.selected
                                          );
                                        } else {
                                          onSelectChange(
                                            [patchLogEvent.id],
                                            !patchLogEvent.selected
                                          );
                                        }
                                        setLastSelectedAction({
                                          id: patchLogEvent.id,
                                          state: !patchLogEvent.selected,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="big">
                                  {moment
                                    .tz(
                                      logEvent.eventTime.timestamp,
                                      timezones[
                                        logEvent.eventTime.logDate.timeZone.id
                                      ] || "America/Los_Angeles"
                                    )
                                    .format("MMM DD, hh:mm:ss a")}
                                </div>
                                <div>
                                  {eventLabels[logEvent.eventCode.id] ||
                                    (logEvent.eventCode.id.includes("DR_CERT")
                                      ? "Certification"
                                      : logEvent.eventCode.id)}
                                </div>
                                <div className="small">
                                  {logEvent.recordStatus?.id || "Active"}
                                </div>
                                <div
                                  className="big"
                                  style={
                                    tempTimeLimiter
                                      ? isMainStatus
                                        ? LogCheck[index].values.location !==
                                          "NO DATA"
                                          ? undefined
                                          : {
                                              backgroundColor: "#D50000",
                                              color: "white",
                                            }
                                        : undefined
                                      : undefined
                                  }
                                >
                                  {tempTimeLimiter
                                    ? LogCheck[index].values.location
                                    : logEvent.location?.calculatedLocation
                                    ? logEvent.location.calculatedLocation
                                    : logEvent.driverLocationDescription}
                                </div>
                                <div className="small">
                                  {originLabels[logEvent.recordOrigin?.id] ||
                                    logEvent.recordOrigin?.id}
                                </div>
                                <div
                                  style={
                                    tempTimeLimiter
                                      ? !sortedEvents[
                                          index
                                        ].originData.eventCode.id.includes(
                                          "DR_CERT"
                                        )
                                        ? LogCheck[index].values.odometer !==
                                          "NO DATA"
                                          ? undefined
                                          : {
                                              backgroundColor: "#D50000",
                                              color: "white",
                                            }
                                        : undefined
                                      : undefined
                                  }
                                >
                                  {tempTimeLimiter
                                    ? LogCheck[index].values.odometer
                                    : logEvent.totalVehicleMiles}
                                </div>
                                <div
                                  style={
                                    tempTimeLimiter
                                      ? !sortedEvents[
                                          index
                                        ].originData.eventCode.id.includes(
                                          "DR_CERT"
                                        )
                                        ? LogCheck[index].values.engHours !==
                                          "NO DATA"
                                          ? undefined
                                          : {
                                              backgroundColor: "#D50000",
                                              color: "white",
                                            }
                                        : undefined
                                      : undefined
                                  }
                                >
                                  {tempTimeLimiter
                                    ? LogCheck[index].values.engHours
                                    : logEvent.totalEngineHours}
                                </div>
                                {/*<div>{logEvent._id}</div>*/}
                                <div>{logEvent.eventComment}</div>
                                <div className="small">
                                  {logEvent.seqId &&
                                    parseInt(logEvent.seqId, 16)}
                                </div>
                                <div>
                                  {user?.firstName} {user?.lastName}
                                </div>
                                <div className="big">{status}</div>
                                <div
                                  style={{
                                    width: 40,
                                    cursor: "pointer",
                                    textAlign: "right",
                                  }}
                                  onClick={() => {
                                    if (patchLogEvent.loading) return;
                                    console.log(
                                      "Editing",
                                      patchLogEvent,
                                      LogCheck[index]
                                    );
                                    setEditing({
                                      resetLoading: false,
                                      patchLogEvent,
                                      loading: false,
                                    });
                                    const codeObj = ALL_EVENT_CODES.find(
                                      (c) => c.id === logEvent.eventCode.id
                                    );
                                    form.setFieldsValue({
                                      originData: patchLogEvent.originData,
                                      time: moment.tz(
                                        logEvent.eventTime.timestamp,
                                        timezones[
                                          logEvent.eventTime.logDate.timeZone.id
                                        ] || "America/Los_Angeles"
                                      ),
                                      code: logEvent.eventCode.id,
                                      logId: patchLogEvent.id,
                                      origin:
                                        codeObj?.origin ||
                                        logEvent.recordOrigin.id,
                                      vehicle: logEvent.vehicle.id,
                                      totalEngineHours:
                                        logEvent.totalEngineHours,
                                      odometer: logEvent.totalVehicleMiles,
                                      lat: logEvent.location?.lat,
                                      lon: logEvent.location?.lon,
                                      locationOrigin: logEvent.location
                                        ? "automatic"
                                        : "manual",
                                      locationNotes:
                                        logEvent.driverLocationDescription,
                                      calculatedLocation:
                                        logEvent.location?.calculatedLocation,
                                      notes: logEvent.eventComment,
                                    });
                                    // form.setFields([{
                                    //   name: "",
                                    //   value:
                                    // }]);
                                  }}
                                >
                                  <EditOutlined />
                                </div>
                              </div>
                            </Tooltip>
                          </>
                        );
                      }}
                    />
                  </Spin>
                )}
              </AutoSizer>
            </div>
            <Row className="mt-3" justify="end" gutter={16} align="middle">
              {duplicateButton}
              <Col>
                <Tooltip title="Use log check for last 9 days?">
                  <Button
                    type={logCheckEnabled ? "primary" : "default"}
                    onClick={() => {
                      setLogCheckEnabled(
                        (oldLogCheckDisabled) => !oldLogCheckDisabled
                      );
                    }}
                  >
                    LOG CHECK {logCheckEnabled ? "ON" : "OFF"}
                  </Button>
                </Tooltip>
              </Col>
              {patch.shiftFinishedAt &&
              ["admin", "manager"].includes(myAccount.role || "") ? (
                <>
                  <Col>
                    {`Moved on ${patch.shiftAmount} ${patch.type} `.toUpperCase()}
                  </Col>
                  {patch.status === "shift_rollback_started" ? (
                    <Col>
                      <Button
                        color="primary"
                        onClick={() => {
                          appDispatch(
                            ManualPatchSlice.stopRollback(
                              patch?.id,
                              patch?.version
                            )
                          );
                        }}
                      >
                        STOP ROLLBACK
                      </Button>
                    </Col>
                  ) : (
                    <>
                      {numberOfRolledBack > 0 ? (
                        <Col>
                          <Button
                            color="primary"
                            onClick={() => {
                              appDispatch(
                                ManualPatchSlice.startRollback(
                                  patch?.id,
                                  patch?.version,
                                  true
                                )
                              );
                            }}
                          >
                            RESTART ROLLBACK
                          </Button>
                        </Col>
                      ) : null}

                      {numberOfRolledBack !== numberOfSuccessful ? (
                        <Col>
                          <Button
                            onClick={() => {
                              appDispatch(
                                ManualPatchSlice.startRollback(
                                  patch?.id,
                                  patch?.version,
                                  false
                                )
                              );
                            }}
                            color="primary"
                            type="primary"
                          >
                            {numberOfRolledBack > 0
                              ? "CONTINUE ROLLBACK"
                              : "ROLLBACK"}
                          </Button>
                        </Col>
                      ) : null}
                    </>
                  )}
                </>
              ) : null}
              {!patch.shiftFinishedAt &&
              ["admin", "shifter_hr", "shifter_day", "shifter_hr_day"].includes(
                myAccount.role || ""
              ) ? (
                <>
                  <Col>
                    <Tooltip
                      mouseEnterDelay={1}
                      getPopupContainer={getContainer}
                      getTooltipContainer={getContainer}
                      title={`Specify shift back in ${patchType}`}
                    >
                      <InputNumber
                        disabled={patchInputsDisabled}
                        value={shiftAmount}
                        precision={0}
                        onChange={(val: number) => setShiftAmount(val)}
                        placeholder={`${patchType === "hours" ? "HH" : "DD"}`}
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    {["shifter_hr_day", "admin"].includes(
                      myAccount.role || ""
                    ) ? (
                      <Tooltip
                        mouseEnterDelay={1}
                        getPopupContainer={getContainer}
                        getTooltipContainer={getContainer}
                        title={`Fix certifications? (last 9 days)`}
                      >
                        <Button
                          // color="primary"
                          type={fixCertifications ? "primary" : "ghost"}
                          disabled={patchInputsDisabled}
                          onClick={() => {
                            setFixCertifications(R.not);
                          }}
                        >
                          CERTIFICATIONS
                        </Button>
                      </Tooltip>
                    ) : null}
                  </Col>
                  <Col>
                    {["shifter_hr_day", "admin"].includes(
                      myAccount.role || ""
                    ) ? (
                      <Button
                        // color="primary"
                        type={patchType === "hours" ? "primary" : "ghost"}
                        disabled={patchInputsDisabled}
                        onClick={() => {
                          setPatchType(
                            patchType === "hours" ? "days" : "hours"
                          );
                        }}
                      >
                        HRS
                      </Button>
                    ) : null}
                  </Col>
                  <Col>
                    <Button
                      disabled={
                        !["shift_started", "shift_requested"].includes(
                          patch.status
                        )
                      }
                      color="primary"
                      onClick={() => {
                        appDispatch(
                          ManualPatchSlice.stop(patch?.id, patch?.version)
                        );
                      }}
                    >
                      STOP
                    </Button>
                  </Col>
                  {numberOfSuccessful !== totalSelected ? (
                    <Col>
                      <Popconfirm
                        getPopupContainer={() => document.body}
                        getTooltipContainer={() => document.body}
                        placement="top"
                        visible={visible}
                        onVisibleChange={handleVisibleChange}
                        title={downloadedAlert?.[1] || alerts[0]?.[0]}
                        onConfirm={shiftData}
                        okText="Ok"
                        disabled={moveButtonDisabled}
                        cancelText="Cancel"
                      >
                        <Button
                          onClick={
                            !downloadedAlert && alerts.length === 0
                              ? shiftData
                              : undefined
                          }
                          disabled={moveButtonDisabled}
                          color="primary"
                          type="primary"
                        >
                          Move on ({shiftAmount}{" "}
                          {patchType === "hours"
                            ? shiftAmount > 1
                              ? "hours"
                              : "hour"
                            : shiftAmount > 1
                            ? "days"
                            : "day"}
                          )
                        </Button>
                      </Popconfirm>
                    </Col>
                  ) : null}
                  {patch.shiftStoppedAt && (
                    <Col>
                      <Button
                        disabled={finishButtonDisabled}
                        color="primary"
                        type="primary"
                        onClick={() => {
                          appDispatch(
                            ManualPatchSlice.remove(patch?.id, patch?.version)
                          );
                        }}
                      >
                        FINISH
                      </Button>
                    </Col>
                  )}
                </>
              ) : null}
            </Row>
          </>
        </>
      </Layout.Content>
    </Layout>
  ) : null;
};

export default ManualPatchDetails;
