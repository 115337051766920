import { ManualPatchPayload } from "interfaces";
import { createRelativeSubscriptiveSlice } from "slices/subscriptive/relative";
import { useSelector } from "react-redux";
import * as r from "ramda";

const {
  select,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  slice,
  selectChildResourceList,
} = createRelativeSubscriptiveSlice({
  parentName: "accounts",
  parentSingleName: "account",
  sliceName: "accountManualPatches",
  name: "manualPatches",
  payloadType: ManualPatchPayload,
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
  idProp: "clientResourceId",
});

const { setLoading } = slice.actions;

export default slice.reducer;

export { unsubscribe, reducer, reconnect, onPublish, subscribe, slice };

export const useAccountManualPatches = (accountId: string | null) => {
  const {
    resourceDictionary: accountManualPatchesById,
    loading: accountManualPatchesLoading,
    subscribed: accountManualPatchesSubscribed,
  } = useSelector(r.partial(select, [accountId])) || {};

  const accountManualPatches = useSelector(
    r.partial(selectChildResourceList, [accountId])
  ) as ManualPatchPayload[] | undefined;

  return {
    accountManualPatches: accountManualPatches as
      | ManualPatchPayload[]
      | undefined,
    accountManualPatchesById: accountManualPatchesById as
      | Record<string, ManualPatchPayload>
      | undefined,
    accountManualPatchesLoading,
    accountManualPatchesSubscribed,
  };
};
