import moment from "moment-timezone";
import { LogEvent, PatchLogEventPayload } from "interfaces";
import { timezones } from "utils/timezone";

export const isRestricted = (
  fixCertifications: boolean,
  patchLogEvent: PatchLogEventPayload
) => {
  const isTooOld = !moment
    .tz(
      patchLogEvent.originData.eventTime.timestamp,
      timezones[patchLogEvent.originData.eventTime.logDate.timeZone.id] ||
        "America/Los_Angeles"
    )
    .isAfter(moment().subtract(9, "days"));

  return (
    !(isTooOld && false) &&
    fixCertifications &&
    isCertification(patchLogEvent.originData)
  );
};

export const isCertification = (logEvent: LogEvent) => {
  return [
    // does not include certifications
    "DR_CERT_1",
    "DR_CERT_2",
    "DR_CERT_3",
    "DR_CERT_4",
    "DR_CERT_5",
    "DR_CERT_6",
    "DR_CERT_7",
    "DR_CERT_8",
    "DR_CERT_9",
  ].includes(logEvent.eventCode.id);
};

export const getLogEvent = (patchLogEvent: PatchLogEventPayload) => {
  const { originData, committedData } = patchLogEvent;
  return (committedData || originData) as unknown as LogEvent;
};
