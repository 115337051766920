import { useMyAccount } from "slices/myAccount";
import { compareTwoStrings } from "string-similarity";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useRef, useState } from "react";
import { useAuthConnectionEffect } from "hooks/useAuthConnectionEffect";
import * as DotsSlice from "slices/dots";
import * as DotSlice from "slices/dots";
import { CreateDotPayload, useDots } from "slices/dots";
import { useDispatch } from "react-redux";
import * as DriversSlices from "slices/drivers";
import { useDrivers } from "slices/drivers";
import { CompanyPayload, DotPayload, DriverPayload } from "interfaces";
import { timezones } from "utils/timezone";
import * as CompaniesSlice from "slices/companies";
import { useCompanies } from "slices/companies";
import moment from "moment-timezone";
import { useForm } from "antd/es/form/Form";
import {
  DeleteOutlined,
  PoweroffOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useAppDispatch } from "store/store";

const DotsPage = () => {
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [origin, setOrigin] = useState<string | null>(null);
  const {
    companiesById,
    companiesLoading,
    companiesSubscribed,
  } = useCompanies();
  const { driversById, driversSubscribed, driversLoading } = useDrivers(
    companyId
  );
  const { dots, dotsSubscribed, dotsLoading } = useDots();
  const { myAccount, myAccountLoading, myAccountSubscribed } = useMyAccount();

  const [form] = useForm<CreateDotPayload>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [timezone] = useState<keyof typeof timezones>("UZ");
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();

  useAuthConnectionEffect(() => {
    dispatch(DotSlice.subscribe());
    return () => {
      dispatch(DotSlice.unsubscribe());
    };
  }, []);

  useAuthConnectionEffect(() => {
    if (companyId && origin) {
      dispatch(DriversSlices.subscribe(companyId, { origin }));
      return () => {
        dispatch(DriversSlices.unsubscribe(companyId, { origin }));
      };
    }
  }, [companyId, origin]);

  useAuthConnectionEffect(() => {
    if (origin) {
      dispatch(CompaniesSlice.subscribe({ origin }));
      return () => {
        dispatch(CompaniesSlice.unsubscribe({ origin }));
      };
    }
  }, [origin]);

  return (
    <Spin
      spinning={
        dotsLoading ||
        !dotsSubscribed ||
        myAccountLoading ||
        !myAccountSubscribed
      }
    >
      <Layout className={"accounts-list-container"}>
        <Layout.Content>
          <Row justify={"space-between"}>
            <Col span={6}>
              <Typography.Title style={{ margin: "16px 0" }} level={3}>
                DOT
              </Typography.Title>
            </Col>
            {["admin", "manager", "shifter_hr", "shifter_day", "shifter_hr_day"].includes(myAccount?.role || "") ? (
              <Col
                span={3}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  icon={<UserAddOutlined />}
                  key="createButton"
                  type="primary"
                  onClick={() => setShowModal(true)}
                >
                  CREATE
                </Button>
                <Modal
                  visible={showModal}
                  title="CREATE DOT"
                  okText="CREATE"
                  cancelText="CANCEL"
                  onCancel={() => {
                    setShowModal(false);
                  }}
                  onOk={() => {
                    form.submit();
                  }}
                  confirmLoading={companiesLoading || !companiesSubscribed}
                >
                  <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    onFinish={async (values) => {
                      const { status, msg } = await appDispatch(
                        DotSlice.create(values)
                      );
                      if (status === "ok") {
                        form.resetFields();
                        setShowModal(false);
                        message.success("Dot has been created");
                      } else {
                        message.error(msg || "Error");
                      }
                    }}
                  >
                    <Form.Item
                      name="from"
                      label="BLOCK DATE"
                      rules={[
                        {
                          required: true,
                          message: "Choose lock date",
                        },
                      ]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      name="origin"
                      label="SYSTEM"
                      className="collection-create-form_last-form-item"
                      rules={[
                        {
                          required: true,
                          message: "Please choose origin",
                        },
                      ]}
                    >
                      <Select
                        placeholder={"SELECT ORIGIN"}
                        onChange={(origin: string) => {
                          form.setFields([
                            { name: "driverId", value: null },
                            { name: "companyId", value: null },
                          ]);
                          setOrigin(origin);
                        }}
                        showSearch
                        getPopupContainer={(node: HTMLElement) =>
                          node.parentNode as HTMLElement
                        }
                        style={{ minWidth: 300 }}
                      >
                        {process.env.REACT_APP_ORIGINS?.split(",").map(
                          (origin) => (
                            <Select.Option key={origin} value={origin}>
                              {origin}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="companyId"
                      label="COMPANY"
                      className="collection-create-form_last-form-item"
                      rules={[
                        {
                          required: true,
                          message: "Please choose company",
                        },
                      ]}
                    >
                      <Select
                        placeholder={"Select company"}
                        filterOption={(str, option) => {
                          const name = option?.children
                            .toLowerCase()
                            .replaceAll(" ", "");
                          const searchStr = str
                            .toLowerCase()
                            .replaceAll(" ", "");
                          return (
                            !str ||
                            name.includes(searchStr) ||
                            compareTwoStrings(name, searchStr) > 0.8
                          );
                        }}
                        onChange={(companyId: string) => {
                          form.setFields([{ name: "driverId", value: null }]);
                          setCompanyId(companyId);
                        }}
                        showSearch
                        getPopupContainer={(node: HTMLElement) =>
                          node.parentNode as HTMLElement
                        }
                        disabled={companiesLoading}
                        loading={companiesLoading}
                        style={{ minWidth: 300 }}
                      >
                        {Object.values(companiesById).map(
                          (company: CompanyPayload) => (
                            <Select.Option
                              key={company._id}
                              value={company._id}
                            >
                              {company.name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="driverId"
                      label="DRIVER"
                      className="collection-create-form_last-form-item"
                      rules={[
                        {
                          required: true,
                          message: "Please choose driver",
                        },
                      ]}
                    >
                      <Select
                        loading={driversLoading}
                        disabled={!driversSubscribed}
                        placeholder={"Select driver"}
                        filterOption={(str, option) => {
                          const name = option?.children.join("").toLowerCase();
                          const searchStr = str.toLowerCase();
                          return (
                            !str ||
                            name.includes(str.toLowerCase()) ||
                            compareTwoStrings(name, searchStr) > 0.8
                          );
                        }}
                        showSearch
                        getPopupContainer={(node: HTMLElement) =>
                          node.parentNode as HTMLElement
                        }
                        style={{ minWidth: 300 }}
                      >
                        {Object.values(driversById || {}).map(
                          (driver: DriverPayload) => (
                            <Select.Option key={driver._id} value={driver._id}>
                              {driver.firstName} {driver.lastName}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="message"
                      label="DESCRIPTION"
                      rules={[
                        {
                          required: true,
                          message: "Description is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Form>
                </Modal>
              </Col>
            ) : null}
          </Row>
          <div className={"content"}>
            <Table
              pagination={{
                pageSizeOptions: ["10", "50", "100", "250", "500", "1000"],
                showSizeChanger: true,
              }}
              dataSource={dots}
            >
              <Table.Column
                title="SYSTEM"
                dataIndex="origin"
                key="origin"
                render={(origin: string) => {
                  return origin ? (
                    <Tooltip title={origin}>{origin}</Tooltip>
                  ) : (
                    "-"
                  );
                }}
              />
              <Table.Column
                title="DRIVER"
                dataIndex="driverName"
                key="driverName"
                render={(driverName: string) => {
                  return driverName ? (
                    <Tooltip title={driverName}>{driverName}</Tooltip>
                  ) : (
                    "-"
                  );
                }}
              />
              <Table.Column
                title="COMPANY"
                dataIndex="companyName"
                key="companyName"
                render={(companyName: string, data) => {
                  return companyName ? (
                    <Tooltip title={companyName}>{companyName}</Tooltip>
                  ) : (
                    "-"
                  );
                }}
              />
              <Table.Column
                title="LOCK DATE"
                dataIndex="from"
                key="from"
                render={(_, dotPayload: DotPayload) => {
                  return `${
                    dotPayload.from
                      ? moment(dotPayload.from).format("DD.MM.YYYY")
                      : "N/A"
                  }`;
                }}
              />

              <Table.Column
                title="STATUS"
                dataIndex="disabled"
                key="from"
                render={(disabled) => {
                  return disabled ? (
                    <Tag color="default">DISABLED</Tag>
                  ) : (
                    <Tag color="processing">ENABLED</Tag>
                  );
                }}
              />

              <Table.Column
                title="CREATED (DATE/TIME)"
                dataIndex="createdAt"
                key="createdAt"
                render={(createdAt: string) => {
                  return createdAt
                    ? moment(createdAt)
                        .tz(timezones[timezone])
                        .format("DD.MM.YYYY HH:mm")
                    : "-";
                }}
              />

              {["admin", "manager"].includes(myAccount?.role || "") ? (
				<>
					<Table.Column
					dataIndex="clientResourceId"
					key="clientResourceId"
					render={(clientResourceId, dot: DotPayload) => {
					const { disabled } = dot;
					if (new Date(dot.from).getDate() <= new Date().getDate() - 9 && disabled == false) {
						appDispatch(
							DotsSlice.disableDot(dot, !disabled)
						);
					}
					return (
						<Space size="small">
							<Tooltip title="Finish patch" placement={"bottom"}>
								<Popconfirm
									title={`${disabled ? "Enable" : "Disable"} this DOT?`}
									onConfirm={async () => {
										const { status, msg } = await appDispatch(
											DotsSlice.disableDot(dot, !disabled)
										);
										if (status === "ok") {
											message.success(
												`Dot has been ${disabled ? "enabled" : "disabled"}`
											);
										} else {
											message.error(msg || "Error");
										}
									}}
									okText="YES"
									cancelText="NO"
								>
									<Button
										shape="circle"
										danger={!disabled}
										icon={<PoweroffOutlined className={"icon-medium"} />} />
								</Popconfirm>
							</Tooltip>

							<Tooltip title="Finish patch" placement={"bottom"}>
								<Popconfirm
									title={"DELETE DOT ?"}
									onConfirm={async () => {
										const { status, msg } = await appDispatch(
											DotsSlice.deleteDot(dot)
										);
										if (status === "ok") {
											message.success("DOT BLOCK - DELETED");
										} else {
											message.error(msg || "Error");
										}
									} }
									okText="YES"
									cancelText="NO"
								>
									<Button
										shape="circle"
										icon={<DeleteOutlined className={"icon-medium"} />} />
								</Popconfirm>
							</Tooltip>
						</Space>
					);
					}}
					/>
				</>
              ) : null}
            </Table>
          </div>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};
export default DotsPage;
