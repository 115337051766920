import { Button, Layout, Menu, message, Modal } from "antd";
import { Link, useLocation } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import AccountForm from "components/AccountForm";
import { useForm } from "antd/lib/form/Form";
import * as MyAccountSlice from "slices/myAccount";
import { useMyAccount } from "slices/myAccount";
import { useAppDispatch } from "store/store";
import { useConnection } from "hooks/useAuthConnectionEffect";
import { UpdateAccountPayload } from "slices/account";

export default function Header() {
  const location = useLocation();
  const [updateMyAccountForm] = useForm<UpdateAccountPayload>();
  const dispatch = useAppDispatch();
  const { myAccount, myAccountLoading, myAccountSubscribed } = useMyAccount();

  const [showModal, setShowModal] = useState<boolean>(false);
  const { loading } = useConnection();

  useEffect(() => {
    if (myAccount && showModal) {
      updateMyAccountForm.resetFields();
    }
  }, [myAccount, showModal]);

  return (
    <Layout.Header
      style={{
        display: "flex",
      }}
    >
      <Link to="/activity">
        <div
          style={{
            fontSize: 18,
            color: "white",
            float: "left",
            fontWeight: 400,
          }}
          className="mx-2"
        >
          FORTEX LOG SUPPORT SYSTEM
        </div>
      </Link>
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={location.pathname.split("/")}
      >
        {["admin", "manager"].includes(myAccount?.role || "") ? (
          <Menu.Item key="accounts">
            <Link to="/accounts">USERS</Link>
          </Menu.Item>
        ) : null}
        <Menu.Item key="activity">
          <Link to="/activity">ACTIVITY</Link>
        </Menu.Item>
        <Menu.Item key="audits">
          <Link to="/audits">AUDIT</Link>
        </Menu.Item>
        <Menu.Item key="dot">
          <Link to="/dot">DOT INSPECTION</Link>
        </Menu.Item>
        <Menu.Item key="subscription-lock">
          <Link to="/subscription-lock">SUBSCRIPTION</Link>
        </Menu.Item>
        <Menu.Item key="driver-requests">
          <Link to="/driver-requests">DRIVER REQUESTS</Link>
        </Menu.Item>
      </Menu>
      <div
        style={{
          marginLeft: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          textTransform: "uppercase",
        }}
      >
        {myAccount?.username}
        <Button
          style={{ marginLeft: "8px" }}
          loading={loading || !myAccountSubscribed}
          onClick={() => setShowModal(true)}
          shape="circle"
          icon={<UserOutlined />}
        />
      </div>

      <div>
        {myAccount && (
          <Modal
            confirmLoading={myAccountLoading}
            visible={showModal}
            title="USER"
            okText="Edit"
            cancelText="Cancel"
            onCancel={() => {
              setShowModal(false);
            }}
            onOk={() => {
              updateMyAccountForm.submit();
            }}
            footer={[
              <Button
                style={{ float: "left" }}
                key="logoutButton"
                onClick={() => {
                  dispatch(MyAccountSlice.logout());
                }}
              >
                LOGOUT
              </Button>,
              <Button
                key="cancelButton"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                CANCEL
              </Button>,
              <Button
                key="Ok"
                type="primary"
                onClick={() => {
                  updateMyAccountForm.submit();
                }}
              >
                EDIT
              </Button>,
            ]}
          >
            <AccountForm
              account={myAccount}
              form={updateMyAccountForm}
              onSubmit={async (updatedAccount) => {
                const { status, msg } = await dispatch(
                  MyAccountSlice.update({
                    ...myAccount,
                    ...updatedAccount,
                  })
                );
                if (status === "ok") {
                  updateMyAccountForm.resetFields();
                  setShowModal(false);
                } else {
                  message.error(msg || "Error");
                }
              }}
            />
          </Modal>
        )}
      </div>
    </Layout.Header>
  );
}
