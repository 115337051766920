import moment from "moment-timezone";
import { timezones } from "utils/timezone";
import { PatchLogEventPayload } from "../interfaces";
import { getOriginData } from "views/manual-patch/details";

const sum2dArray = (arr: number[][]) => {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      sum += arr[i][j];
    }
  }
  return sum;
};

const sumArray = (arr: any[]) => {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += arr[i].value ? arr[i].value : arr[i];
  }
  return sum;
};

const trimString = (word: string) => {
  let temp: string = "";
  for (let i = 0; i < word.length; i++) {
    if (word[i] !== " ") {
      temp += word[i];
    }
  }
  return temp;
};

export const calculateLogCheck = (events: PatchLogEventPayload[]) => {
  let errorLog: any[] = [];
  let drivingLimitCounter: number[][] = [];
  let workLimitCounter: number[][] = [];
  let drivingBreakCounter: number = 0;
  let sleeperResetCounter: any[] = [];
  let workCycleCounter: number[] = [];
  let workCycleIndex: number = 0;
  let cycleCounter: number = 0;
  let criticalIsError = false;
  let shutDownError = false;
  let powerUpError = false;
  let tempError = false;
  let finalLocation: any = "";
  let finalTotalVehicleMiles: any = "";
  let finalTotalEngineHours: any = "";
  for (let j = 0; j < events.length; j++) {
    try {
      finalLocation = "NO DATA";
      finalTotalVehicleMiles = "NO DATA";
      finalTotalEngineHours = "NO DATA";
      if (events[j - 1] !== undefined) {
        if (
          getOriginData(events[j - 1]).eventCode.id === "ENG_UP_NORMAL" ||
          getOriginData(events[j - 1]).eventCode.id === "DS_D"
        ) {
          shutDownError = false;
          powerUpError = false;
        }
      }
      if (drivingLimitCounter.length < 1) {
        for (let i = 0; i < 2; i++) {
          drivingLimitCounter.push([]);
          workLimitCounter.push([]);
        }
      }

      const err: any = {
        id: events[j].logId,
        casual: {
          status: false,
          type: [],
          log: [],
        },
        values: {
          location: null,
          odometer: null,
          engHours: null,
        },
        violation: {
          status: false,
          type: [],
          log: [],
        },
        warning: {
          status: false,
          type: [],
          log: [],
        },
      };

      // ODOMETER AND ENGINE HOURS
      if (!getOriginData(events[j]).eventCode.id.includes("DR_CERT")) {
        if (
          getOriginData(events[j]).totalEngineHours !== undefined ||
          getOriginData(events[j]).totalVehicleMiles !== undefined
        ) {
          if (events[j - 1] === undefined) {
            tempError = false;
          } else {
            if (getOriginData(events[j]).eventCode.id === "DS_D") {
              if (
                getOriginData(events[j - 1]).totalEngineHours >
                  getOriginData(events[j]).totalEngineHours ||
                getOriginData(events[j - 1]).totalVehicleMiles >
                  getOriginData(events[j]).totalVehicleMiles
              ) {
                err.casual.status = true;
                err.casual.type.push({ EHO: true });
                err.casual.log.push("ENGINE HOURS OR ODOMETER ERROR");
              } else {
                if (
                  +(
                    (getOriginData(events[j]).eventTime.timestamp -
                      getOriginData(events[j - 1]).eventTime.timestamp) /
                    3600000
                  ).toFixed(2) -
                    +(
                      getOriginData(events[j]).totalEngineHours -
                      getOriginData(events[j - 1]).totalEngineHours
                    ).toFixed(2) >
                    0.11 &&
                  +(
                    (getOriginData(events[j]).eventTime.timestamp -
                      getOriginData(events[j - 1]).eventTime.timestamp) /
                    3600000
                  ).toFixed(2) -
                    +(
                      getOriginData(events[j]).totalEngineHours -
                      getOriginData(events[j - 1]).totalEngineHours
                    ).toFixed(2) <
                    -0.11
                ) {
                  err.casual.status = true;
                  err.casual.type.push({ EHO: true });
                  err.casual.log.push("ENGINE HOURS OR ODOMETER ERROR");
                }
              }
            } else {
              if (
                getOriginData(events[j - 1]).totalEngineHours >
                  getOriginData(events[j]).totalEngineHours ||
                getOriginData(events[j - 1]).totalVehicleMiles >
                  getOriginData(events[j]).totalVehicleMiles
              ) {
                err.casual.status = true;
                err.casual.type.push({ EHO: true });
                err.casual.log.push("ENGINE HOURS OR ODOMETER ERROR");
              } else {
                if (
                  +(
                    (getOriginData(events[j]).eventTime.timestamp -
                      getOriginData(events[j - 1]).eventTime.timestamp) /
                    3600000
                  ).toFixed(2) -
                    +(
                      getOriginData(events[j]).totalEngineHours -
                      getOriginData(events[j - 1]).totalEngineHours
                    ).toFixed(2) <=
                  -0.11
                ) {
                  err.casual.status = true;
                  err.casual.type.push({ EHO: true });
                  err.casual.log.push("ENGINE HOURS OR ODOMETER ERROR");
                }
              }
            }
          }
        } else {
          err.casual.status = true;
          err.casual.type.push({ EHO: true });
          err.casual.log.push("ENGINE HOURS OR ODOMETER ERROR");
        }
        finalTotalVehicleMiles = getOriginData(events[j]).totalVehicleMiles
          ? getOriginData(events[j]).totalVehicleMiles
          : "NO DATA";
        finalTotalEngineHours = getOriginData(events[j]).totalEngineHours
          ? getOriginData(events[j]).totalEngineHours
          : "NO DATA";
      }

      // LOCATION
      if (
        getOriginData(events[j]).eventCode.id !== "DR_LOGIN" &&
        getOriginData(events[j]).eventCode.id !== "DR_LOGOUT" &&
        !getOriginData(events[j]).eventCode.id.includes("DR_CERT")
      ) {
        if (
          getOriginData(events[j]).driverLocationDescription ||
          (getOriginData(events[j]).location !== undefined &&
            getOriginData(events[j]).location?.calculatedLocation !== undefined)
        ) {
          if (
            getOriginData(events[j]).driverLocationDescription !== undefined
          ) {
            if (
              trimString(getOriginData(events[j]).driverLocationDescription) !==
              ""
            ) {
              finalLocation = getOriginData(events[j])
                .driverLocationDescription;
            }
          }
          if (getOriginData(events[j]).location !== undefined) {
            if (
              getOriginData(events[j]).location?.calculatedLocation !==
              undefined
            ) {
              finalLocation = getOriginData(events[j]).location
                ?.calculatedLocation;
            }
          }
        } else {
          err.casual.status = true;
          err.casual.type.push({ L: true });
          err.casual.log.push("LOCATION ERROR");
        }
      } else {
        if (getOriginData(events[j]).driverLocationDescription !== undefined) {
          if (
            trimString(getOriginData(events[j]).driverLocationDescription) !==
            ""
          ) {
            finalLocation = getOriginData(events[j]).driverLocationDescription;
          }
        }
        if (getOriginData(events[j]).location !== undefined) {
          if (
            getOriginData(events[j]).location?.calculatedLocation !== undefined
          ) {
            finalLocation = getOriginData(events[j]).location
              ?.calculatedLocation;
          }
        }
      }

      // INTERMEDIATE NOT AFTER DRIVING
      if (
        getOriginData(events[j]).eventCode.id === "LOG_NORMAL_PRECISION" &&
        events[j - 1] !== undefined
      ) {
        if (
          getOriginData(events[j - 1]).eventCode.id === "DS_D" ||
          getOriginData(events[j - 1]).eventCode.id === "LOG_NORMAL_PRECISION"
        ) {
          if (
            +(
              getOriginData(events[j]).eventTime.timestamp -
                getOriginData(events[j - 1]).eventTime.timestamp >
              3601000
            ) ||
            +(
              getOriginData(events[j]).eventTime.timestamp -
              getOriginData(events[j - 1]).eventTime.timestamp
            ) < 3599000
          ) {
            err.casual.status = true;
            err.casual.type.push({ MI: true });
            err.casual.log.push("MISSING INTERMEDIATE ERROR");
          }
        } else {
          err.casual.status = true;
          err.casual.type.push({ MI: true });
          err.casual.log.push("INCORRECT STATUS BEFORE INTERMEDIATE ERROR");
        }
      }

      // TWO IDENTICAL ID SIDE BY SIDE
      if (
        !getOriginData(events[j]).eventCode.id.includes("DR_CERT") &&
        getOriginData(events[j]).eventCode.id !== "LOG_NORMAL_PRECISION"
      ) {
        if (events[j - 1] !== undefined) {
          if (
            getOriginData(events[j - 1]).eventCode.id ===
            getOriginData(events[j]).eventCode.id
          ) {
            err.casual.status = true;
            err.casual.type.push({ IS: true });
            err.casual.log.push("TWO IDENTICAL STATUSES ERROR");
          }
        }
      }

      // DRIVING ORIGIN -> DRIVER
      if (getOriginData(events[j]).eventCode.id === "DS_D") {
        if (getOriginData(events[j]).recordOrigin !== undefined) {
          if (getOriginData(events[j]).recordOrigin.id === "DRIVER") {
            err.warning.status = true;
            err.warning.type.push({ DO: true });
            err.warning.log.push(" DRIVING ORIGIN WARNING");
          }
        }
      }

      // AFTER DRIVING/INTERMEDIATE -> LOG OUT/IN OR SHUT DOWN/ POWER UP
      if (events[j - 1] !== undefined) {
        if (
          getOriginData(events[j]).eventCode.id.includes("DR_CERT") ||
          getOriginData(events[j]).eventCode.id === "DR_LOGOUT" ||
          getOriginData(events[j]).eventCode.id === "ENG_UP_NORMAL" ||
          getOriginData(events[j]).eventCode.id === "DS_D"
        ) {
          if (
            getOriginData(events[j - 1]).eventCode.id === "DS_D" ||
            getOriginData(events[j - 1]).eventCode.id === "LOG_NORMAL_PRECISION"
          ) {
            err.casual.status = true;
            err.casual.type.push({ ISPE: true });
            err.casual.log.push("INCORRECT STATUS PLACEMENT ERROR");
          }
        }
      }

      // SHUT DOWN ERRORS
      if (getOriginData(events[j]).eventCode.id === "ENG_DOWN_NORMAL") {
        const arr: any[] = [];
        for (let i = j; i < events.length; i++) {
          if (events[i] !== undefined) {
            if (!getOriginData(events[i]).eventCode.id.includes("DR_CERT")) {
              arr.push(events[i]);
            }
            if (getOriginData(events[i]).eventCode.id === "ENG_UP_NORMAL") {
              break;
            }
            if (getOriginData(events[i]).eventCode.id === "DS_D") {
              arr.push(events[i]);
              break;
            }
          } else {
            break;
          }
        }

        for (let i = 0; i < arr.length; i++) {
          if (arr[i + 1] !== undefined && arr.length !== 0) {
            if (getOriginData(arr[arr.length - 1]).eventCode.id === "DS_D") {
              err.casual.status = true;
              err.casual.type.push({ NPU: true });
              err.casual.log.push("NO POWER UP ERROR");
              break;
            } else {
              if (
                getOriginData(arr[i]).totalEngineHours !==
                getOriginData(arr[i + 1]).totalEngineHours
              ) {
                err.casual.status = true;
                err.casual.type.push({ EHCASD: true });
                err.casual.log.push(
                  "ENGINE HOURS HAVE CHANGED AFTER SHUT DOWN ERROR"
                );
                break;
              }
            }
          } else {
            break;
          }
        }
      }

      //   POWER UP ERRORS
      if (getOriginData(events[j]).eventCode.id === "ENG_UP_NORMAL") {
        const arr: any[] = [];
        for (let i = j; i < events.length; i--) {
          if (events[i] !== undefined) {
            if (!getOriginData(events[i]).eventCode.id.includes("DR_CERT")) {
              arr.unshift(events[i]);
            }
            if (getOriginData(events[i]).eventCode.id === "ENG_DOWN_NORMAL") {
              arr.unshift(events[i]);
              break;
            }
            if (getOriginData(events[i]).eventCode.id === "DS_D") {
              arr.unshift(events[i]);
              break;
            }
          } else {
            break;
          }
        }

        for (let i = 0; i < arr.length; i++) {
          if (arr[i + 1] !== undefined && arr[i] !== undefined) {
            if (getOriginData(arr[i]).eventCode.id === "DS_D") {
              err.casual.status = true;
              err.casual.type.push({ NSD: true });
              err.casual.log.push("NO SHUT DOWN ERROR");
              break;
            } else {
              if (
                getOriginData(arr[i]).totalEngineHours !==
                getOriginData(arr[i + 1]).totalEngineHours
              ) {
                err.casual.status = true;
                err.casual.type.push({ EHCASD: true });
                err.casual.log.push(
                  "ENGINE HOURS HAVE CHANGED AFTER SHUT DOWN ERROR"
                );
                break;
              }
            }
          } else {
            break;
          }
        }
      }

      //   EXCESSIVE LOG IN
      if (getOriginData(events[j]).eventCode.id === "DR_LOGIN") {
        if (events[j - 1] !== undefined) {
          if (getOriginData(events[j - 1]).eventCode.id !== "DR_LOGOUT") {
            err.casual.status = true;
            err.casual.type.push({ ELI: true });
            err.casual.log.push("EXCESSIVE LOG IN ERROR");
          }
        }
      }

      // EXCESSIVE LOG OUT
      if (getOriginData(events[j]).eventCode.id === "DR_LOGOUT") {
        if (events[j + 1] !== undefined) {
          if (getOriginData(events[j + 1]).eventCode.id !== "DR_LOGIN") {
            err.casual.status = true;
            err.casual.type.push({ ELO: true });
            err.casual.log.push("EXCESSIVE LOG OUT ERROR");
          }
        }
      }

      // MISSING INTERMEDIATE
      if (events[j + 1] !== undefined) {
        if (
          getOriginData(events[j]).eventCode.id === "DS_D" ||
          getOriginData(events[j]).eventCode.id === "LOG_NORMAL_PRECISION"
        ) {
          if (
            +(
              getOriginData(events[j + 1]).eventTime.timestamp -
              getOriginData(events[j]).eventTime.timestamp
            ) > 3601000
          ) {
            err.casual.status = true;
            err.casual.type.push({ MI: true });
            err.casual.log.push("MISSING INTERMEDIATE ERROR");
          }
        }
      }

      // LOCATION DID NOT CHANGE AFTER DRIVING ERROR
      if (events[j + 1] !== undefined) {
        if (getOriginData(events[j]).eventCode.id === "DS_D") {
          if (
            +(
              getOriginData(events[j + 1]).eventTime.timestamp -
              getOriginData(events[j]).eventTime.timestamp
            ) > 1200000
          ) {
            if (getOriginData(events[j]).location !== undefined) {
              if (
                getOriginData(events[j]).location?.calculatedLocation !==
                undefined
              ) {
                if (
                  getOriginData(events[j + 1]).driverLocationDescription !==
                  undefined
                ) {
                  if (
                    getOriginData(events[j + 1]).driverLocationDescription ===
                    getOriginData(events[j]).location?.calculatedLocation
                  ) {
                    err.warning.status = true;
                    err.warning.type.push({ LDCAD: true });
                    err.warning.log.push(
                      " LOCATION DID NOT CHANGE AFTER MORE THAN 20 MINUTES OF DRIVING"
                    );
                  }
                } else if (
                  getOriginData(events[j + 1]).location !== undefined
                ) {
                  if (
                    getOriginData(events[j + 1]).location
                      ?.calculatedLocation !== undefined
                  ) {
                    if (
                      getOriginData(events[j + 1]).location
                        ?.calculatedLocation ===
                      getOriginData(events[j]).location?.calculatedLocation
                    ) {
                      err.warning.status = true;
                      err.warning.type.push({ LDCAD: true });
                      err.warning.log.push(
                        " LOCATION DID NOT CHANGE AFTER MORE THAN 20 MINUTES OF DRIVING"
                      );
                    }
                  }
                }
              }
            }
            if (
              getOriginData(events[j]).driverLocationDescription !== undefined
            ) {
              if (
                getOriginData(events[j + 1]).driverLocationDescription !==
                undefined
              ) {
                if (
                  getOriginData(events[j + 1]).driverLocationDescription ===
                  getOriginData(events[j]).driverLocationDescription
                ) {
                  err.warning.status = true;
                  err.warning.type.push({ LDCATMD: true });
                  err.warning.log.push(
                    " LOCATION DID NOT CHANGE AFTER MORE THAN 20 MINUTES OF DRIVING"
                  );
                }
              } else if (getOriginData(events[j + 1]).location !== undefined) {
                if (
                  getOriginData(events[j + 1]).location?.calculatedLocation !==
                  undefined
                ) {
                  if (
                    getOriginData(events[j + 1]).location
                      ?.calculatedLocation ===
                    getOriginData(events[j]).driverLocationDescription
                  ) {
                    err.warning.status = true;
                    err.warning.type.push({ LDCATMD: true });
                    err.warning.log.push(
                      " LOCATION DID NOT CHANGE AFTER MORE THAN 20 MINUTES OF DRIVING"
                    );
                  }
                }
              }
            }
          }
        }
      }

      if (events[j - 1] !== undefined) {
        if (
          getOriginData(events[j]).eventCode.id !== "DR_LOGIN" &&
          getOriginData(events[j]).eventCode.id !== "DR_LOGOUT" &&
          !getOriginData(events[j]).eventCode.id.includes("DR_CERT")
        ) {
          if (
            getOriginData(events[j]).driverLocationDescription !== undefined
          ) {
            for (let i = j - 1; i < events.length; i--) {
              if (events[i] !== undefined) {
                if (
                  getOriginData(events[i]).eventCode.id !== "DR_LOGIN" &&
                  getOriginData(events[i]).eventCode.id !== "DR_LOGOUT" &&
                  !getOriginData(events[i]).eventCode.id.includes("DR_CERT")
                ) {
                  if (
                    getOriginData(events[i]).driverLocationDescription !==
                    undefined
                  ) {
                    if (
                      getOriginData(events[i]).eventCode.id === "DS_D" ||
                      getOriginData(events[i]).eventCode.id ===
                        "LOG_NORMAL_PRECISION"
                    ) {
                      break;
                    } else {
                      if (
                        getOriginData(events[j]).driverLocationDescription !==
                        getOriginData(events[i]).driverLocationDescription
                      ) {
                        err.casual.status = true;
                        err.casual.type.push({ LC: true });
                        err.casual.log.push("LOCATION CHANGED ERROR");
                        break;
                      }
                    }
                  }
                  if (getOriginData(events[i]).location !== undefined) {
                    if (
                      getOriginData(events[i]).location?.calculatedLocation !==
                      undefined
                    ) {
                      if (
                        getOriginData(events[i]).eventCode.id === "DS_D" ||
                        getOriginData(events[i]).eventCode.id ===
                          "LOG_NORMAL_PRECISION"
                      ) {
                        break;
                      } else {
                        if (
                          getOriginData(events[j]).driverLocationDescription !==
                          getOriginData(events[i]).location?.calculatedLocation
                        ) {
                          err.casual.status = true;
                          err.casual.type.push({ LC: true });
                          err.casual.log.push("LOCATION CHANGED ERROR");
                          break;
                        }
                      }
                    }
                  }
                }
              } else {
                break;
              }
            }

            if (getOriginData(events[j]).location !== undefined) {
              if (
                getOriginData(events[j]).location?.calculatedLocation !==
                undefined
              ) {
                for (let i = j - 1; i < events.length; i--) {
                  if (events[i] !== undefined) {
                    if (
                      getOriginData(events[i]).eventCode.id !== "DR_LOGIN" &&
                      getOriginData(events[i]).eventCode.id !== "DR_LOGOUT" &&
                      !getOriginData(events[i]).eventCode.id.includes("DR_CERT")
                    ) {
                      if (
                        getOriginData(events[i]).driverLocationDescription !==
                        undefined
                      ) {
                        if (
                          getOriginData(events[i]).eventCode.id === "DS_D" ||
                          getOriginData(events[i]).eventCode.id ===
                            "LOG_NORMAL_PRECISION"
                        ) {
                          break;
                        } else {
                          if (
                            getOriginData(events[j]).location
                              ?.calculatedLocation !==
                            getOriginData(events[i]).driverLocationDescription
                          ) {
                            err.casual.status = true;
                            err.casual.type.push({ LC: true });
                            err.casual.log.push("LOCATION CHANGED ERROR");
                            break;
                          }
                        }
                      }
                      if (getOriginData(events[i]).location !== undefined) {
                        if (
                          getOriginData(events[i]).location
                            ?.calculatedLocation !== undefined
                        ) {
                          if (
                            getOriginData(events[i]).eventCode.id === "DS_D" ||
                            getOriginData(events[i]).eventCode.id ===
                              "LOG_NORMAL_PRECISION"
                          ) {
                            break;
                          } else {
                            if (
                              getOriginData(events[j]).location
                                ?.calculatedLocation !==
                              getOriginData(events[i]).location
                                ?.calculatedLocation
                            ) {
                              err.casual.status = true;
                              err.casual.type.push({ LC: true });
                              err.casual.log.push("LOCATION CHANGED ERROR");
                              break;
                            }
                          }
                        }
                      }
                    }
                  } else {
                    break;
                  }
                }
              }
            }
          }
        }
      }

      // SPEEDING
      if (events[j + 1] !== undefined) {
        if (
          getOriginData(events[j + 1]).totalVehicleMiles -
            getOriginData(events[j]).totalVehicleMiles >
          70 *
            ((getOriginData(events[j + 1]).eventTime.timestamp -
              getOriginData(events[j]).eventTime.timestamp) /
              3600000)
        ) {
          err.warning.status = true;
          err.warning.type.push({ SP: true });
          err.warning.log.push(" POSSIBILITY OF SPEEDING");
        }
      }

      if (events[j] !== undefined) {
        if (
          getOriginData(events[j]).eventCode.id !== "DS_D" &&
          getOriginData(events[j]).eventCode.id !== "LOG_NORMAL_PRECISION" &&
          !getOriginData(events[j]).eventCode.id.includes("DR_CERT")
        ) {
          if (events[j + 1] !== undefined) {
            if (
              !isNaN(getOriginData(events[j]).totalVehicleMiles) &&
              !isNaN(getOriginData(events[j + 1]).totalVehicleMiles)
            ) {
              if (
                getOriginData(events[j]).totalVehicleMiles !==
                getOriginData(events[j + 1]).totalVehicleMiles
              ) {
                err.casual.status = true;
                err.casual.type.push({ OC: true });
                err.casual.log.push("ODOMETER ERROR");
              }
            }
          }
        }
      }

      if (getOriginData(events[j]).eventCode.id === "DS_D") {
        for (let i = j + 1; i < events.length; i++) {
          if (events[i] !== undefined) {
            if (
              getOriginData(events[i]).eventCode.id === "DS_OFF" ||
              getOriginData(events[i]).eventCode.id === "DS_ON" ||
              getOriginData(events[i]).eventCode.id === "DS_SB" ||
              getOriginData(events[i]).eventCode.id === "DS_D"
            ) {
              if (sumArray(sleeperResetCounter) > 2) {
                drivingLimitCounter[1].push(
                  (getOriginData(events[i]).eventTime.timestamp -
                    getOriginData(events[j]).eventTime.timestamp) /
                    3600000
                );
                workLimitCounter[1].push(
                  (getOriginData(events[i]).eventTime.timestamp -
                    getOriginData(events[j]).eventTime.timestamp) /
                    3600000
                );
              } else {
                drivingLimitCounter[0].push(
                  (getOriginData(events[i]).eventTime.timestamp -
                    getOriginData(events[j]).eventTime.timestamp) /
                    3600000
                );
                workLimitCounter[0].push(
                  (getOriginData(events[i]).eventTime.timestamp -
                    getOriginData(events[j]).eventTime.timestamp) /
                    3600000
                );
              }
              if (sum2dArray(drivingLimitCounter) >= 11) {
                err.violation.status = true;
                if (
                  !err.violation.log
                    .join(", ")
                    .includes(" DRIVING VIOLATION AT")
                ) {
                  err.violation.log.push(
                    ` DRIVING VIOLATION AT ${moment
                      .tz(
                        getOriginData(events[i]).eventTime.timestamp -
                          (sum2dArray(drivingLimitCounter) - 11) * 3600000,
                        timezones[
                          getOriginData(events[j]).eventTime.logDate.timeZone.id
                        ] || "America/Los_Angeles"
                      )
                      .format("MMM DD, hh:mm:ss a")}`
                  );
                }
                drivingLimitCounter[0].splice(0, drivingLimitCounter[0].length);
                drivingLimitCounter[1].splice(0, drivingLimitCounter[1].length);
              } else {
                err.violation.status = true;
              }

              if (sum2dArray(workLimitCounter) >= 14) {
                err.violation.status = true;
                if (
                  !err.violation.log
                    .join(", ")
                    .includes("14 HOURS VIOLATION AT")
                ) {
                  err.violation.log.push(
                    ` 14 HOURS VIOLATION AT ${moment
                      .tz(
                        getOriginData(events[i]).eventTime.timestamp -
                          (sum2dArray(workLimitCounter) - 14) * 3600000,
                        timezones[
                          getOriginData(events[j]).eventTime.logDate.timeZone.id
                        ] || "America/Los_Angeles"
                      )
                      .format("MMM DD, hh:mm:ss a")}`
                  );
                }
                workLimitCounter[0].splice(0, workLimitCounter[0].length);
                workLimitCounter[1].splice(0, workLimitCounter[1].length);
              } else {
                err.violation.status = false;
              }
              break;
            }
          } else {
            break;
          }
        }
      }

      if (
        getOriginData(events[j]).eventCode.id === "DS_SB" ||
        getOriginData(events[j]).eventCode.id === "DS_OFF"
      ) {
        for (let i = j; i < events.length; i++) {
          if (events[i] !== undefined) {
            if (
              getOriginData(events[i]).eventCode.id === "DS_ON" ||
              getOriginData(events[i]).eventCode.id === "DS_D"
            ) {
              if (
                (getOriginData(events[i]).eventTime.timestamp -
                  getOriginData(events[j]).eventTime.timestamp) /
                  3600000 >=
                2
              ) {
                sleeperResetCounter.push({
                  value:
                    (getOriginData(events[i]).eventTime.timestamp -
                      getOriginData(events[j]).eventTime.timestamp) /
                    3600000,
                  restType: getOriginData(events[j]).eventCode.id,
                });
              }
              if (sumArray(sleeperResetCounter) >= 10) {
                for (let i = 0; i < sleeperResetCounter.length; i++) {
                  if (sleeperResetCounter[i]?.value >= 10) {
                    drivingLimitCounter[0].splice(
                      0,
                      drivingLimitCounter[0].length
                    );
                    drivingLimitCounter[1].splice(
                      0,
                      drivingLimitCounter[1].length
                    );
                    sleeperResetCounter.splice(0, sleeperResetCounter.length);
                    workLimitCounter[0].splice(0, workLimitCounter[0].length);
                    workLimitCounter[1].splice(0, workLimitCounter[1].length);
                  }
                  if (sleeperResetCounter[i]?.value >= 7) {
                    if (sleeperResetCounter[i]?.restType === "DS_SB") {
                      drivingLimitCounter[0].splice(
                        0,
                        drivingLimitCounter.length
                      );
                      sleeperResetCounter.splice(0, sleeperResetCounter.length);
                    }
                    if (sleeperResetCounter[i]?.restType === "DS_OFF") {
                      sleeperResetCounter.splice(0, sleeperResetCounter.length);
                      sleeperResetCounter.push({
                        value: 3,
                        restType: "DS_SB",
                      });
                    }
                    for (let i = j + 1; i < events.length; i++) {
                      if (events[i] !== undefined) {
                        if (getOriginData(events[i]).eventCode.id === "DS_ON") {
                          for (let a = j + 1; a < events.length; a++) {
                            if (events[a] !== undefined) {
                              if (
                                getOriginData(events[a]).eventCode.id ===
                                  "DS_OFF" ||
                                getOriginData(events[a]).eventCode.id ===
                                  "DS_SB" ||
                                getOriginData(events[a]).eventCode.id === "DS_D"
                              ) {
                                if (
                                  (getOriginData(events[a]).eventTime
                                    .timestamp -
                                    getOriginData(events[i]).eventTime
                                      .timestamp) /
                                    60000 <
                                  15
                                ) {
                                  err.casual.status = true;
                                  err.casual.type.push({ PTI: true });
                                  err.casual.log.push("PTI DURATION ERROR");
                                  break;
                                }
                              }
                            } else {
                              break;
                            }
                          }
                        } else if (
                          getOriginData(events[i]).eventCode.id === "DS_D"
                        ) {
                          err.casual.status = true;
                          err.casual.type.push({ PTI: true });
                          err.casual.log.push("NO PTI");
                        }
                      } else {
                        break;
                      }
                    }
                  }
                }
              }
              if (sleeperResetCounter.length > 1) {
                if (workLimitCounter !== undefined) {
                  workLimitCounter[0].push(sleeperResetCounter[0].value);
                  if (workLimitCounter[1].length >= 1) {
                    for (let i = 0; i < workLimitCounter[1].length; i++) {
                      workLimitCounter[0].push(workLimitCounter[1][i]);
                    }
                  }
                  workLimitCounter[1].splice(0, workLimitCounter[1].length);
                }
                sleeperResetCounter.splice(0, sleeperResetCounter.length - 1);
              }
              break;
            }
          } else {
            break;
          }
        }
      }

      if (
        getOriginData(events[j]).eventCode.id === "DS_OFF" ||
        getOriginData(events[j]).eventCode.id === "DS_ON" ||
        getOriginData(events[j]).eventCode.id === "DS_SB"
      ) {
        for (let i = j + 1; i < events.length; i++) {
          if (events[i] !== undefined) {
            if (
              getOriginData(events[i]).eventCode.id === "DS_OFF" ||
              getOriginData(events[i]).eventCode.id === "DS_ON" ||
              getOriginData(events[i]).eventCode.id === "DS_SB" ||
              getOriginData(events[i]).eventCode.id === "DS_D"
            ) {
              if (
                getOriginData(events[j]).eventCode.id === "DS_OFF" ||
                getOriginData(events[j]).eventCode.id === "DS_SB"
              ) {
                if (
                  (getOriginData(events[i]).eventTime.timestamp -
                    getOriginData(events[j]).eventTime.timestamp) /
                    3600000 <
                  2
                ) {
                  if (sumArray(sleeperResetCounter) > 2) {
                    workLimitCounter[1].push(
                      (getOriginData(events[i]).eventTime.timestamp -
                        getOriginData(events[j]).eventTime.timestamp) /
                        3600000
                    );
                  } else {
                    workLimitCounter[0].push(
                      (getOriginData(events[i]).eventTime.timestamp -
                        getOriginData(events[j]).eventTime.timestamp) /
                        3600000
                    );
                  }
                  if (sum2dArray(workLimitCounter) >= 14) {
                    err.violation.status = true;
                    if (
                      !err.violation.log
                        .join(", ")
                        .includes("14 HOURS VIOLATION AT")
                    ) {
                      err.violation.log.push(
                        ` 14 HOURS VIOLATION AT ${moment
                          .tz(
                            getOriginData(events[i]).eventTime.timestamp -
                              (sum2dArray(workLimitCounter) - 14) * 3600000,
                            timezones[
                              getOriginData(events[j]).eventTime.logDate
                                .timeZone.id
                            ] || "America/Los_Angeles"
                          )
                          .format("MMM DD, hh:mm:ss a")}`
                      );
                    }
                    workLimitCounter[0].splice(0, workLimitCounter[0].length);
                    workLimitCounter[1].splice(0, workLimitCounter[1].length);
                  } else {
                    err.violation.status = false;
                  }
                  break;
                } else {
                  break;
                }
              } else {
                if (sumArray(sleeperResetCounter) > 2) {
                  workLimitCounter[1].push(
                    (getOriginData(events[i]).eventTime.timestamp -
                      getOriginData(events[j]).eventTime.timestamp) /
                      3600000
                  );
                } else {
                  workLimitCounter[0].push(
                    +(
                      getOriginData(events[i]).eventTime.timestamp -
                      getOriginData(events[j]).eventTime.timestamp
                    ) / 3600000
                  );
                }
                if (sum2dArray(workLimitCounter) >= 14) {
                  err.violation.status = true;
                  if (
                    !err.violation.log
                      .join(", ")
                      .includes("14 HOURS VIOLATION AT")
                  ) {
                    err.violation.log.push(
                      ` 14 HOURS VIOLATION AT ${moment
                        .tz(
                          getOriginData(events[i]).eventTime.timestamp -
                            (sum2dArray(workLimitCounter) - 14) * 3600000,
                          timezones[
                            getOriginData(events[j]).eventTime.logDate.timeZone
                              .id
                          ] || "America/Los_Angeles"
                        )
                        .format("MMM DD, hh:mm:ss a")}`
                    );
                  }
                  workLimitCounter[0].splice(0, workLimitCounter[0].length);
                  workLimitCounter[1].splice(0, workLimitCounter[1].length);
                } else {
                  err.violation.status = false;
                }
                break;
              }
            }
          } else {
            break;
          }
        }
      }

      if (getOriginData(events[j]).eventCode.id === "DS_D") {
        for (let i = j + 1; i < events.length; i++) {
          if (events[i] !== undefined) {
            if (
              getOriginData(events[i]).eventCode.id === "DS_OFF" ||
              getOriginData(events[i]).eventCode.id === "DS_ON" ||
              getOriginData(events[i]).eventCode.id === "DS_SB" ||
              getOriginData(events[i]).eventCode.id === "DS_D"
            ) {
              drivingBreakCounter +=
                (getOriginData(events[i]).eventTime.timestamp -
                  getOriginData(events[j]).eventTime.timestamp) /
                3600000;
              if (drivingBreakCounter >= 8) {
                err.violation.status = true;
                if (
                  !err.violation.log.join(", ").includes("BREAK VIOLATION AT")
                ) {
                  err.violation.log.push(
                    ` BREAK VIOLATION AT ${moment
                      .tz(
                        getOriginData(events[i]).eventTime.timestamp -
                          (drivingBreakCounter - 8) * 3600000,
                        timezones[
                          getOriginData(events[j]).eventTime.logDate.timeZone.id
                        ] || "America/Los_Angeles"
                      )
                      .format("MMM DD, hh:mm:ss a")}`
                  );
                }
              } else {
                break;
              }
            }
          } else {
            break;
          }
        }
      }

      if (
        getOriginData(events[j]).eventCode.id === "DS_ON" ||
        getOriginData(events[j]).eventCode.id === "DS_OFF" ||
        getOriginData(events[j]).eventCode.id === "DS_SB"
      ) {
        for (let i = j + 1; i < events.length; i++) {
          if (events[i] !== undefined) {
            if (getOriginData(events[i]).eventCode.id === "DS_D") {
              if (
                +(
                  getOriginData(events[i]).eventTime.timestamp -
                  getOriginData(events[j]).eventTime.timestamp
                ) /
                  3600000 >=
                0.5
              ) {
                drivingBreakCounter = 0;
                break;
              } else {
                break;
              }
            }
          } else {
            break;
          }
        }
      }

      if (events[j] !== undefined) {
        if (
          getOriginData(events[j]).eventCode.id === "DS_ON" ||
          getOriginData(events[j]).eventCode.id === "DS_D"
        ) {
          for (let i = j + 1; i < events.length; i++) {
            if (events[i] !== undefined) {
              if (
                getOriginData(events[i]).eventCode.id === "DS_OFF" ||
                getOriginData(events[i]).eventCode.id === "DS_SB" ||
                getOriginData(events[i]).eventCode.id === "DS_ON" ||
                getOriginData(events[i]).eventCode.id === "DS_D"
              ) {
                cycleCounter +=
                  (getOriginData(events[i]).eventTime.timestamp -
                    getOriginData(events[j]).eventTime.timestamp) /
                  3600000;
                if (cycleCounter >= 70) {
                  err.violation.status = true;
                  err.violation.type.push({ CYC: true });
                  err.violation.log.push(
                    ` CYCLE VIOLATION AT ${moment 
                      .tz(
                        getOriginData(events[i]).eventTime.timestamp -
                          (cycleCounter - 70) * 3600000,
                        timezones[
                          getOriginData(events[j]).eventTime.logDate.timeZone.id
                        ] || "America/Los_Angeles"
                      )
                      .format("MMM DD, hh:mm:ss a")}`
                  );
                } else {
                  break;
                }
              }
            } else {
              break;
            }
          }
        }
        if (
          getOriginData(events[j]).eventCode.id === "DS_OFF" ||
          getOriginData(events[j]).eventCode.id === "DS_SB"
        ) {
          for (let i = j + 1; i < events.length; i++) {
            if (events[i] !== undefined) {
              if (
                getOriginData(events[i]).eventCode.id === "DS_ON" ||
                getOriginData(events[i]).eventCode.id === "DS_D"
              ) {
                if (
                  (getOriginData(events[i]).eventTime.timestamp -
                    getOriginData(events[j]).eventTime.timestamp) /
                    3600000 >=
                  34
                ) {
                  cycleCounter = 0;
                }
              }
            } else {
              break;
            }
          }
        }
      }
      err.values.location = finalLocation;
      err.values.engHours = finalTotalEngineHours;
      err.values.odometer = finalTotalVehicleMiles;
      errorLog.push(err);
    } catch (err) {
      console.error(err);
      errorLog.push({
        id: events[j].logId,
        casual: {
          status: true,
          type: ["UNKNOWN"],
          log: [
            `FAILED TO PROCESS EVENT AT ${moment
              .tz(
                getOriginData(events[j]).eventTime.timestamp,
                timezones[
                  getOriginData(events[j]).eventTime.logDate.timeZone.id
                ] || "America/Los_Angeles"
              )
              .format("MMM DD, hh:mm:ss a")}`,
          ],
        },
        values: {
          location: null,
          odometer: null,
          engHours: null,
        },
        violation: {
          status: false,
          type: ["UNKNOWN"],
          log: [
            `FAILED TO PROCESS EVENT AT ${moment
              .tz(
                getOriginData(events[j]).eventTime.timestamp,
                timezones[
                  getOriginData(events[j]).eventTime.logDate.timeZone.id
                ] || "America/Los_Angeles"
              )
              .format("MMM DD, hh:mm:ss a")}`,
          ],
        },
        warning: {
          status: false,
          type: [],
          log: [],
        },
      });
    }
  }

  return errorLog;
};
