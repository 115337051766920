import {
  createSlice,
  Draft,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";

import * as R from "ramda";
import { Dispatch } from "redux";
import { Socket } from "socket.io-client";
import { emitAsyncOrdered } from "utils/socket";
import {
  ObjectPropertiesOfType,
  SubscriptiveResourceList,
} from "slices/subscriptive";
import { BaseModel } from "interfaces";
import { ClassConstructor } from "class-transformer";

export interface RelativeSubscriptiveResourceList<T extends BaseModel> {
  [key: string]: SubscriptiveResourceList<T>;
}

export type RootState = Record<
  string,
  Record<string, SubscriptiveResourceList<BaseModel>>
>;
export type RelativeRootState = RootState;
export const createRelativeSubscriptiveSlice = ({
  name,
  parentName,
  sliceName,
  payloadType,
  idProp,
  parentSingleName,
  deletedFilterFn,
  reducers,
}: {
  deletedFilterFn: (resource: BaseModel) => boolean;
  name: string;
  sliceName?: string;
  parentName: string;
  payloadType: ClassConstructor<BaseModel>;
  parentSingleName: string;
  idProp: Extract<ObjectPropertiesOfType<BaseModel, string>, string>;
  reducers: ValidateSliceCaseReducers<
    RelativeSubscriptiveResourceList<BaseModel>,
    SliceCaseReducers<RelativeSubscriptiveResourceList<BaseModel>>
  >;
}) => {
  const selectChildResourceList = (
    parentId: string | null,
    state: RootState
  ) => {
    parentId &&
      R.sortWith(
        [R.descend(R.prop("createdAt"))],
        R.values(state[sliceName || name][parentId]?.resourceDictionary)
      );

    return parentId
      ? R.sortWith(
          [R.descend(R.prop("createdAt"))],
          R.values(state[sliceName || name][parentId]?.resourceDictionary)
        )
      : null;
  };

  const initialState: SubscriptiveResourceList<BaseModel> = {
    resourceDictionary: {},
    subscribed: false,
    lastUpdatedAt: null,
    loading: false,
  };

  const slice = createSlice({
    name: sliceName || name,
    initialState: {},
    reducers: {
      setLoadingResources: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        {
          payload: { parentId, ids, state: loadState },
        }: PayloadAction<{ parentId: string; ids: string[]; state: boolean }>
      ) => {
        const dictionary = state[parentId].resourceDictionary;
        for (const id of ids) {
          const resource = dictionary[id];
          if (resource) {
            resource.loading = loadState;
          }
        }
      },
      onPublish: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        {
          payload: { data: resources, parentId },
        }: PayloadAction<{ parentId: string; data: BaseModel[] }>
      ) => {
        console.log(`on ${parentSingleName}/${name} publish`, resources);
        if (!state[parentId]) {
          state[parentId] = initialState;
        }
        const deletedResourceIds: string[] = R.pipe<
          BaseModel[],
          BaseModel[],
          string[]
        >(
          R.filter(deletedFilterFn),
          R.map(R.prop<any, any>(idProp))
        )(resources);

        const updatedResources: BaseModel[] = R.filter(
          R.complement(deletedFilterFn),
          resources
        );

        Object.assign(
          state[parentId].resourceDictionary,
          R.indexBy(R.prop(idProp), updatedResources)
        );

        for (const resourceId of deletedResourceIds) {
          delete state[parentId].resourceDictionary[resourceId];
        }

        const lastUpdatedPatch = R.pipe<BaseModel[], BaseModel[], BaseModel>(
          R.sortBy(R.prop("updatedAt")),
          R.last
        )(Object.values(state[parentId].resourceDictionary));

        state[parentId].lastUpdatedAt = lastUpdatedPatch?.updatedAt;
        console.timeEnd("onPublish");
      },

      setLoading: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        {
          payload: { loading, parentId },
        }: PayloadAction<{ loading: boolean; parentId: string }>
      ) => {
        if (state[parentId]) state[parentId].loading = loading;
      },
      initChildState: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        { payload: parentId }: PayloadAction<string>
      ) => {
        if (!state[parentId]) {
          state[parentId] = initialState;
        }
      },
      setSubscribed: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        {
          payload: { parentId, subscribed },
        }: PayloadAction<{ subscribed: boolean; parentId: string }>
      ) => {
        if (state[parentId]) {
          state[parentId].subscribed = subscribed;
        }
      },
      ...reducers,
    },
  });

  const {
    onPublish,
    setLoading,
    setSubscribed,
    initChildState,
    setLoadingResources,
  } = slice.actions;

  const select = (parentId: string | null, state: RootState) =>
    parentId ? state[sliceName || name][parentId] : null;

  const unsubscribe = (
    parentId: string,
    data?: Record<string, unknown>
  ) => async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ) => {
    const socket = getSocket();
    dispatch(setLoading({ parentId, loading: true }));
    socket.off(`${parentName}/${parentId}/${name}:publish`);
    if (getSocket().connected) {
      await emitAsyncOrdered(
        socket,
        `${parentSingleName}/${name}`,
        `${parentSingleName}/${name}:unsubscribe`,
        {
          [`${parentSingleName}Id`]: parentId,
          ...data,
        }
      );
    }

    dispatch(setLoading({ loading: false, parentId }));
    dispatch(setSubscribed({ subscribed: false, parentId }));
  };

  const subscribe = (
    parentId: string,
    data?: Record<string, unknown>
  ) => async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ) => {
    const socket = getSocket();
    await dispatch(initChildState(parentId));
    const childState = select(parentId, getState());
    const { lastUpdatedAt } = childState || {};
    dispatch(setLoading({ loading: true, parentId }));
    const context: {
      acc: BaseModel[];
      timeout: NodeJS.Timeout | null;
      count: number;
    } = {
      acc: [],
      timeout: null,
      count: 0,
    };
    socket.on(
      `${parentName}/${parentId}/${name}:publish`,
      (data: BaseModel[]) => {
        dispatch(
          onPublish({
            parentId,
            data,
          })
        );
      }
    );
    const { status } = await emitAsyncOrdered(
      socket,
      `${parentSingleName}/${name}`,
      `${parentSingleName}/${name}:subscribe`,
      {
        lastUpdatedAt,
        [`${parentSingleName}Id`]: parentId,
        ...data,
      }
    );
    if (status === "ok") {
      dispatch(setSubscribed({ subscribed: true, parentId }));
    } else {
      dispatch(setSubscribed({ subscribed: false, parentId }));
    }
    dispatch(setLoading({ loading: false, parentId }));
  };

  const reconnect = () => async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ) => {
    const socket = getSocket();
    const state = getState();
    const parentIds = R.keys<RootState>(state) as string[];
    await Promise.all(
      R.map(async (parentId: string) => {
        if (state[parentId]?.subscribed) {
          await dispatch(setSubscribed({ parentId, subscribed: false }));
          await dispatch(subscribe(parentId));
        }
      }, parentIds)
    );
  };
  //
  // const create = (data: TInCreate) => async (
  //   dispatch: Dispatch<any>,
  //   getState: () => RootState,
  //   getSocket: () => Socket
  // ): Promise<ResponsePayload<S>> => {
  //   const socket = getSocket();
  //   dispatch(setLoading(true));
  //   const response = await emitAsync<ResponsePayload<S>>(
  //     socket,
  //     `${name}:create`,
  //     data
  //   );
  //   dispatch(setLoading(false));
  //   return response;
  // };
  //
  // const update = (data: TInUpdate) => async (
  //   dispatch: Dispatch<any>,
  //   getState: () => RootState,
  //   getSocket: () => Socket
  // ): Promise<ResponsePayload<S>> => {
  //   const socket = getSocket();
  //   dispatch(setLoading(true));
  //   const response = await emitAsync<ResponsePayload<S>>(
  //     socket,
  //     `${name}:update`,
  //     data
  //   );
  //   dispatch(setLoading(false));
  //   return response;
  // };
  //
  // const remove = (data: { id: string }) => async (
  //   dispatch: Dispatch<any>,
  //   getState: () => RootState,
  //   getSocket: () => Socket
  // ): Promise<ResponsePayload<S>> => {
  //   const socket = getSocket();
  //   dispatch(setLoading(true));
  //   const response = await emitAsync<ResponsePayload<S>>(
  //     socket,
  //     `${name}:remove`,
  //     data
  //   );
  //   dispatch(setLoading(false));
  //   return response;
  // };

  const reducer = slice.reducer;
  return {
    resourceId: `${parentSingleName}/${name}`,
    select,
    reconnect,
    unsubscribe,
    reducer,
    onPublish,
    setLoadingResources,
    subscribe,
    // create,
    // update,
    // remove,
    selectChildResourceList,
    slice,
  };
};
