import "./App.css";
import { Layout, Spin } from "antd";
import Header from "../components/Header";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Login from "./login";
import React, { useEffect } from "react";
import { initConnectionAsync, selectConnection } from "slices/connection";
import { useDispatch, useSelector } from "react-redux";
import AccountListPage from "views/accounts";
import AccountDetailsPage from "views/accounts/details";
import ManualPatchDetails from "views/manual-patch/details";
import { useAuthConnectionEffect } from "hooks/useAuthConnectionEffect";
import * as MyAccountSlice from "slices/myAccount";
import ManualPatchesPage from "views/manual-patch";
import AuditsPage from "views/audit";
import DotPage from "views/dot";
import SubscriptionLockPage from "views/subscriptionLocks";
import RequestsPage from "views/requests";

function App() {
  const { authenticated, loading } = useSelector(selectConnection);
  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initConnectionAsync());
  }, []);
  useAuthConnectionEffect(() => {
    dispatch(MyAccountSlice.subscribe({}));
    return () => {
      dispatch(MyAccountSlice.unsubscribe({}));
    };
  });

  return (
    <Switch location={location}>
      <Route path="/login" exact>
        <Login />
      </Route>
      {authenticated || loading ? (
        <Spin spinning={loading}>
          <Layout>
            <Header />
            <Switch>
              <Route
                path="/accounts/:accountId"
                children={<AccountDetailsPage />}
              />
              <Route path="/accounts" children={<AccountListPage />} />
              <Route path="/activity" children={<ManualPatchesPage />} exact />
              <Route
                path="/activity/:manualPatchId"
                exact
                children={<ManualPatchDetails />}
              />
              <Route path="/audits" children={<AuditsPage />} />
              <Route path="/dot" children={<DotPage />} />
              <Route
                path="/subscription-lock"
                children={<SubscriptionLockPage />}
              />
              <Route path="/driver-requests" children={<RequestsPage />} />
              <Redirect
                path="/"
                exact
                to={{
                  pathname: "/activity",
                }}
              />
            </Switch>
          </Layout>
        </Spin>
      ) : (
        !loading && (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      )}
    </Switch>
  );
}

export default App;
