import PQueue from "p-queue/dist";
import { Socket } from "socket.io-client";

const queues: Record<string, PQueue> = {};

export const emitAsyncOrdered = <T>(
  socket: Socket,
  resourceId: string,
  event: string,
  data?: any
): Promise<T> => {
  if (!queues[resourceId]) {
    queues[resourceId] = new PQueue({ concurrency: 1, autoStart: true });
  }
  const queue = queues[resourceId];
  return queue.add(() => {
    return new Promise<T>(function (resolve, reject) {
      if (socket.connected) {
        socket.emit(event, data, (newData: T) => {
          console.log(`Ack: ${event}`, newData);
          resolve(newData);
        });
      } else {
        resolve({ status: "networkError" } as any);
      }
    });
  });
};

export const emitAsync = <T>(
  socket: Socket,
  event: string,
  data?: any
): Promise<T> => {
  return new Promise<T>(function (resolve, reject) {
    let timeout: any;
    if (socket.connected) {
      timeout = setTimeout(() => {
        console.log("Timeout in", event);
        resolve({ status: "timeout" } as any);
      }, 60000);
    }

    console.log(`Emitting ${event}`, data);
    socket.emit(event, data, (newData: T) => {
      timeout && clearTimeout(timeout);
      console.log(`Ack: ${event}`, newData);
      resolve(newData);
    });
  });
};
