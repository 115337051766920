import {
  createRelativeSubscriptiveSlice,
  RelativeSubscriptiveResourceList,
} from "slices/subscriptive/relative";
import { DriverPayload } from "interfaces";
import { useSelector } from "react-redux";
import * as r from "ramda";

export type DriversRootState = {
  [k: string]: RelativeSubscriptiveResourceList<DriverPayload>;
};

const {
  select,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  selectChildResourceList,
  slice,
} = createRelativeSubscriptiveSlice({
  name: "drivers",
  // @ts-expect-error _id does not exist
  idProp: "_id",
  payloadType: DriverPayload,
  parentName: "companies",
  parentSingleName: "company",
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
});

export { unsubscribe, reducer, reconnect, onPublish, subscribe };

export default slice.reducer;

export const useDrivers = (accountId: string | null) => {
  const {
    resourceDictionary: driversById,
    loading: driversLoading,
    subscribed: driversSubscribed,
  } = useSelector(r.partial(select, [accountId])) || {};

  const drivers = useSelector(r.partial(selectChildResourceList, [accountId]));

  return {
    drivers: drivers as DriverPayload[] | undefined,
    driversById: driversById as Record<string, DriverPayload> | undefined,
    driversLoading,
    driversSubscribed,
  };
};
